import { BaseStakeholder } from "./baseStakeholder.model";

export class MissingPartStakeholder extends BaseStakeholder {

    missingPartId:number;

    constructor(
        name:string,
        employeeId:string,
        email:string,
        missingPartId:number,
        id?:number) {

        super(name, employeeId, email, id);
        this.missingPartId = missingPartId;
    }
}
    