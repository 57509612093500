import { MissingPartStepFile } from "./missingPartStepFile.model";

export enum MissingPartStepTitle {
    "BASIC INFORMATION",
    "HANDLING",
    "DELIVERY",
    "CLOSE THE CASE",
}

export class MissingPartStep {

    missingPartId:number;
    stepIndex:number;
    closed:boolean;
    comment:string;
    ownerEmployeeId:string;
    ownerName:string;
    ownerEmail:string;
    files?:MissingPartStepFile[];

    constructor(
        missingPartId:number,
        stepIndex:number,
        closed:boolean,
        comment:string,
        ownerEmployeeId:string,
        ownerName:string,
        ownerEmail:string,
        files?:MissingPartStepFile[]
    ) {
        this.missingPartId = missingPartId;
        this.stepIndex = stepIndex;
        this.closed = closed;
        this.comment = comment;
        this.ownerEmployeeId = ownerEmployeeId;
        this.ownerName = ownerName;
        this.ownerEmail = ownerEmail;
        this.files = files;
    }
}