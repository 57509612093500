<div class="header-container" [ngClass]="[envName]">
    <div class="title">
        <h1 routerLink="/">DeMO</h1>
        <h2 class="dropdown-container" *ngIf="!isHomepage()" tabindex="0" (keyup.arrowdown)="selectNextFactory()" (keyup.arrowup)="selectPreviousFactory()">
            {{currentFactory != null && hasAuthorizationForUrlFactory() ? currentFactory.name : 'SELECT FACTORY' | translate}}
            <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.793038 0.170008C0.666335 0.170008 0.54483 0.220689 0.455652 0.310705C0.366482 0.400722 0.316968 0.522653 0.318135 0.649367C0.319302 0.776176 0.371042 0.897151 0.461803 0.985477L6.1618 6.68548C6.25097 6.77454 6.37174 6.82458 6.49769 6.82458C6.62365 6.82458 6.74442 6.77454 6.83348 6.68548L12.5335 0.985477C12.6254 0.897051 12.6779 0.775332 12.6791 0.647793C12.6803 0.520243 12.6302 0.397568 12.5399 0.30734C12.4498 0.217218 12.3272 0.167066 12.1996 0.168233C12.0721 0.169505 11.9503 0.221989 11.8618 0.313808L6.49757 5.67805L1.13332 0.313808H1.13343C1.04405 0.221883 0.921166 0.170035 0.792978 0.170035L0.793038 0.170008Z"/></svg>
            <div class="dropdown-content">
                <span *ngFor="let factory of getFactoriesArray()" (click)="selectFactory(factory)" [ngClass]="{'active' :currentFactory != null && currentFactory.id === factory.id}">{{factory.name}}</span>
            </div>
        </h2>
        <h3 class="dropdown-container" *ngIf="!isHomepage() && hasAuthorizationForUrlFactory()" tabindex="0" (keyup.arrowdown)="selectNextLine()" (keyup.arrowup)="selectPreviousLine()">
            {{currentLine != null ? currentLine.name : 'ALL LINES' | translate }}
            <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.793038 0.170008C0.666335 0.170008 0.54483 0.220689 0.455652 0.310705C0.366482 0.400722 0.316968 0.522653 0.318135 0.649367C0.319302 0.776176 0.371042 0.897151 0.461803 0.985477L6.1618 6.68548C6.25097 6.77454 6.37174 6.82458 6.49769 6.82458C6.62365 6.82458 6.74442 6.77454 6.83348 6.68548L12.5335 0.985477C12.6254 0.897051 12.6779 0.775332 12.6791 0.647793C12.6803 0.520243 12.6302 0.397568 12.5399 0.30734C12.4498 0.217218 12.3272 0.167066 12.1996 0.168233C12.0721 0.169505 11.9503 0.221989 11.8618 0.313808L6.49757 5.67805L1.13332 0.313808H1.13343C1.04405 0.221883 0.921166 0.170035 0.792978 0.170035L0.793038 0.170008Z"/></svg>
            <div class="dropdown-content">
                <span *ngFor="let line of currentFactory.productionLines" (click)="selectLine(line)" [ngClass]="{'active' :currentLine?.id === line.id}">{{line.name}}</span>
                <span *ngIf="currentFactory.productionLines.length > 0" (click)="selectLine()" [ngClass]="{'active' :getCurrentLineId() == -1}">{{ "ALL LINES" | translate }}</span>
            </div>
        </h3>
    </div>
    <div class="menu">
        <a routerLink="{{getHeaderButtonUrl('deviations-list')}}" routerLinkActive="active-link" (click)="getAuthorizedFactories(0)">{{ 'DEVIATIONS LIST' | translate }}</a>
        <a routerLink="{{getHeaderButtonUrl('missing-parts')}}" routerLinkActive="active-link" (click)="getAuthorizedFactories(1)">{{ 'MISSING PARTS' | translate }}</a>
        <a routerLink="{{getHeaderButtonUrl('suppliers')}}" routerLinkActive="active-link" (click)="getAuthorizedFactories(3)" *ngIf="isLinkVisible('Suppliers')">{{ 'SUPPLIERS' | translate }}</a>
        <span href="" class="btn-dropdown">
            <svg width="15" height="15" viewBox="0 0 93 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M90.6484 95.5299C90.6484 76.2499 93.3384 53.6899 46.3384 53.6899C-0.661621 53.6899 2.02838 76.4999 2.02838 95.5299" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M46.3378 51.24C59.935 51.24 70.9578 40.2173 70.9578 26.62C70.9578 13.0227 59.935 2 46.3378 2C32.7405 2 21.7178 13.0227 21.7178 26.62C21.7178 40.2173 32.7405 51.24 46.3378 51.24Z" stroke-width="7" stroke-miterlimit="10"/>
            </svg>
            <span>{{getActiveAccount()?.name}}</span>
            <span>{{currentLine?.name}}</span>
            
            <div class="dropdown-content">
                <div class="user">
                    <svg width="20" height="20" viewBox="0 0 93 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M90.6484 95.5299C90.6484 76.2499 93.3384 53.6899 46.3384 53.6899C-0.661621 53.6899 2.02838 76.4999 2.02838 95.5299" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M46.3378 51.24C59.935 51.24 70.9578 40.2173 70.9578 26.62C70.9578 13.0227 59.935 2 46.3378 2C32.7405 2 21.7178 13.0227 21.7178 26.62C21.7178 40.2173 32.7405 51.24 46.3378 51.24Z" stroke-width="7" stroke-miterlimit="10"/>
                    </svg>
                    <span class="name">{{getActiveAccount()?.username}}</span>
                    <span>{{getActiveAccount()?.idTokenClaims?.['mailNickName']}} {{getActiveAccount()?.name}}</span>
                </div>
                <!--OPERATOR ID-->
                <app-operator-scanning 
                    [currentFactoryId]="currentFactory.id || -1" 
                    [allowUnknownOperator]="currentFactory.allowUnknownOperator"
                    [headerVersion]="true"
                    *ngIf="currentFactory?.operatorScanningEnabled" 
                    (userScannedEvent)="scannedAgent = $event"
                    (isScanningEvent)="isScanning = $event">
                </app-operator-scanning>

                <div class="zone">
                    <span>{{currentLine != null ? currentLine.name : "ALL LINES" | translate}}</span>
                </div>
                <div class="sub-dropdown-container">
                    <span>{{currentCheckpoint?.name}}</span>
                    <div class="sub-dropdown-content" *ngIf="currentLine != null">
                        <span *ngFor="let checkpoint of currentLine?.checkpoints" (click)="selectCheckpoint(checkpoint)" [ngClass]="{'active' :currentCheckpoint?.id === checkpoint.id}">{{checkpoint.name}}</span>
                    </div>
                    <div class="sub-dropdown-content" *ngIf="currentLine == null">
                        <span *ngFor="let checkpoint of getAllLinesCheckpoints()" (click)="selectCheckpoint(checkpoint)" [ngClass]="{'active' :currentCheckpoint?.id === checkpoint.id}">{{checkpoint.name}}</span>
                    </div>
                </div>
                <div class="notification-option">
                    <mat-slide-toggle [(ngModel)]="browserNotificationsEnabled" (change)="toggleBrowserNotifications($event)">{{"ALL BROWSER NOTIFICATION" | translate}}</mat-slide-toggle>
                </div>
                <span class="element log-in" *ngIf="!loginDisplay" (click)="login()">{{'LOGIN' | translate}}</span>
                <span class="element log-out" *ngIf="loginDisplay" (click)="logout()">{{'LOGOUT' | translate}}</span>
            </div>
        </span>
    </div>

    <!-- Burger menu icon -->
    <div class="burger-menu" (click)="toggleBurgerMenu()">
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.7199 0.862854C15.2502 0.862854 15.6799 1.29263 15.6799 1.82285C15.6799 2.35308 15.2502 2.78285 14.7199 2.78285H1.27995C0.749718 2.78285 0.319946 2.35308 0.319946 1.82285C0.319946 1.29263 0.749718 0.862854 1.27995 0.862854H14.7199ZM14.7199 6.04685C15.2502 6.04685 15.6799 6.47663 15.6799 7.00685C15.6799 7.53708 15.2502 7.96685 14.7199 7.96685H1.27995C0.749718 7.96685 0.319946 7.53708 0.319946 7.00685C0.319946 6.47663 0.749718 6.04685 1.27995 6.04685H14.7199ZM14.7199 11.2309C15.2502 11.2309 15.6799 11.6606 15.6799 12.1909C15.6799 12.7211 15.2502 13.1509 14.7199 13.1509H1.27995C0.749718 13.1509 0.319946 12.7211 0.319946 12.1909C0.319946 11.6606 0.749718 11.2309 1.27995 11.2309H14.7199Z" fill="#333333"/>
        </svg>
    </div>

    <!-- Burger menu content -->
    <div class="burger-menu-content" [ngClass]="{'open':isBurgerMenuOpen}">
        <div class="account-info">
            <svg width="30" height="30" viewBox="0 0 93 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M90.6484 95.5299C90.6484 76.2499 93.3384 53.6899 46.3384 53.6899C-0.661621 53.6899 2.02838 76.4999 2.02838 95.5299" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M46.3378 51.24C59.935 51.24 70.9578 40.2173 70.9578 26.62C70.9578 13.0227 59.935 2 46.3378 2C32.7405 2 21.7178 13.0227 21.7178 26.62C21.7178 40.2173 32.7405 51.24 46.3378 51.24Z" stroke-width="7" stroke-miterlimit="10"/>
            </svg>
            <div>
                <span class="name">{{getActiveAccount()?.name}} {{getActiveAccount()?.idTokenClaims?.['mailNickName']}}</span>
                <span class="username">{{getActiveAccount()?.username}}</span>
            </div>
        </div>

        <div class="select-line">
            <div class="dropdown-container factory-container">
                {{currentFactory != null ? currentFactory.name : "Select Factory"}}
                <div class="dropdown-content">
                    <span *ngFor="let factory of getFactoriesArray()" (click)="selectFactory(factory)" [ngClass]="{'active' :currentFactory != null && currentFactory.id === factory.id}">{{factory.name}}</span>
                </div>
            </div>
            <div class="dropdown-container line-container" *ngIf="currentFactory != null">
                {{currentLine != null ? currentLine.name : 'ALL LINES' | translate }}
                <div class="dropdown-content">
                    <span *ngFor="let line of currentFactory.productionLines" (click)="selectLine(line)" [ngClass]="{'active' :currentLine?.id === line.id}">{{line.name}}</span>
                    <span *ngIf="currentFactory.productionLines.length > 0" (click)="selectLine()" [ngClass]="{'active' :getCurrentLineId() == -1}">{{ "ALL LINES" | translate }}</span>
                </div>
            </div>
        </div>

        <a routerLink="{{'/deviations-list/' + getCurrentFactoryId() + '/' + getCurrentLineId()}}" routerLinkActive="active-link" (click)="isBurgerMenuOpen = false">{{ 'DEVIATIONS LIST' | translate }}</a>
        <a routerLink="{{'/missing-parts/' + getCurrentFactoryId() + '/' + getCurrentLineId()}}" routerLinkActive="active-link" (click)="isBurgerMenuOpen = false">{{ 'MISSING PARTS' | translate }}</a>
        <a routerLink="{{getHeaderButtonUrl('suppliers')}}" routerLinkActive="active-link" (click)="getAuthorizedFactories(3)" *ngIf="isLinkVisible('Suppliers')">{{ 'SUPPLIERS' | translate }}</a>

        <div class="notification-option">
            <mat-slide-toggle [(ngModel)]="browserNotificationsEnabled" (change)="toggleBrowserNotifications($event)">{{"ALL BROWSER NOTIFICATION" | translate}}</mat-slide-toggle>
        </div>

        <span class="mobile-log-in" *ngIf="!loginDisplay" (click)="login()">Login</span>
        <span class="mobile-log-out" *ngIf="loginDisplay" (click)="logout()">Logout</span>
    </div>
</div>

