import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-tag-element',
  templateUrl: './tag-element.component.html',
  styleUrl: './tag-element.component.less'
})
export class TagElementComponent {
  @Input() text = "";
  @Input() id = 0;
  @Input() hasRightToModifyTag = false;
  
  @Output() removeEvent = new EventEmitter<any>();

  removeElement(){
    this.removeEvent.emit({id: this.id});
  }
}
