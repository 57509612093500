export enum Language {
    French = "fr",
    Dutch = "nl",
    English = "en"
}

export enum DateFormatDisplay{
    French = "JJ/MM/AAAA",
    Dutch = "D-MM-JJJJ",
    English = "MM/DD/YYYY"
}