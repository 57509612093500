import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Agent } from 'src/app/models/agent.model';
import { SupplierQuality } from 'src/app/models/supplierQuality.model';
import { SupplierQualityWorkload } from 'src/app/models/supplierQualityWorkload.model';
import { environment } from 'src/environments/environment';
import * as graphHelper from 'src/app/graphHelper';
import settings from 'src/app/appSettings';
import { DeviceCodeInfo } from '@azure/identity';

@Component({
  selector: 'app-workloads-list',
  templateUrl: './workloads-list.component.html',
  styleUrls: ['./workloads-list.component.less']
})
export class WorkloadsListComponent {

  @Input() selectedSupplier:SupplierQuality | null = null;
  @Input() factoryId:number = -2;

  apiUrl:string = environment.API_URL;

  @ViewChild('nameInput') nameInput!: ElementRef;

  hasWorkloadToAdd = false;
  workloadToModify?:SupplierQualityWorkload | null;
  workloadToRemove?:SupplierQualityWorkload | null;
  isSavingData = false;

  //POPUP
  cancelClosePopup = false;

  //FORM
  workloadForm = new FormGroup({
    name: new FormControl(''),
    workloadHours : new FormControl()
  });

  nameFilter:string = "";
  inputNameFocused = false;
  isLoadingAzureUsers = false;
  azureUsersList:Agent[] = [];
  selectedAzureUser:Agent | null = null;

  constructor(private httpClient:HttpClient) {}

  ngOnInit(): void {
    graphHelper.initializeGraphForUserAuth(settings, (info: DeviceCodeInfo) => {});
  }

  openAddWorkloadPopup(){
    this.setFormValues();
    this.hasWorkloadToAdd = true;
  }

  openModifyWorkloadPopup(workload:SupplierQualityWorkload){
    this.workloadToModify = workload;
    this.setFormValues();
  }

  openRemoveWorkloadPopUp(workload:SupplierQualityWorkload, event?:any){
    event.stopPropagation();
    this.workloadToRemove = workload;
  }

  setFormValues(){
    this.workloadForm.reset();
    this.workloadForm.enable();
    
    if(this.workloadToModify != null){
      this.workloadForm.setValue({
        name:this.workloadToModify.name,
        workloadHours:this.workloadToModify.workload
      });
    }else{
      this.workloadForm.setValue({
        name:"",
        workloadHours:null
      });
    }
  }

  saveWorkload(){
    if(this.hasWorkloadToAdd){
      this.addWorkload();
    }
  }

  addWorkload(){  
    if(this.selectedSupplier == null || this.selectedSupplier.id == null) {return;}

    this.isSavingData = false;

    const newWorkload:SupplierQualityWorkload = {
      name: this.selectedAzureUser != null ? this.selectedAzureUser.agentName : this.workloadForm.value.name || null,
      employeeId: this.selectedAzureUser != null ? this.selectedAzureUser.employeeId : null,
      email: this.selectedAzureUser != null ? this.selectedAzureUser.email : null,
      workload: Number(this.workloadForm.value.workloadHours),
      supplierQualityId:this.selectedSupplier.id
    }  

    this.httpClient.post<SupplierQualityWorkload>(this.apiUrl + 'SupplierQuality/Workload', newWorkload).subscribe(
    {
      next: (res) => {
        this.selectedSupplier?.workloads?.push(res);
        this.closePopUp();
      },
        error: (e) => {
          console.error(e);
          this.closePopUp();
        }
    });  
  }

  modifyWorkload(){
    if(this.selectedSupplier == null || this.selectedSupplier.id == null || this.workloadToModify == null) {return;}

    this.isSavingData = true;

    if(this.workloadToModify.name !== this.workloadForm.value.name){
      this.workloadToModify.name = this.selectedAzureUser != null ? this.selectedAzureUser.agentName : this.workloadForm.value.name || null;
      this.workloadToModify.employeeId = this.selectedAzureUser != null ? this.selectedAzureUser.employeeId : null;
      this.workloadToModify.email = this.selectedAzureUser != null ? this.selectedAzureUser.email : null;
    }

    this.workloadToModify.workload = Number(this.workloadForm.value.workloadHours);
      
    this.httpClient.put<SupplierQualityWorkload>(this.apiUrl + 'SupplierQuality/Workload', this.workloadToModify).subscribe(
    {
      next: (res) => {
        this.closePopUp();
      },
        error: (e) => {
          console.error(e);
          this.closePopUp();
        }
    });
  }

  removeWorkload(){
    if(this.workloadToRemove == null || this.selectedSupplier == null){ return;}

    this.isSavingData = true;

    this.httpClient.delete(this.apiUrl + "SupplierQuality/Workload/" + this.workloadToRemove.id).subscribe(
      {
        next: (res) => {
          this.selectedSupplier?.workloads?.splice(this.selectedSupplier?.workloads?.findIndex(x => this.workloadToRemove != null && this.workloadToRemove.id === x.id), 1);
          this.closePopUp();
        },
        error: (e) => {
          console.log(e);
          this.closePopUp();
        }
      }
    );
  }

  //POPUP
  closePopUp(){
    if(!this.cancelClosePopup){
      this.hasWorkloadToAdd = false;
      this.workloadToRemove = null;
      this.selectedAzureUser = null;
      this.workloadToModify = null;
      this.isSavingData = false;
    }else{
      this.cancelClosePopup = false;
    }
  }

  cancelClosePopUp(){
    this.cancelClosePopup =  true;
  }

  getPopUpTitle(){
    return this.hasWorkloadToAdd ? "ADD WORKLOAD" : "MODIFY WORKLOAD";
  }

  //NAME FIELD
  async getAzureUsersList(){
    setTimeout(()=>{
      this.nameInput.nativeElement.focus();
    },100);

    try {
      this.azureUsersList = [];
      this.isLoadingAzureUsers = true;
      const users = await graphHelper.getUsersWithFilter(this.nameFilter);
    
      users.forEach((user:any) => {
          this.azureUsersList.push(new Agent(user.id, user.displayName, user.onPremisesExtensionAttributes.extensionAttribute5, user.mail, this.factoryId));
      });

      this.isLoadingAzureUsers = false;

    } catch (err) {
      console.log(`Error getting user: ${err}`);
    }
    
  }
  selectAzureUser(agent:Agent){
    this.selectedAzureUser = agent;

    this.workloadForm.controls['name'].setValue(agent.agentName);
  }

  validateKeyPress(event: KeyboardEvent): boolean {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const currentValue = (event.target as HTMLInputElement).value;
  
    // Allow control keys and decimal point if not already in the input
    if (
      allowedKeys.includes(event.key) || 
      (event.key === '.' && !currentValue.includes('.'))
    ) {
      return true;
    }
  
    // Allow numbers only
    return /[0-9]/.test(event.key);
  }
}
