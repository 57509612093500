<mat-icon class="close" (click)="close()">{{'close'}}</mat-icon>

<select id="deviceSelection" (change)="onDeviceSelectChange($event)" [(ngModel)]="selectedOption">
    <option *ngFor="let device of availableDevices" [value]="device.deviceId">
        {{ device.label || 'Camera ' + (availableDevices.indexOf(device) + 1) }}
    </option>
</select>

<zxing-scanner
    #scanner
    start="true"
    (scanSuccess)="onCodeResult($event)"
    [formats]="allowedFormats"
    [device]="selectedDevice"
    (autostarted)="cameraAutoStarted()">
</zxing-scanner>

<mat-spinner class="primary" *ngIf="isLoadingCamera"></mat-spinner>

<span class="error-message" *ngIf="!isLoadingCamera && selectedDevice == null">{{"NO CAMERA FOUND" | translate}}</span>