import { Pipe, PipeTransform } from "@angular/core";
import { ProductView } from "../models/productView.model";

@Pipe({
    name:'searchChassisId'
})
export class SearchPipe implements PipeTransform{
    transform(products: ProductView[], searchText:string) {
        if(products == null || products.length === 0 || searchText === ''){
            return products;
        } else {
            return products.filter((product) => 
            {
                return product.fabNumber.concat(product.controlLetter).toLowerCase().includes(searchText.toLowerCase())
            }) 
        }
    }
}