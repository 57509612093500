import { BaseStakeholder } from "./baseStakeholder.model";

export class SupplierQualityStakeholder extends BaseStakeholder {

    supplierQualityId: number;

    constructor(
        name:string | null,
        employeeId:string | null,
        email:string | null,
        supplierQualityId:number,
        id?:number
    ) {
        super(name, employeeId, email, id);
        this.supplierQualityId = supplierQualityId;
    }
}