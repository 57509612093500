import { Component, ElementRef, ViewChild } from '@angular/core';
import { consumerPollProducersForChange } from '@angular/core/primitives/signals';

@Component({
  selector: 'app-take-picture',
  templateUrl: './take-picture.component.html',
  styleUrl: './take-picture.component.less'
})
export class TakePictureComponent {
  @ViewChild('videoElement', { static: true }) videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement', { static: true }) canvasElement!: ElementRef<HTMLCanvasElement>;

  videoStream: MediaStream | null = null;

  isCameraOpen = false;
  isSavingData = false;
  isCameraOn = false;

  async startCamera() {
    this.isCameraOn = true;
    await new Promise((resolve) => setTimeout(resolve, 100));
    try {
      this.videoStream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: { ideal: 'environment' } } });
      this.videoElement.nativeElement.srcObject = this.videoStream;
    } catch (error) {
      this.isCameraOn = false;
    }
  }

  stopCamera() {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach(track => track.stop());
      this.videoStream = null;
    }
    this.isCameraOn = false;
  }

  capture() {
    const canvas = this.canvasElement.nativeElement;
    const video = this.videoElement.nativeElement;

    // Set canvas size to match the video
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Draw the video frame to the canvas
    const context = canvas.getContext('2d');
    if (context) {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
    }

    // Optionally, convert canvas to image URL
    const imageDataUrl = canvas.toDataURL('image/png');
  }

  ngOnDestroy(): void {
    // Stop the video stream when component is destroyed
    this.stopCamera();
  }

  openCameraPopup():void{
    this.startCamera();
    this.isCameraOpen = true;
  }

  closePopUp(){
    this.isCameraOpen = false;
    this.stopCamera();
  }
}
