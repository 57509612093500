import { HttpClient } from '@angular/common/http';
import { identifierName } from '@angular/compiler';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { DeviceCodeInfo } from '@azure/identity';
import { TranslateService } from '@ngx-translate/core';
import settings from 'src/app/appSettings';
import * as graphHelper from 'src/app/graphHelper';
import { Agent } from 'src/app/models/agent.model';
import { BaseStakeholder } from 'src/app/models/baseStakeholder.model';
import { MissingPart } from 'src/app/models/missingPart.model';
import { MissingPartStakeholder } from 'src/app/models/missingPartStakeholder.model';
import { PartViewModel } from 'src/app/models/partViewModel.model';
import { Role } from 'src/app/models/role.model';
import { SupplierQuality } from 'src/app/models/supplierQuality.model';
import { SupplierQualityStakeholder } from 'src/app/models/supplierQualityStakeholder.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stakeholders-list',
  templateUrl: './stakeholders-list.component.html',
  styleUrl: './stakeholders-list.component.less'
})
export class StakeholdersListComponent {
  apiUrl:string =  environment.API_URL;

  @Input() roleAuth!:Role;
  @Input() errorMessageSelectedCase:string = "";
  @Input() currentFactoryId = -2
  @Input() selectedMissingPart:MissingPart | null = null;
  @Input() selectedSupplier:SupplierQuality | null = null;
  @Input() agentList:Agent[] = [];
  
  @Output() assignEvent = new EventEmitter<any>();
  
  hasNotificationToSend = false;
  hasMissingPartToAssign = false;
  isSavingData = false;

  stakeholderToAssign?: BaseStakeholder | null;
  stakeholderToRemove?: BaseStakeholder | null;

  //STAKEHOLDERS
  inputStakeholdersFocused:boolean = false;
  stakeholderFilter:string = "";
  azureUsersList:Agent[] = [];
  isLoadingAzureUsers:boolean = false;
  warningStakeholderMessage:string = "";

  //POPUP
  cancelClosePopup = false;

  @HostListener('window:keydown.escape') escapeEvent() { this.closePopUp(); }
  @ViewChild('addStakeholderInput') addStakeholderInput!: ElementRef;

  constructor(
    private httpClient:HttpClient,
    private translate: TranslateService) {
  }

  ngOnInit(): void {
    graphHelper.initializeGraphForUserAuth(settings, (info: DeviceCodeInfo) => {});
  }

  openNotifyStakeholdersPopup(){
    this.hasNotificationToSend = true;
  }

  openAssignPopUp(s?:BaseStakeholder){
    if(s != null){ this.stakeholderToAssign = s;}

    this.hasMissingPartToAssign = true;
    this.isSavingData = false;
  }

  isSameStakeholderAndAgent(s1:MissingPartStakeholder, s2:Agent):boolean{  
    if((s1.employeeId != null && s1.employeeId !== "") && (s2.employeeId != null && s2.employeeId !== "")){
      return s1.employeeId === s2.employeeId;
    }
    else{
      return s1.name === s2.agentName;
    }
  }

  addStakeholderInputFocus(){
    this.inputStakeholdersFocused = true;
    this.warningStakeholderMessage = "";
  }
  
  addStakeholder(agent?:Agent){
    if(agent == null || (this.selectedMissingPart == null && this.selectedSupplier == null)){ return;}

    let newStakeholder:BaseStakeholder | null = null;

    if(this.selectedMissingPart != null){
      newStakeholder = new MissingPartStakeholder(agent.agentName, agent.employeeId || "", agent.email, this.selectedMissingPart.id!);
    } 
    else if(this.selectedSupplier != null){
      newStakeholder = new SupplierQualityStakeholder(agent.agentName, agent.employeeId, agent.email, this.selectedSupplier.id!);
    }
    else {
      return;
    }

    //if new one not already on the list, don't add it
    if(this.isAlreadyStakeholder(newStakeholder.email!, newStakeholder.employeeId!, newStakeholder.name!)) {
      return;
    }

    const url = newStakeholder instanceof MissingPartStakeholder ? 'MissingPart/Stakeholder' : 'SupplierQuality/Stakeholder';

    this.httpClient.post<MissingPartStakeholder | SupplierQualityStakeholder>(this.apiUrl + url, newStakeholder).subscribe(
      {
        next: (res) => {
          this.selectedCaseStakeholders().push(res);
          //todo get if new stakeholder has right to see mp
          //this.warningStakeholderMessage = "NEW STAKEHOLDER NOT AUTHORIZED"
        },
        error: (e) => {
          console.error(e);
        }
      }
    );
  }

  isAlreadyStakeholder(email:string, employeeId:string, name:string):boolean{
    if((this.selectedMissingPart == null || this.selectedMissingPart.stakeholders == null || this.selectedMissingPart.stakeholders.length === 0) && 
        (this.selectedSupplier == null || this.selectedSupplier.stakeholders == null || this.selectedSupplier.stakeholders.length === 0)) {return false;}

    return this.selectedCaseStakeholders().find(x=>x.email === email && x.employeeId === employeeId && x.name === name) != null;
  }

  removeStakeholder(){
    console.log("stakeholder to remove", this.stakeholderToRemove);
    if(this.stakeholderToRemove == null || (this.selectedMissingPart == null && this.selectedSupplier == null)){ return;}

    const url = this.selectedMissingPart != null ? 'MissingPart/Stakeholder/': 'SupplierQuality/Stakeholder/';
    this.httpClient.delete(this.apiUrl + url + this.stakeholderToRemove.id).subscribe(
      {
        next: (res) => {
          this.isSavingData = false;
          this.selectedCaseStakeholders()?.splice(this.selectedCaseStakeholders()?.findIndex(x => this.stakeholderToRemove != null && this.stakeholderToRemove.id === x.id), 1);
          this.closePopUp();
        },
        error: (e) => {
          console.log(e);
        }
      }
    );
  }

  async getAzureUsersList(){
    setTimeout(()=>{
      this.addStakeholderInput.nativeElement.focus();
    },100);

    try {
      this.azureUsersList = [];
      this.isLoadingAzureUsers = true;
      const users = await graphHelper.getUsersWithFilter(this.stakeholderFilter);
    
      users.forEach((user:any) => {
        if(user != null && !this.isAlreadyStakeholder(user.mail, user.onPremisesExtensionAttributes.extensionAttribute5, user.displayName)){
          this.azureUsersList.push(new Agent(user.id, user.displayName, user.onPremisesExtensionAttributes.extensionAttribute5, user.mail, this.currentFactoryId));
        }
      });

      this.isLoadingAzureUsers = false;

    } catch (err) {
      console.log(`Error getting user: ${err}`);
    }
    
  }
  isStakeholderOwner(s:BaseStakeholder): boolean{
    const sc = this.selectedCase();
    if(sc == null) {return false};

    if((s.employeeId != null && s.employeeId !== "") && (sc.ownerEmployeeId != null && sc.ownerEmployeeId !== "")){
      return s.employeeId === sc.ownerEmployeeId;
    }
    else{
      return s.name === sc.ownerName;
    }
  }
  openRemoveStakeholderPopUp(s:BaseStakeholder){
    this.stakeholderToRemove = s;
    this.isSavingData = false;
  }

  //POPUP
  closePopUp(){
    if(!this.cancelClosePopup){
      this.hasMissingPartToAssign = false;
      this.hasNotificationToSend = false;
      this.stakeholderToRemove = null;
      this.stakeholderToAssign = null;
    }else{
      this.cancelClosePopup = false;
    }
  }

  cancelClosePopUp(){
    this.cancelClosePopup =  true;
  }

  assignMissingPart(){
    if((this.selectedMissingPart == null && this.selectedSupplier == null) || this.translate.currentLang == null || this.stakeholderToAssign == null){ return;}
    this.isSavingData = true;

    const url = this.selectedMissingPart != null ? 'MissingPart/AssignTo/' + this.selectedMissingPart?.id : 'SupplierQuality/AssignTo/' + this.selectedSupplier?.id;

    this.httpClient.put<MissingPart>(this.apiUrl + url + '/' + this.stakeholderToAssign.id + '/' + this.translate.currentLang, null).subscribe(
      res => {
        this.assignEvent.emit({case: res});
        this.closePopUp();
        this.isSavingData = false;
      });
  }

  notifyStakeholders(){
    if((this.selectedMissingPart == null && this.selectedSupplier == null) || this.translate.currentLang == null){ return;}
    this.isSavingData = true;
    const url = this.selectedMissingPart != null ? 'Notification/MissingPart/' + this.selectedMissingPart.id : 'Notification/SupplierQuality/' + this.selectedSupplier?.id;
    this.httpClient.post<any>(this.apiUrl + url + '/' + this.translate.currentLang, null).subscribe(
      res => {
        this.isSavingData = false;
        this.closePopUp();
      }
    );
  }

  getPopUpTitle(){
    if(this.hasMissingPartToAssign && this.stakeholderToAssign == null){
      return "ASSIGN TO ME";}
    else if(this.hasMissingPartToAssign && this.stakeholderToAssign != null){
      return "ASSIGN";}
    else if(this.stakeholderToRemove != null){
      return "REMOVE STAKEHOLDER";}
    else if(this.hasNotificationToSend){
      return "NOTIFY STAKEHOLDERS";}
    else{
      return "";}
  }

  selectedCase():MissingPart | SupplierQuality | null{
    if(this.selectedMissingPart != null){
      return this.selectedMissingPart;
    }
    else if(this.selectedSupplier != null){
      return this.selectedSupplier;
    }
    else{
      return null;
    }
  }

  selectedCaseStakeholders(){
    const sc = this.selectedCase();
    return sc != null ? sc.stakeholders as BaseStakeholder[] : [];
  }
  
}
