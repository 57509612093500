<span style="font-family:VolvoNovum, sans-serif;">を タ ツ ね し ジ ヒ А Б В Г Д Е Ё Ж З 大 三 𡨸 漢</span>
<app-demo-button text="{{i18nTest}}" type="btn-big" color="grey"></app-demo-button>

<app-demo-button text="{{i18nTest}}" type="btn-big" color="secondary"></app-demo-button>

<app-demo-button text="{{i18nTest}}" type="btn-big" color="tertiary"></app-demo-button>

<app-demo-card type="big" text="{{i18nTest}}" image="{{testImg}}"></app-demo-card>

<app-demo-card type="medium" text="{{i18nTest}}" image="{{testImg}}"></app-demo-card>

<app-back-button text="{{i18nTest}}"></app-back-button>

<app-production-tracking></app-production-tracking>
<div style="margin: 30px;"></div>



<div cdkDropList (cdkDropListDropped)="onDrop($event)" [cdkDropListData]="workcells" class="drag-list">
    <div cdkDrag *ngFor="let workcell of workcells" class="drag-item">
        {{workcell}}
    </div>
</div>

<app-demo-button text="notif" (clickEvent)="getTestNotification()"></app-demo-button>

<app-demo-button text="test /me" (clickEvent)="getTestMSGraph()"></app-demo-button>

<app-take-picture></app-take-picture>