export class WorkcellView {

    name:string;
    order:number;
    id?:number;

    constructor(name:string, order:number, id?:number) {
        this.name = name;
        this.order = order;
        this.id = id;
    }
}