import { Part } from "./part.model";
import { Supplier } from "./supplier.model";
import { SupplierQualityFile } from "./supplierQualityFile.model";
import { SupplierQualityImpactedProduct } from "./supplierQualityImpactedProduct.model";
import { SupplierQualityStakeholder } from "./supplierQualityStakeholder.model";
import { SupplierQualityStep } from "./supplierQualityStep.model";
import { SupplierQualityWorkload } from "./supplierQualityWorkload.model";

export enum SupplierQualityStatus{
    Potential,
    ToBeConfirmed,
    InProgress,
    Closed
}

export class SupplierQuality {

    id?:number;
    factoryId:number;
    partId:string | null;
    part:Part | null;
    title:string | null;
    creatorEmployeeId?:string | null;
    creatorName?:string | null;
    creatorEmail?:string | null;
    ownerEmployeeId:string | null;
    ownerName:string | null;
    ownerEmail:string | null;
    creationDate?:Date | null;
    comment:string | null;
    status?:SupplierQualityStatus;
    supplierParmaCode:string | null;
    supplier:Supplier | null;
    materialControllerEmployeeId?:string | null;
    materialControllerName?:string | null;
    materialControllerEmail?:string | null;
    steps:SupplierQualityStep[] | null;
    stakeholders:SupplierQualityStakeholder[] | null;
    files: SupplierQualityFile[] | null;
    impactedProducts:SupplierQualityImpactedProduct[] | null;
    workloads:SupplierQualityWorkload[] | null;

    constructor(
        factoryId:number,
        partId:string | null,
        part:Part | null,
        title:string | null,
        creatorEmployeeId:string | null,
        creatorName:string | null,
        creatorEmail:string | null,
        ownerEmployeeId:string | null,
        ownerName:string | null,
        ownerEmail:string | null,
        creationDate:Date | null,
        comment:string | null,
        status:SupplierQualityStatus,
        supplierParmaCode:string | null,
        supplier:Supplier | null,
        materialControllerEmployeeId:string | null,
        materialControllerName:string | null,
        materialControllerEmail:string | null,
        steps:SupplierQualityStep[] | null,
        stakeholders:SupplierQualityStakeholder[] | null,
        files:SupplierQualityFile[] | null,
        impactedProducts:SupplierQualityImpactedProduct[] | null,
        workloads:SupplierQualityWorkload[] | null,
        id?:number) {

        this.factoryId = factoryId;
        this.id = id;
        this.partId = partId;
        this.part = part;
        this.title = title;
        this.creatorEmployeeId = creatorEmployeeId;
        this.creatorName = creatorName;
        this.creatorEmail = creatorEmail;
        this.ownerEmployeeId = ownerEmployeeId;
        this.ownerName = ownerName;
        this.ownerEmail = ownerEmail;
        this.creationDate = creationDate;
        this.comment = comment;
        this.status = status;
        this.supplierParmaCode = supplierParmaCode;
        this.supplier = supplier;
        this.materialControllerEmployeeId = materialControllerEmployeeId;
        this.materialControllerName = materialControllerName;
        this.materialControllerEmail = materialControllerEmail;
        this.steps = steps;
        this.stakeholders = stakeholders;
        this.files = files;
        this.impactedProducts = impactedProducts;
        this.workloads = workloads;
    }
}