import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Factory } from '../models/factory.model';
import * as moment from 'moment-timezone';
import { Language } from '../models/language.enum';

@Pipe({
  name: 'demoDate'
})
//LANGUAGE SETTING
export class DemoDatePipe extends DatePipe implements PipeTransform {
  override transform(value: any, args?: any): any {
    const language:string = localStorage.getItem("language")!;    
    const tz = JSON.parse(localStorage.getItem("currentFactory")!)?.timeZoneId;  // Get the selected factory timezone
    const selectedTime = moment.tz(value, tz);

    if(args === "onlyDate"){
      if(language === Language.English){
        return selectedTime.format('MM/DD/YYYY');
      }
      else if(language === Language.French){
        return selectedTime.format('DD/MM/YYYY');
      }
      else if(language === Language.Dutch){
        return selectedTime.format('DD:MM:yy');
      }
    }

    if(language === Language.English){
      return selectedTime.format('MM/DD/YYYY h:mm a');
    }
    else if(language === Language.French){
      return selectedTime.format('DD/MM/YYYY HH:mm');
    }
    else if(language === Language.Dutch){
      return selectedTime.format('DD:MM:yy HH:mm:ss');
    }
  
    return super.transform(value, "EEEE d MMMM y h:mm a");
  }
} 