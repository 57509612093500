
<div class="reset-input" *ngIf="!isDisabled">
  <svg *ngIf="filterInputText != '' || this.selectedFunction != null || this.selectedMissingCause != null || this.selectedResponsible != null" (click)="clear()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062">
    <path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/>
    <path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/>
  </svg>
</div>

<div class="tooltip-container">
  <input matInput type="text" placeholder="{{getSelectedFullPath()}}" [(ngModel)]="filterInputText" (input)="filterChanged($event)" (focus)="onFocus()" autocomplete="off" *ngIf="!isDisabled" [ngClass]="{'warning' :warningFieldNotVoid}">
  <input matInput type="text" placeholder="{{getSelectedFullPath()}}" [(ngModel)]="filterInputText" (input)="filterChanged($event)" (focus)="onFocus()" (blur)="stopFocus($event)" autocomplete="off" *ngIf="isDisabled" disabled>
  
  <!--Warning and errors-->
  <div class="warning-container" *ngIf="warningFieldNotVoid && !isFilterInputFocus">
    <mat-icon class="mat-icon-rtl-mirror error">{{'error'}}</mat-icon>
    <span class="error-message">{{"SEARCH RUNNING" | translate}}</span>
  </div>

  <div class="tooltip-content" *ngIf="selectedFunction != null && !isFilterInputFocus">
    <span>{{getSelectedFullPath()}}</span>
  </div>
  <div class="field-options">
    <mat-icon class="mat-icon-rtl-mirror small" (click)="isSettingsOpen = !isSettingsOpen">{{'settings'}}</mat-icon>
    <div class="options-container" *ngIf="isSettingsOpen">
      <div class="option option-level">
        {{"LEVEL SETTINGS EXPLANATION"| translate}}
        <mat-select [(value)]="selectedLevelOption" (selectionChange)="changeOptionLevel($event)">
          <mat-option [value]="1">{{"LEVEL" | translate}} 1</mat-option>
          <mat-option [value]="2">{{"LEVEL" | translate}} 2</mat-option>
          <mat-option [value]="3">{{"LEVEL" | translate}} 3</mat-option>
          <mat-option [value]="4">{{"LEVEL" | translate}} 4</mat-option>
        </mat-select>
      </div>
      <div class="option option-code">
        {{"CODE SETTINGS EXPLANATION"| translate}}
        <mat-slide-toggle [(ngModel)]="showCode" (change)="toggleShowCode($event)">{{"SHOW CODE" | translate}}</mat-slide-toggle>
      </div>
    </div>
  </div>
</div>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" [ngClass]="{'active': isFilterInputFocus}" *ngIf="!isDisabled" (mousedown)="cancelBlur()">  
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [ngClass]="{'active': selectedFunction?.id == node.name.split('/', 1)[0] || selectedMissingCause?.id == node.name.split('/', 1)[0], 'active-path':isSelectedPath(node)}" (click)="selectNode(node)" (keyup.enter)="selectNode(node)" tabIndex="0">
      <!-- use a disabled button to provide padding for tree leaf -->
      <button mat-icon-button disabled type='button'></button>
      <span *ngIf="showCode">{{node.name.split('/', 2)[1]}}</span>
      <span *ngIf="!showCode">{{nodeNameWithoutCode(node.name.split('/', 2)[1])}}</span>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding [ngClass]="{'active': selectedFunction?.id == node.name.split('/', 1)[0] || selectedMissingCause?.id == node.name.split('/', 1)[0], 'active-path':isSelectedPath(node), 'to-be-selected': false}" (click)="selectNode(node)" (keyup.enter)="selectNode(node)" tabIndex="0" (keyup.arrowleft)="treeControl.collapse(node)" (keyup.arrowright)="treeControl.expand(node)">
      <button mat-icon-button matTreeNodeToggle type='button'
              [attr.aria-label]="'Toggle ' + node.name" tabindex="-1">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <span *ngIf="showCode">{{node.name.split('/', 2)[1]}}</span>
      <span *ngIf="!showCode">{{nodeNameWithoutCode(node.name.split('/', 2)[1])}}</span>
    </mat-tree-node>
  </mat-tree>