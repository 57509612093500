import { BaseStep } from "./baseStep.model";
import { SupplierQualityStepFile } from "./supplierQualityStepFile.model";

export enum SupplierQualityStepTitle {
    "STAKEHOLDERS",
    "PROBLEM DESCRIPTION",
    "CONTAINMENT",
    "ROOT CAUSE ANALYSIS",
    "PERMANENT CORRECTIVE ACTIONS",
    "VALIDATION",
    "PREVENT RECURRENCE",
    "CLOSE PROBLEM AND RECOGNIZE CONTRIBUTIONS",
}

export class SupplierQualityStep extends BaseStep {

    supplierQualityId:number;

    constructor(
        supplierQualityId:number,
        stepIndex:number,
        closed:boolean,
        comment:string,
        ownerEmployeeId:string,
        ownerName:string,
        ownerEmail:string,
        files?:SupplierQualityStepFile[]
    ) {
        super(stepIndex, closed, comment, ownerEmployeeId, ownerName, ownerEmail, files);
        this.supplierQualityId = supplierQualityId;
    }
}