import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WorkcellView } from '../models/workcellView.model';

@Injectable({
  providedIn: 'root'
})
export class HeaderDataService {
  //Current Checkpoint 
  private chosenCheckpoint$: BehaviorSubject<[WorkcellView, number, number]> = new BehaviorSubject<[WorkcellView, number, number]>([new WorkcellView("no name", -1), -2, -2]);

  public setChosenCheckpoint(newChosenCheckpoint: WorkcellView, factoryId:number, lineId:number): void {
    // Sets new value, every entity, which is subscribed to changes (`getNavTitle().subscribe(...)`) will get new value every time it changes
    this.chosenCheckpoint$.next([newChosenCheckpoint, factoryId, lineId]);
  }

  public getChosenCheckpoint(): Observable<[WorkcellView, number, number]> {
    // Allow to `subscribe` on changes and get the value every time it changes
    return this.chosenCheckpoint$.asObservable();
  }
}
