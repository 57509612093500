import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, PopupRequest, RedirectRequest } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root', // Makes this service available application-wide
})
export class AuthService {
  constructor(private authService: MsalService) {}

  getUserId(): string {
    return this.getActiveAccount()?.idTokenClaims?.['mailNickName']?.toString() || '';
  }

  getActiveAccount() {
    return this.authService.instance.getActiveAccount();
  }

  getAllAccounts(){
    return this.authService.instance.getAllAccounts();
  }

  setActiveAccount(account:AccountInfo){
    this.authService.instance.setActiveAccount(account);
  }

  loginPopup(popupRequest?:PopupRequest){
    if(popupRequest) {
      this.authService.loginPopup(popupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  loginRedirect(redirectRequest?:RedirectRequest){
    if(redirectRequest){
      this.authService.loginRedirect(redirectRequest);
    }else{
      this.authService.loginRedirect();
    }
  }

  logout(){
    this.authService.logout();
  }
}