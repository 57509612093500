import { Component, ElementRef, HostListener, Inject, OnInit, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {FormArray, FormControl, FormGroup, PatternValidator} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserRole } from 'src/app/models/userRole.model';
import { Role } from 'src/app/models/role.model';
import { TranslateService } from '@ngx-translate/core';
import { MissingPart, MissingPartStatus } from 'src/app/models/missingPart.model';
import { Product } from 'src/app/models/product.model';
import { Deviation, MissingStatus } from 'src/app/models/deviation.model';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { DateFormatDisplay, Language } from 'src/app/models/language.enum';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MissingCause } from 'src/app/models/missingCause.model';
import { ignoreElements, of, Subject, Subscription, takeUntil } from 'rxjs';
import { FaultType } from 'src/app/models/faultType.model';
import { Agent } from 'src/app/models/agent.model';
import { MissingPartStakeholder } from 'src/app/models/missingPartStakeholder.model';
import { AccountInfo } from '@azure/msal-browser';
import { Factory } from 'src/app/models/factory.model';
import { MissingPartStep, MissingPartStepTitle } from 'src/app/models/missingPartStep.model';
import { MissingPartHistory } from 'src/app/models/missingPartHistory.model';
import { ActionType } from 'src/app/models/deviationHistory.model';
import { MissingPartImpactedProductionLine } from 'src/app/models/missingPartImpactedProductionLine.model';
import { Part } from 'src/app/models/part.model';
import * as Diacritics from 'diacritics';
import { PartViewModel } from 'src/app/models/partViewModel.model';
import { DeviceCodeInfo } from '@azure/identity';
import * as graphHelper from 'src/app/graphHelper';
import settings, { AppSettings } from 'src/app/appSettings';
import { User } from '@microsoft/microsoft-graph-types';
import {MatDatepickerInputEvent, MatDatepickerModule} from '@angular/material/datepicker';
import { Responsible } from 'src/app/models/responsible.model';
import { Supplier } from 'src/app/models/supplier.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { AuthService } from 'src/app/services/auth.service';
import { UserRolesService } from 'src/app/services/user-roles.service';

const errorMessagePopupTitle = "SOMETHING WRONG";

@Component({
  selector: 'app-missing-parts-mg-page',
  templateUrl: './missing-parts-mg-page.component.html',
  styleUrls: ['./missing-parts-mg-page.component.less'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-US'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})

export class MissingPartsMgPageComponent implements OnInit {

  @HostListener('window:keydown.escape') escapeEvent() {
    if(!this.hasDeviationToEdit && !this.hasDeviationToSolve && document.getElementsByClassName('inner-popup').length === 0){
      this.closePopUp();
    }
  }
  @HostListener('window:keydown.enter') enterEvent() {
    if(this.hasMissingPartToValidate){
      this.validateMissingPart();
    }
    else if(this.hasMissingPartToClose){
      this.closeMissingPart();
    }
    else if(this.searchIsFocus){
      this.selectMissingPartByScan();
    }
  }

  @HostListener('window:keyup.s') searchEvent() {
    if(!this.hasMissingPartToAdd && !this.hasMissingPartToEdit && !this.hasMissingPartToValidate && !this.hasMissingPartToClose && !this.inputStakeholdersFocused && !this.showProblemStatus && !this.hasDeviationToEdit && !this.hasDeviationToSolve){
      this.toggleSearch();
    }
  }

  @HostListener('scroll', ['$event'])
  onDivScroll(event: Event): void {
    const scrollOffset = (event.target as HTMLElement).scrollTop;
    // Update the tooltip's position based on the scrollOffset
    // You may need to use ElementRef and Renderer2 for DOM manipulation
  }

  //@HostListener('window:keydown.control') searchEvent() { this.toggleSearch(this.searchInputElement.nativeElement); }

  apiUrl:string =  environment.API_URL;
  roleAuth!:Role;
  isGenericUser:boolean = false;
  private unsubscribe$ = new Subject<void>();

  filters:number[] = [];
  missingParts!:MissingPart[];
  filteredMissingParts!:MissingPart[];
  selectedMissingPart!:MissingPart | null;
  selectedProduct!:Product | null;
  selectedDeviation!:Deviation | null;

  //SEARCH MISSING CAUSE
  missingCauses!:MissingCause[];
  selectedMissingCause!: number | null | undefined;
  selectedMissingCauseId!:number | null | undefined;
  warningMissingCauseField:boolean = false;

  // DEFAULT RESPONSIBLE
  responsibles:Responsible[] = [];
  selectedDefaultResponsibleId!:number | null;
  warningDefaultResponsibleField:boolean = false;

  currentFactory!:Factory;
  currentFactoryId:number = -1;

  currentLineId:number = -1;
  urlMissingPartId:number = -1;

  hasMissingPartToAdd:boolean = false;
  hasMissingPartToEdit:boolean = false;
  hasMissingPartToValidate:boolean = false;
  hasMissingPartToAssign:boolean = false;
  hasMissingPartToClose:boolean = false;
  hasDeviationToEdit:boolean = false;
  hasDeviationToSolve:boolean = false;
  hasNotificationToSend:boolean = false;
  stakeholderToRemove!:MissingPartStakeholder | null;
  stakeholderToAssign!:MissingPartStakeholder | null;
  showProblemStatus:boolean = false;
  isFormDisabled:boolean = false;

  impactedDeviations!:Deviation[];

  isMissingPartDuplicate:boolean = false;
  isCreatingMissingPart:boolean = false;

  isSavingData:boolean = false;
  isSavingStep:number = -1;
  isLoadingData:boolean = false;
  
  showMissingPartHistory:boolean = false;
  loadingMissingPartHistory:boolean = false;
  missingPartHistories!:MissingPartHistory[];

  cancelClosePopup:boolean = false;

  isProductListOpen:boolean = true;

  //FORMS
  missingPartForm = new FormGroup({
    partId: new FormControl(''),
    title: new FormControl(),
    impactedProductsNumber : new FormControl(),
    cause : new FormControl(''),
    supplierParmaCode : new FormControl(''),
    comment : new FormControl(''),
    dueTo : new FormControl(),
    commingProduct : new FormControl(''),
    plannedMissingDate: new FormControl(),
    plannedArrival: new FormControl(),
    impactedProductionLines: new FormArray([])
  });

  isFieldLinesExpanded:boolean = false;

  //PART ID INPUT
  missingPartOptions:PartViewModel[] = [];
  inputMpFocused:boolean = false;
  selectedMissingPartOption!:PartViewModel | null;
  toBeSelectedPartOption!:PartViewModel | null;
  optionsLimit:number = 100;

  //POSSIBLE SUPPLIERS
  selectedMissingPartPossibleSuppliers:Supplier[] = [];
  suppliersLoaded:boolean = true;

  //STAKEHOLDERS
  inputStakeholdersFocused:boolean = false;
  stakeholderFilter:string = "";
  agentList:Agent[] = [];
  azureUsersList:Agent[] = [];
  isLoadingAzureUsers:boolean = false;
  warningStakeholderMessage:string = "";

  @ViewChild('firstMPInput') firstMPInput!: ElementRef;
  @ViewChild('afterTreeInput') afterTreeInput!: ElementRef;

  //SEARCH PRODUCT FEATURE
  searchText:string = '';
  searchIsFocus:boolean = false;
  @ViewChildren('search') searchInputElement!: QueryList<ElementRef>;

  //PROBLEM SOLVING
  MissingPartStepTitle = MissingPartStepTitle;

  //HANDLING ERRORS
  errorMessage:string = "";
  errorMessageSelectedMP:string = "";

  screenHeight!: number;
  screenWidth!: number; 

  //NOTIFICATION MESSAGE
  messagePopupType:string = "";
  messagePopupTitle:string = "";
  messagePopupText:string = "";

  //IMPACTED PRODUCT MP POPUP
  isRefreshingImpactedProduct = false;

  constructor(
    private httpClient:HttpClient,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private authService:AuthService,
    private roleService:UserRolesService,
    private translate: TranslateService,
    private clipboard: Clipboard,
    /*private datePipe: DatePipe*/
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,) {
      this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((data:any)=>{
      this.currentFactoryId = Number(data.params.factoryId);
      this.currentLineId = Number(data.params.lineId);
      this.urlMissingPartId = Number(data.params.missingPartId);
      
      this.getMissingParts(this.currentLineId, this.urlMissingPartId == null || Number.isNaN(this.urlMissingPartId));
      this.getCurrentFactory(this.currentFactoryId);
      this.setCalendarLanguage(localStorage.getItem('language')!);
      this.getMissingCause(this.currentFactoryId);
      this.getResponsible(this.currentFactoryId);
      this.getAgentList(this.currentFactoryId);
      this.getMissingPartOptions();
    })
    graphHelper.initializeGraphForUserAuth(settings, (info: DeviceCodeInfo) => {});

    this.roleService.roles$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      if (res) {
        this.roleAuth = res.userRoles;
        this.isGenericUser = res.isGenericUser;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getActiveAccount(): AccountInfo | null{
    return this.authService.getActiveAccount();
  }

  getUserId(){
    return this.authService.getUserId();
  }

  getCurrentFactory(currentId:number){
    if(currentId == null){ return;}
    this.httpClient.get<Factory>(this.apiUrl + "Factory/" + currentId).subscribe(
      (res: any) => {
        this.currentFactory = res
      }
    );
  }

  getMissingParts(lineId:number, autoselect = true, callback?:() => void): void {
    if(this.currentFactoryId == null || lineId == null){ return;}

    const requestUrl = lineId !== -1 ? this.apiUrl + 'MissingPart/ByProductionLineId/'+ this.currentFactoryId + '/' + lineId : this.apiUrl + 'MissingPart/ByFactoryId/' + this.currentFactoryId;

    this.httpClient.get<any[]>(requestUrl).subscribe({
        next: (res: any[]) => this.handleGetMissingPartsSuccess(res, autoselect, callback),
        error: (e: any) => this.handleGetMissingPartsError(e),
    });
  }

  private handleGetMissingPartsSuccess(res:any[], autoselect: boolean, callback?: () => void): void {
    console.log("missing parts", res);
    this.missingParts = res;
    this.filters = JSON.parse(localStorage.getItem('mpFilter')!) ?? [0, 1, 2];     
    this.filterMissingParts(autoselect);

    if(this.urlMissingPartId !== -1 && this.urlMissingPartId != null && !Number.isNaN(this.urlMissingPartId)){
      if(this.missingParts.find(x=>x.id === this.urlMissingPartId)){
        this.selectMissingPartById(this.urlMissingPartId);
      }
      else{
        this.errorMessageSelectedMP = "MISSING PART NOT ON THIS LINE"
      }
      window.history.replaceState("", "", "/missing-parts/" + this.currentFactoryId + "/" + this.currentLineId);
    }

    if (callback) {callback();}
  }

  private handleGetMissingPartsError(e:any): void {
    if (e.status === 404) {
        this.errorMessage = "NO MISSING PART MESSAGE";
    }
    this.missingParts = [];
    this.filteredMissingParts = [];
    this.selectedMissingPart = null;
  }

  getMissingCause(factoryId:number){
    if(factoryId == null){ return;}
    this.httpClient.get<MissingCause[]>(this.apiUrl + 'MissingCause/ByFactoryId/'+ factoryId).subscribe(
      (res: MissingCause[]) => {
        console.log("missing cause",res)
        this.missingCauses = res;
      }
    );
  }

  getResponsible(factoryId:number){
    if(factoryId == null){ return;}
    this.httpClient.get<Responsible[]>(this.apiUrl + 'Responsible/ByFactoryId/'+ factoryId).subscribe(
      (res: Responsible[]) => {
        console.log("responsible",res)
        this.responsibles = res;
      }
    );
  }

  getAgentList(factoryId:number){
    if(factoryId == null){ return;}
    this.httpClient.get<Agent[]>(this.apiUrl + 'MissingPart/AgentsByFactoryId/'+ factoryId).subscribe(
      (res: Agent[]) => {
        console.log("agents",res);
        this.agentList = res;
      }
    );
  }

  setFilter(filterStatusIndex:number){
    if(this.missingParts == null){ return;}

    if(this.filters.includes(filterStatusIndex)){
      this.filters.splice(this.filters.indexOf(filterStatusIndex), 1);
    }
    else{
      this.filters.push(filterStatusIndex);
    }
    
    localStorage.setItem("mpFilter", JSON.stringify(this.filters));
    this.filterMissingParts(true);
  }

  filterMissingParts(autoselect:boolean = false){
    if(this.missingParts == null){ return;}

    if(this.filters.length === 0){
      this.filteredMissingParts = this.missingParts;
    }
    else{
      this.filteredMissingParts = [];
      this.filters.forEach(filter => {
        this.filteredMissingParts = this.filteredMissingParts.concat(this.missingParts.filter(mp => mp.missingPartStatus === filter));
      });
    }

    this.orderFilteredMissingParts();

    if(autoselect && this.filteredMissingParts.length !== 0){
      this.selectMissingPart(this.filteredMissingParts[0]);
    }
  }

  orderFilteredMissingParts(){
    this.filteredMissingParts.sort((a:MissingPart, b:MissingPart) => {        
      if(a.creationDate == null){
        return -1;
      }
      else if(b.creationDate == null){
        return 1;
      }
      else if (a.creationDate >= b.creationDate) {
          return -1;
      }
      else if (a.creationDate < b.creationDate) {
          return 1;
      }
      return 0;
    });    
  }

  selectMissingPart(selected:MissingPart){
    if(selected == null || selected.id == null){ return;}
    this.selectMissingPartById(selected.id);
  }

  selectMissingPartByScan(){
    const toSelect:MissingPart = this.missingParts.find(p=>p.partId === this.searchText)!;
    
    if(toSelect != null){
      this.selectMissingPartById(toSelect.id!);
    }else{
      //no corresponding mp
    }

    this.searchText = "";
  }

  selectMissingPartById(toSelectId:number, callback?:() => void){
    if(toSelectId == null){ return;}
    this.impactedDeviations = [];
    this.errorMessageSelectedMP = '';
    this.httpClient.get<MissingPart>(this.apiUrl + 'MissingPart/'+ toSelectId).subscribe(
      (res: any) => {
        console.log("missing part selected", res);
        this.selectedMissingPart = res;

        this.setImpactedDeviations(res);
        this.filterImpactedDeviations();

        if(this.screenWidth <= 1100){
          this.isProductListOpen = false;
        }

        if(callback){ callback();}
      }
    );
    
  }

  getSuppliers(partId:string, factoryId:number){
    if(partId == null || factoryId == null){ return;}
    this.selectedMissingPartPossibleSuppliers = [];
    this.suppliersLoaded = false;

    this.httpClient.get<any>(this.apiUrl + 'MissingPart/Suppliers/ByPartAndFactory/' + partId + '/' + factoryId).subscribe(
    {
      next: (res:Supplier[]) => {
        if((this.hasMissingPartToEdit || this.showProblemStatus) && this.selectedMissingPart?.supplierParmaCode != null && this.selectedMissingPart.supplier != null
          && res.find(x=> x.parmaCode === this.selectedMissingPart?.supplierParmaCode) == null){
            res.push(this.selectedMissingPart.supplier);
        }

        this.selectedMissingPartPossibleSuppliers = this.sortSuppliers(res);
        this.setSupplierField();      
        this.suppliersLoaded = true;
      },
      error: (e) => {
        this.suppliersLoaded = true;
      }
    }
    );
  }

  sortSuppliers(suppliers:Supplier[]):Supplier[]{
    return suppliers.sort((a:Supplier, b:Supplier) => {
      if(a.POB != null && b.POB != null){
        return this.compareByPOBAndDate(a,b);}
      else if(a.POB == null && b.POB == null){
        return this.compareByDate(a,b);}
      else{
        return a.POB == null ? 1 : -1;} 
    });
  }

  private compareByPOBAndDate(a: Supplier, b: Supplier): number {
    if (a.POB !== b.POB) {
        return a.POB! > b.POB! ? -1 : 1;
    }
    return this.compareByDate(a, b);
  }

  private compareByDate(a: Supplier, b: Supplier): number {
      return a.modificationDate > b.modificationDate ? -1 : 1;
  }

  setSupplierField(){
    if(this.hasMissingPartToEdit && this.selectedMissingPart != null && this.selectedMissingPart.supplierParmaCode != null
      && this.selectedMissingPartPossibleSuppliers.find(x=> x.parmaCode === this.selectedMissingPart?.supplierParmaCode)){ return;}
    
    if(this.selectedMissingPartPossibleSuppliers.length === 1){
      this.missingPartForm.controls['supplierParmaCode'].setValue(this.selectedMissingPartPossibleSuppliers[0].parmaCode);
      this.missingPartForm.controls['supplierParmaCode'].updateValueAndValidity();
    }
  }

  setImpactedDeviations(mp:MissingPart){
    this.impactedDeviations = [];
    if(mp == null || mp.impactedProducts == null){ return;}

    mp.impactedProducts.forEach((product:Product) => {
      product.deviations.forEach(deviation => {
        this.impactedDeviations.push(deviation);
      });
    });
  }

  filterImpactedDeviations() {
    this.impactedDeviations.sort((a: any, b: any) => {
      if (a.missingPartStatus !== b.missingPartStatus) {
        return a.missingPartStatus - b.missingPartStatus;
      }
      return b.detectionDate - a.detectionDate;
    });
  }

  closePopUp(){
    if(!this.cancelClosePopup){
      this.hasMissingPartToAdd = false;
      this.hasMissingPartToEdit = false;
      this.hasMissingPartToValidate = false;
      this.hasMissingPartToClose = false;
      this.hasMissingPartToAssign = false;
      this.hasNotificationToSend = false;
      this.hasDeviationToSolve = false;
      this.inputMpFocused = false;
      this.selectedProduct = null;
      this.selectedDeviation = null;
      this.selectedMissingCauseId = null;
      this.selectedDefaultResponsibleId = null;
      this.missingPartForm.enable();
      this.stakeholderToRemove = null;
      this.stakeholderToAssign = null;
      this.showProblemStatus = false;
      this.showMissingPartHistory = false;
      this.selectedMissingPartOption = null;
      this.optionsLimit = 100;
      this.toBeSelectedPartOption = null;
      this.closeMessagePopup();
    }else{
      this.cancelClosePopup = false;
    }
  }

  closeSolvePopUp(){
      this.hasDeviationToSolve = false;
      this.selectedDeviation = null;
      setTimeout(()=>{
        this.toggleSearch();
      },100);
      this.closeMessagePopup();
  }

  cancelClosePopUp(){
    this.cancelClosePopup =  true;
  }

  //show or hide the search bar
  toggleSearch(s?:HTMLInputElement){
    this.searchInputElement.first.nativeElement.focus();
  }


  openMissingPartPopUp(missingPart?:MissingPart){
    if(missingPart != null){
      this.hasMissingPartToEdit = true;
      this.getSuppliers(missingPart.partId, this.currentFactoryId);
    }
    else{
      this.hasMissingPartToAdd = true;
      this.selectedMissingPartPossibleSuppliers = [];
    }
    
    if(!this.roleAuth.missingPartManagement){
      this.isFormDisabled = true;
    }

    this.isMissingPartDuplicate = false;
    this.setFormValues(missingPart);
    this.isCreatingMissingPart=false;
    

    setTimeout(()=>{
      this.firstMPInput.nativeElement.focus();
    },100);
  }

  openProductPopUp(deviation:Deviation){
    if(!this.roleAuth?.readDeviation){ return; }
    console.log("open popup deviation mp page",deviation);
    this.selectedDeviation = deviation!;
    this.selectedProduct = this.getImpactedDeviationProduct(deviation.productId)!;
    this.hasDeviationToEdit = true;
  }

  getImpactedDeviationProduct(productId:number):Product | undefined{
    if(productId == null){ return undefined;}

    return this.selectedMissingPart?.impactedProducts?.find(x=>x.id === productId);
  }

  closeProductPopUp(deviation:Deviation | null){
    if(deviation != null){
      this.isLoadingData = true;
      const oldMissingPartId = deviation.missingPartId;
      //update deviation info in related product list 
      let selectedMissingPartProduct:Product | null = null;
      selectedMissingPartProduct = this.getImpactedDeviationProduct(deviation.productId) || null;
      
      if(selectedMissingPartProduct != null){
        const index:number = selectedMissingPartProduct.deviations.findIndex(x=>x.id === deviation.id);

        //if not missing part anymore remove deviation from the list
        if(deviation.missingPartId == null){
          selectedMissingPartProduct.deviations.splice(index, 1);
          //if related product has no more deviation, remove it too
          if(selectedMissingPartProduct.deviations.length === 0){
            this.selectedMissingPart?.impactedProducts?.splice(this.selectedMissingPart.impactedProducts.findIndex(x=>x.id === selectedMissingPartProduct?.id),1);
          }
        }
        else if(index !== -1){
          selectedMissingPartProduct.deviations[index] = deviation;
        }
      }

      const tmpDeviation:Deviation = Object.assign({}, deviation);

      //in case of a new missing part has been created
      this.getMissingParts(this.currentLineId, false, () => {
        //if the missing part is still in the displayed list
        if(oldMissingPartId != null && this.filteredMissingParts.findIndex(x=> x.id === oldMissingPartId) !== -1){
          this.selectMissingPartById(oldMissingPartId, () => {
            this.isLoadingData = false;
          })
        }
        else if(this.filteredMissingParts[0] != null && this.filteredMissingParts[0].id != null){
          this.selectMissingPartById(this.filteredMissingParts[0].id, () => {
            this.isLoadingData = false;
          });
        }
      });
    }

    this.selectedDeviation = null;
    this.selectedProduct = null;
    this.hasDeviationToEdit = false;

    this.closeMessagePopup();
  }

  onMissingPartSubmit() {
    if(!this.missingPartForm.valid || this.isCreatingMissingPart || this.isSavingData || this.isMissingPartDuplicate
      || this.warningMissingCauseField || this.warningDefaultResponsibleField){ return;}

    if(this.hasMissingPartToAdd){
      this.createMissingPart();
    }
    else if(this.hasMissingPartToEdit){
      this.modifyMissingPart();
    }
  }

  checkMissingPartDuplicate(partId:string){
    if(partId == null || this.currentFactoryId == null || Number.isNaN(this.currentFactoryId)){ return;}
    this.httpClient.get<any>(this.apiUrl + 'MissingPart/Exists/' + encodeURIComponent(partId) + '/' + this.currentFactoryId).subscribe(
      res => {
        this.isMissingPartDuplicate = res;
      }
    );
  }

  createMissingPart(){
    if(this.isCreatingMissingPart){ return;}

    const newMissingPart:MissingPart = {
      "comment":this.missingPartForm.value.comment || "",
      "part":null,
      "partId": this.missingPartForm.value.partId!,
      "title":this.missingPartForm.value.title == null || this.missingPartForm.value.title === "" ? this.missingPartForm.value.partId! : this.missingPartForm.value.title!,
      "impactedProductsNumber":this.missingPartForm.value.impactedProductsNumber != null && this.missingPartForm.value.impactedProductsNumber !== "" ? this.missingPartForm.value.impactedProductsNumber : null,
      "plannedMissingDate": this.missingPartForm.value.plannedMissingDate !== "" && this.missingPartForm.value.plannedMissingDate != null ? new Date(this.missingPartForm.value.plannedMissingDate) : null, 
      "plannedArrival": this.missingPartForm.value.plannedArrival !== ""  && this.missingPartForm.value.plannedArrival != null ? new Date(this.missingPartForm.value.plannedArrival) : null,
      "carrier":"",
      "factoryId":Number(this.currentFactoryId),
      "creationDate":new Date(),
      "creator":this.authService.getActiveAccount()?.username!,
      "creatorOperatorEmployeeId":null,
      "creatorOperatorName":null,
      "cause":this.missingPartForm.value.cause || "",
      "missingCauseId":this.selectedMissingCauseId || null,
      "defaultResponsibleId":this.selectedDefaultResponsibleId || null,
      "defaultResponsible":null,
      "defaultResponsibleLevel1":null,
      "defaultResponsibleLevel1Id":null,
      "defaultResponsibleLevel2":null,
      "defaultResponsibleLevel2Id":null,
      "defaultResponsibleLevel3":null,
      "defaultResponsibleLevel3Id":null,
      "missingPartStatus":MissingPartStatus.Potential,
      "supplierParmaCode": this.missingPartForm.value.supplierParmaCode || null,
      "ownerEmployeeId": "",
      "ownerName": "",
      "ownerEmail": "",
      "materialControllerEmployeeId":"",
      "materialControllerName":"",
      "materialControllerEmail":"",
      "impactedProducts": null,
      "stakeholders":null,
      "steps":null,
      "impactedProductionLines":null,
    }
    this.setDefaultResponsible(newMissingPart);

    newMissingPart.impactedProductionLines = [];
    this.missingPartForm.value.impactedProductionLines?.forEach(lineId => {
      newMissingPart.impactedProductionLines?.push(new MissingPartImpactedProductionLine(0, lineId, null));
    });

    this.isCreatingMissingPart = true;

    console.log("mp creation", newMissingPart);
    
    this.httpClient.post<MissingPart>(this.apiUrl + 'MissingPart', newMissingPart).subscribe(
      {
        next: (res) => {
          this.closePopUp();
          this.selectedMissingPart = res;
          this.setImpactedDeviations(res);
          this.missingParts.push(this.selectedMissingPart);

          if(!this.filters.includes(MissingPartStatus.Potential)){
            this.setFilter(MissingPartStatus.Potential);}
          else{
            this.filterMissingParts(true);}
          
          this.isCreatingMissingPart = false;
          this.displayMessagePopup({type:"success", title:"SAVED", text:"SAVED CORRECTLY"});
        },
        error: (e) => {
          this.isCreatingMissingPart = false;
          this.displayMessagePopup({type:"error", title: errorMessagePopupTitle, text:"PLS RETRY"});
          console.error(e);
        }
      }
    );
  }

  modifyMissingPart(){
    if(this.selectedMissingPart == null){ return;}

    this.isSavingData = true;

    if(this.missingPartForm.value.partId != null){
      this.selectedMissingPart.partId = this.missingPartForm.value.partId;}

    this.selectedMissingPart.title = this.missingPartForm.value.title!;
    this.selectedMissingPart.impactedProductsNumber = this.missingPartForm.value.impactedProductsNumber != null && this.missingPartForm.value.impactedProductsNumber !== "" ? this.missingPartForm.value.impactedProductsNumber : null;

    this.selectedMissingPart.supplierParmaCode = this.missingPartForm.value.supplierParmaCode || null;
    this.selectedMissingPart.supplier = null;

    this.selectedMissingPart.missingCauseId = this.selectedMissingCauseId || null;
    this.selectedMissingPart.missingCause = null;

    this.selectedMissingPart.defaultResponsibleId = this.selectedDefaultResponsibleId || null;
    this.selectedMissingPart.defaultResponsible = null;
    this.setDefaultResponsible(this.selectedMissingPart);

    this.selectedMissingPart.comment = this.missingPartForm.value.comment!;
    this.selectedMissingPart.plannedMissingDate = this.missingPartForm.value.plannedMissingDate !== "" && this.missingPartForm.value.plannedMissingDate != null ? new Date(this.missingPartForm.value.plannedMissingDate) : null;
    this.selectedMissingPart.plannedArrival = this.missingPartForm.value.plannedArrival !== ""  && this.missingPartForm.value.plannedArrival != null ? new Date(this.missingPartForm.value.plannedArrival) : null;

    this.selectedMissingPart.impactedProductionLines = [];

    this.missingPartForm.value.impactedProductionLines?.forEach(lineId => {
      this.selectedMissingPart?.impactedProductionLines?.push(new MissingPartImpactedProductionLine(this.selectedMissingPart.id!, lineId, null));
    });

    const elementToSend = Object.assign({}, this.selectedMissingPart);
    elementToSend.steps = null;

    console.log("send new missing part", elementToSend);
    this.httpClient.put<MissingPart>(this.apiUrl + 'MissingPart', elementToSend).subscribe(
      {
        next: (res) => {
          this.selectedMissingPart = res;
          
          this.updateStatusIconMissingPartList(res.id!, res.missingPartStatus, res.title, res.partId);
          if(this.selectedMissingPart?.id === res.id){
            this.selectedMissingPart = res;
          }

          this.isSavingData = false;
          this.closePopUp();
          this.displayMessagePopup({type:"success", title:"SAVED", text:"SAVED CORRECTLY"});
        },
        error: (e) => {
          this.isSavingData = false;
          this.displayMessagePopup({type:"error", title: errorMessagePopupTitle, text:"PLS RETRY"});
          console.error(e);
        }
      }
    );
  }

  getElementParents(elId:number | null, list:any[]):number[]{
    const parents:number[] = [];

    let parent = list.find((x:any)=>x.id === elId);
    while(parent != null && parent.id != null){
      parents.push(parent.id);
      parent = list.find((x:any)=>x.id === parent?.parentId);
    }

    return parents;    
  }

  setDefaultResponsible(missingPart:MissingPart | null){
    if(missingPart == null) { return;}

    const parents:number[] = this.getElementParents(this.selectedDefaultResponsibleId, this.responsibles);

    missingPart.defaultResponsibleId = this.selectedDefaultResponsibleId;
    missingPart.defaultResponsibleLevel1Id = parents[parents.length - 1] || null;
    missingPart.defaultResponsibleLevel2Id = parents[parents.length - 2] || null;
    missingPart.defaultResponsibleLevel3Id = parents[parents.length - 3] || null;

    missingPart.defaultResponsible = null;
    missingPart.defaultResponsibleLevel1 = null;
    missingPart.defaultResponsibleLevel2 = null;
    missingPart.defaultResponsibleLevel3 = null;
  }

  setFormValues(missingPart?:MissingPart){
    const formArray:FormArray = this.missingPartForm.get('impactedProductionLines') as FormArray;
    while(formArray.length !== 0) {
      formArray.removeAt(0);
    };

    this.missingPartForm.reset();
    
    if(missingPart != null && this.selectedMissingPart != null){
      this.missingPartForm.setValue({
        partId: this.selectedMissingPart.partId,
        title: this.selectedMissingPart.title,
        impactedProductsNumber : this.selectedMissingPart.impactedProductsNumber,
        cause : this.selectedMissingPart.missingCauseId?.toString() || "",
        supplierParmaCode : this.selectedMissingPart.supplierParmaCode || "",
        comment : this.selectedMissingPart.comment,
        dueTo : "",
        commingProduct : "",
        plannedMissingDate : this.selectedMissingPart.plannedMissingDate,
        plannedArrival : this.selectedMissingPart.plannedArrival,
        impactedProductionLines: []
      });

      this.findMissingPartOption();

      const formArray2:FormArray = this.missingPartForm.get('impactedProductionLines') as FormArray;
      this.selectedMissingPart.impactedProductionLines?.forEach(line => {
        formArray2.push(new FormControl(line.productionLineId));
      });

      this.selectedMissingCause = this.selectedMissingPart.missingCauseId;
      this.selectedMissingCauseId = this.selectedMissingPart.missingCauseId;

      this.selectedDefaultResponsibleId = this.selectedMissingPart.defaultResponsibleId;

      if(this.selectedMissingPart.missingPartStatus === MissingPartStatus.InProgress || this.selectedMissingPart.missingPartStatus === MissingPartStatus.ToBeConfirmed){
        this.missingPartForm.controls['partId'].disable();
      }

      if(this.isFormDisabled){
        this.missingPartForm.disable();
        (<FormArray>this.missingPartForm.get('impactedProductionLines')).controls.forEach(control => {
          control.disable();
        })
      }
        
      
    }else{
      this.missingPartForm.setValue({
        partId: "",
        title: "",
        impactedProductsNumber : null,
        cause : "",
        supplierParmaCode:"",
        comment : "",
        dueTo : "",
        commingProduct : "",
        plannedMissingDate : null,
        plannedArrival : null,
        impactedProductionLines: []
      });
      this.selectedMissingCause = null;
      this.selectedMissingCauseId = null;
      this.selectedDefaultResponsibleId = null;
    }

  }


  findMissingPartOption(){
    this.selectedMissingPartOption = this.missingPartOptions.find(x => x.partId === this.selectedMissingPart?.partId)!;
  }

  setSelectedMissingCause(missingCauseId:number){
    this.selectedMissingCauseId = missingCauseId;
    this.afterTreeInput.nativeElement.focus();
  }

  getMissingCauseFromList(id:number):MissingCause{
    return this.missingCauses.find((x:MissingCause) => Number(x.id) === id)!;
  }

  scrollToFunction(fieldId:string){
    const elementInScrollableDiv = document.getElementById(fieldId);
    const positionFromTopOfScrollableDiv = elementInScrollableDiv?.offsetTop;
    const scrollableDivElement = document.getElementById('missing-part-popup');

    if(scrollableDivElement && positionFromTopOfScrollableDiv){
      scrollableDivElement.scroll({top: positionFromTopOfScrollableDiv - 80, left:0, behavior:"smooth"});
    }
  }

  setSelectedDefaultResponsible(responsibleId:number){
    this.selectedDefaultResponsibleId = responsibleId;
    this.afterTreeInput.nativeElement.focus();
  }

  getResponsibleElement(id:number):Responsible{
    return this.responsibles.find((x:Responsible) => x.id === id)!;
  }

  openValidatePopUpAfterDeviation(event:any){
    this.selectedProduct = event.product;
    this.selectedDeviation = event.deviation;
    this.hasMissingPartToValidate = true;
  }

  openValidatePopUp(deviation:Deviation, event?:Event){
    event?.stopPropagation();

    this.selectedProduct = this.getImpactedDeviationProduct(deviation.productId)!;
    this.selectedDeviation = deviation;
    this.hasMissingPartToValidate = true;
    this.isSavingData = false;
  }

  openSolvePopUpAfterDeviation(event:any){
    this.openSolvePopUp(null, event.deviation);
  }

  openSolvePopUp(event:Event | null, d:Deviation){
    if(event != null) { event.stopPropagation();}

    if(d.resolutionDate != null || !this.roleAuth?.solveDeviation){ return;}

    this.hasDeviationToSolve = true;
    this.selectedDeviation = Object.assign({}, d);

    this.isSavingData = false;
  }

  openProblemStatus(){
    this.showProblemStatus = true;
    this.getMissingPartHistory(this.selectedMissingPart?.id);
    if(this.selectedMissingPart){
      this.getSuppliers(this.selectedMissingPart.partId, this.currentFactoryId);
    }
  }

  openAssignPopUp(s?:MissingPartStakeholder){
    if(s != null){ this.stakeholderToAssign = s;}

    this.hasMissingPartToAssign = true;
    this.isSavingData = false;
  }

  assignMissingPart(){
    if(this.selectedMissingPart == null ||this.translate.currentLang == null){ return;}
    this.isSavingData = true;

    this.httpClient.put<MissingPart>(this.apiUrl + 'MissingPart/AssignToMe/' + this.selectedMissingPart.id, null).subscribe(
        res => {
          this.closePopUp();
          
          const modifiedMissingPartIndex = this.missingParts.findIndex(x=>x.id === this.selectedMissingPart?.id);
          if(modifiedMissingPartIndex != null && modifiedMissingPartIndex !== -1){
            this.missingParts?.splice(modifiedMissingPartIndex, 1, res);
          }

          this.filterMissingParts();
          this.selectMissingPart(this.selectedMissingPart!);
          this.isSavingData =false;
      }
    );
  }
  

  assignedMissingPart(event:any){
    const modifiedMissingPartIndex = this.missingParts.findIndex(x=>x.id === this.selectedMissingPart?.id);
    if(modifiedMissingPartIndex != null && modifiedMissingPartIndex !== -1){
      this.missingParts?.splice(modifiedMissingPartIndex, 1, event.case);
    }

    this.filterMissingParts();
    this.selectMissingPart(this.selectedMissingPart!);
    this.isSavingData =false;
  }

  openClosePopUp(){
    if(this.canCloseMissingPart()){
      this.hasMissingPartToClose = true;
      this.isSavingData = false;
    }
  }

  validateMissingPart(){
    if(this.selectedMissingPart == null || this.selectedDeviation == null){ return;}
    this.isSavingData = true;

    this.httpClient.put<MissingPart>(this.apiUrl + 'Deviation/Validate/'+ this.selectedDeviation.id, null).subscribe(
      res => {    
        if(this.selectedMissingPart != null && this.selectedMissingPart.impactedProducts != null && this.selectedDeviation != null){
          const deviation = this.selectedMissingPart.impactedProducts.find(x=>x.id === this.selectedDeviation?.productId)?.deviations?.find(x=>x.id === this.selectedDeviation?.id);

          if(deviation != null){
            deviation.missingPartStatus = MissingStatus.Confirmed;
            const impactedDeviation = this.impactedDeviations.find(x=>x.id === deviation?.id);
            if(impactedDeviation){
              impactedDeviation.missingPartStatus = MissingStatus.Confirmed;
            }
          }
        }           
        
        this.updateStatusIconMissingPartList(res.id!, res.missingPartStatus);

        if(this.selectedMissingPart?.id === res.id){
          this.selectedMissingPart = res;
        }

        this.closePopUp();
        this.isSavingData =false;
      }
    );
  }

  updateStatusIconMissingPartList(missingPartId:number, newStatus:MissingPartStatus, newTitle?:string, newPartId?:string){
    const impactedMP = this.missingParts.find(x=>x.id === missingPartId);

    if(impactedMP != null){
      impactedMP.missingPartStatus = newStatus;

      if(newTitle != null && newPartId != null){
        impactedMP.title = newTitle;
        impactedMP.partId = newPartId;
      }
      
    }

    this.missingParts = [...this.missingParts];
    this.filterMissingParts();
  }

  solveDeviation(deviation:Deviation){
    if(this.selectedMissingPart != null && this.selectedMissingPart.impactedProducts != null && this.selectedDeviation != null){
      let deviationInMP = this.selectedMissingPart.impactedProducts.find(x=>x.id === this.selectedDeviation?.productId)?.deviations?.find(x=>x.id === this.selectedDeviation?.id);
      
      if(deviationInMP != null){
        deviationInMP = deviation;
        this.impactedDeviations.splice(this.impactedDeviations.findIndex(x=>x.id === deviation?.id), 1, deviation);
      }
    }
    this.closePopUp();
    this.isSavingData = false;
}

  canCloseMissingPart(){
    return !this.isSavingData &&
           this.hasCauseFilledOut() &&
           this.hasAllDeviationsValidated();
  }

  hasCauseFilledOut(){
    if(this.selectedMissingPart == null || this.selectedMissingPart.missingCause == null){ return false;}

    if(this.isCauseLevel(1, this.selectedMissingPart.missingCause)){
      return this.missingCauses.find(x=>x.level1 === this.selectedMissingPart?.missingCause?.level1 && x.id !== this.selectedMissingPart.missingCause.id) == null;}
    else if(this.isCauseLevel(2, this.selectedMissingPart.missingCause)){
      return this.missingCauses.find(x=>x.level2 === this.selectedMissingPart?.missingCause?.level2 && x.id !== this.selectedMissingPart.missingCause.id) == null;}
    else if(this.isCauseLevel(3, this.selectedMissingPart.missingCause)){
      return true;}
    else{
      return false;}
  }

  isCauseLevel(level:number, cause:MissingCause){
    const levels = [cause.level1, cause.level2, cause.level3];

    // Ensure levels up to the given level are non-empty
    const isValid = levels.slice(0, level).every(l => l != null && l !== "");
    // Ensure levels beyond the given level are empty
    const isBeyondEmpty = levels.slice(level).every(l => l == null || l === "");

    return isValid && isBeyondEmpty;
  }

  hasAllDeviationsValidated(){
    return this.selectedMissingPart?.missingPartStatus !== MissingPartStatus.ToBeConfirmed;
  }

  closeMissingPart(){
    if(this.selectedMissingPart == null || !this.canCloseMissingPart()){ return;}

    this.isSavingData = true;
    
    this.httpClient.put<MissingPart>(this.apiUrl + 'MissingPart/Close/' + this.selectedMissingPart.id, null).subscribe(
      {
        next: (res) => {
          this.closePopUp();
          console.log("closing",res);

          this.updateStatusIconMissingPartList(res.id!, res.missingPartStatus);
          if(this.selectedMissingPart?.id === res.id){
            this.selectedMissingPart = res;
          }

          this.isSavingData =false;
        },
        error: (e) => {
          
        }
      }
    );
  }

  getMissingPartStatusDate(){
    switch (this.selectedMissingPart?.missingPartStatus) {
      case MissingPartStatus.Potential:
        return this.selectedMissingPart.creationDate;
      case MissingPartStatus.ToBeConfirmed:
        return this.getToBeValidatedDate();
      case MissingPartStatus.InProgress:
        return this.getValidationDate();
      case MissingPartStatus.Closed:
          return null;
      default:
        return null;
    }
  }

  getValidationDate(){
    if(this.selectedMissingPart == null || this.selectedMissingPart.impactedProducts?.length === 0){ return;}

    let mostRecentDate:Date;

    this.selectedMissingPart.impactedProducts?.forEach(p => {
      p.deviations.forEach(d => {
        if(mostRecentDate == null){
          mostRecentDate = d.missingPartValidationDate!;
        }
        else if(d.missingPartValidationDate != null && d.missingPartValidationDate > mostRecentDate){
          mostRecentDate = d.missingPartValidationDate;
        }
      });
    });

    return mostRecentDate!;
  }

  getToBeValidatedDate(){
    if(this.selectedMissingPart == null || this.selectedMissingPart.impactedProducts?.length === 0){ return;}

    let oldestDate:Date;

    this.selectedMissingPart.impactedProducts?.forEach(p => {
      p.deviations.forEach(d => {
        if(oldestDate == null){
          oldestDate = d.detectionDate!;
        }
        else if(d.detectionDate != null && d.detectionDate < oldestDate){
          oldestDate = d.detectionDate;
        }
      });
    });

    return oldestDate!;
  }

  getPopUpTitle(){
    if(this.showMissingPartHistory){
      return "HISTORY";}
    else if(this.hasMissingPartToAdd){
      return "ADD A PART" ;}
    else if(this.hasMissingPartToEdit && this.isFormDisabled){
      return "VIEW MISSING PART";}
    else if(this.hasMissingPartToEdit){
      return "EDIT MISSING PART";}
    else if(this.hasMissingPartToValidate){
      return "CONFIRM RELATED PRODUCT";}
    else if(this.hasMissingPartToClose){
      return "CLOSE MISSING PART";}
    else if(this.hasMissingPartToAssign && this.stakeholderToAssign == null){
      return "ASSIGN TO ME";}
    else if(this.hasMissingPartToAssign && this.stakeholderToAssign != null){
      return "ASSIGN";}
    else if(this.stakeholderToRemove != null){
      return "REMOVE STAKEHOLDER";}
    else if(this.hasNotificationToSend){
      return "NOTIFY STAKEHOLDERS";}
    else{
      return "";}
  }

  getPopUpButtonText(){
    if(this.hasMissingPartToAdd){
      return "SAVE MISSING PART" ;}
    else if(this.hasMissingPartToEdit){
      return "EDIT MISSING PART";}
    else if(this.hasMissingPartToValidate){
      return "CONFIRM RELATED PRODUCT";}
    else if(this.isCreatingMissingPart){
      return "SAVING";}
    else{
      return "";}
  }

  //LANGUAGE SETTING
  getDescriptionTranslation(){
    if(this.selectedMissingPart == null || this.selectedMissingPart.part == null){ return;}

    switch (this.translate.currentLang) {
      case Language.French:
        return this.selectedMissingPart.part?.descriptionFR;
      case Language.Dutch:
        return this.selectedMissingPart.part?.descriptionNL;
      default:
        return this.selectedMissingPart.part.descriptionEN;
    }
  }

  getMissingCauseFullPath(){
    if(this.selectedMissingPart == null || this.selectedMissingPart.missingCause == null){ return;}

    let missingCauseParent!:MissingCause;

    if(this.selectedMissingPart.missingCause.level3 !== ""){
      missingCauseParent = this.missingCauses.find(x=>x.code === this.selectedMissingPart?.missingCause?.level2)!;
    }

    return missingCauseParent != null ? this.getMissingCauseTranslation(missingCauseParent) + " > " + this.getMissingCauseTranslation(this.selectedMissingPart.missingCause).replace(/^ >+/, '')  : this.getMissingCauseTranslation(this.selectedMissingPart.missingCause)
  }

  //LANGUAGE SETTING
  getMissingCauseTranslation(mc:MissingCause){
    if(mc == null){ return "";}

    switch (this.translate.currentLang) {
      case Language.French:
        return mc.nameFR;
      case Language.Dutch:
        return mc.nameNL;
      default:
        return mc.nameEN;
    }
  }

  getDefaultResponsibleFullPath(){
    if(this.selectedMissingPart == null || this.selectedMissingPart.defaultResponsible == null){ return;}

    return (this.getTreeElementName(this.selectedMissingPart.defaultResponsibleLevel2!) + (this.selectedMissingPart.defaultResponsibleLevel3 !=  null ? " > " : "") +
             this.getTreeElementName(this.selectedMissingPart.defaultResponsibleLevel3!)).replace(/^ >+/, '').trim();
  }

  //LANGUAGE SETTING
  getResponsibleFullPath(dev:Deviation){
    if(dev == null){ return "";}
    return (this.getTreeElementName(dev.responsibleLevel2!) + (dev.responsibleLevel3 !=  null ? " > " : "") +
             this.getTreeElementName(dev.responsibleLevel3!)).replace(/^ >+/, '').trim();
  }

  getTreeElementName(f:Responsible){
    if(f == null){ return "";}

    if(this.translate.currentLang === Language.French){
      return f?.nameFR;}
    else if(this.translate.currentLang === Language.Dutch){
      return f?.nameNL;}
    else{
      return f?.nameEN;}
  }

  toggleProductList(){
    this.isProductListOpen = !this.isProductListOpen;
  }

  getMissingPartStatusTranslation(status:MissingPartStatus){
    switch (status) {
      case MissingPartStatus.Potential:
        return "POTENTIAL";
      case MissingPartStatus.ToBeConfirmed:
        return "TO BE CONFIRMED";
      case MissingPartStatus.InProgress:
        return "IN PROGRESS";
      case MissingPartStatus.Closed:
          return "CLOSED";
      default:
        return "";
    }
  }

  //CALENDAR LANGUAGE
  setCalendarLanguage(language:string) {
    if(language == null){ return;}

    this._locale = language;
    this._adapter.setLocale(this._locale);
  } 

  getDateFormatString(): string {
    if (this._locale === Language.English){
      return DateFormatDisplay.English;}
    else if (this._locale === Language.French){
      return DateFormatDisplay.French;}
    else if(this._locale === Language.Dutch){
      return DateFormatDisplay.Dutch;}

    return '';
  }

  //HISTORY
  showHistory(){
    this.showMissingPartHistory = !this.showMissingPartHistory;

    if(this.showMissingPartHistory){
      this.getMissingPartHistory(this.selectedMissingPart?.id);
    }
  }

  getMissingPartHistory(mpId:number | undefined){
    if(mpId == null || this.translate.currentLang == null){ return;}

    this.loadingMissingPartHistory = true;
    this.errorMessage = "";

    this.httpClient.get<MissingPartHistory[]>(this.apiUrl + 'MissingPartHistory/ByMissingPartId/' + mpId + '/' + this.translate.currentLang).subscribe(
      {
        next: (res) => {
          console.log("history", res);
          this.loadingMissingPartHistory = false;
          this.missingPartHistories = res;
        },
        error: (e) => {
          if(e.status === 404 || e.status === 403){
            this.missingPartHistories = [];
            this.loadingMissingPartHistory = false;
            this.errorMessage = "NO HISTORY AVAILABLE";
          }
        }
      }
    );
  }

  getMissingPartHistoryof(stepIndex:number): MissingPartHistory[]{
    if(this.missingPartHistories == null || this.missingPartHistories.length === 0){ return [];}
    const stepHistories: MissingPartHistory[] = [];
    this.missingPartHistories.forEach(historyEl => {
      if(historyEl.propertyName.includes("Step"+stepIndex)){
        stepHistories.push(historyEl);
      }
    });
    return stepHistories;
  }

  getHistoryValue(value:string, property:string){
    if(property === "MissingCauseId"){
      const cause:MissingCause = this.getMissingCauseFromList(Number(value));
      if(cause == null){
        return "ø";
      }

      return cause.code + ' ' + this.getMissingCauseTranslation(cause);
    }
    if(value == null || value === ""){
      return "ø";
    }
      
    return value;
  }

  isHistoryValueDate(property:string, value:string){
    if((value == null || value !== "") && 
      (property === "PlannedMissingDate" || property === "PlannedArrival")){ return true;}

    return false;
  }
  
  isCreation(actionType:number):boolean{
    return actionType === ActionType.Creation;
  }

  isUpdate(actionType:number):boolean{
    return actionType === ActionType.Update;
  }

  getHistoryPropertyName(property:string){
    const propertyMapping: { [key: string]: string } = {
      "Title": "TITLE",
      "ImpactedProducts": "IMPACTED PRODUCTS",
      "Comment": "COMMENT",
      "PartId": "PARTID",
      "ImpactedProductsNumber": "NUMBER OF IMPACTED VEHICLE",
      "PlannedMissingDate": "PLANNED MISSING DATE",
      "PlannedArrival": "PLANNED ARRIVAL",
      "MissingCauseId": "CAUSE",
      "Stakeholders": "STAKEHOLDERS",
      "Steps": "STEP",
      "DefaultResponsibleId": "DEFAULT RESPONSIBLE"
    };

    return propertyMapping[property] || property;
  }

  //PROBLEM SOLVING STATUS
  isPreviousStepClosed(index:number):boolean{
    if(index === 0){
      return true;
    }
    else if(this.selectedMissingPart != null && this.selectedMissingPart.steps != null && this.selectedMissingPart.steps[index - 1] != null){
      return this.selectedMissingPart?.steps[index - 1].closed!;
    }
    else{
      return true;
    }
  }

  isNextStepClosed(index:number):boolean{
    if(this.selectedMissingPart == null || this.selectedMissingPart.steps == null){
      return false;
    }
    else if(this.selectedMissingPart.steps[index - 1] != null){
      return this.selectedMissingPart.steps[index - 1].closed;
    }
    else{
      return false;
    }
  }

  callSaveStep(step:MissingPartStep, callback?:() => void) {
    this.httpClient.put<MissingPartStep>(this.apiUrl + 'MissingPart/Step', step).subscribe(
      {
        next: (res) => {
          this.isSavingStep = -1;
          if (callback) {
            callback();
          }
        },
        error: (e) => {
          console.error(e);
        }
      }
    );
  }

  saveStep(event:any, step:MissingPartStep){
    this.isSavingStep = step.stepIndex;
    step.comment = event.comment;

    if(step.stepIndex === 1 || step.stepIndex === 2){
      this.modifyMissingPartCauseFromStep(event.missingCauseId, event.supplier, (missingPartId:number) => {
        this.callSaveStep(step, () => {
          this.selectMissingPartById(missingPartId);
        })
      });
    } else {
      this.callSaveStep(step);
    }
  }

  callCloseStep(step:MissingPartStep, callback?:() => void) {
    this.httpClient.put<MissingPartStep>(this.apiUrl + 'MissingPart/Step/Close', step).subscribe(
      {
        next: (res) => {
          this.isSavingStep = -1;
          if(this.selectedMissingPart != null && this.selectedMissingPart.id === res.missingPartId && this.selectedMissingPart.steps != null){
            this.selectedMissingPart.steps[res.stepIndex - 1] = res;
          }
          if (callback) {
            callback();
          }
        },
        error: (e) => { 
          if(e.error.includes("only the owner")){
            this.displayMessagePopup({type:"error", title: errorMessagePopupTitle, text:"NOT STEP OWNER"});
            this.isSavingStep = -1;
          }
          else{
            this.isSavingStep = -1;
            console.error(e);
          }
        }
      }
    );
  }

  closeStep(event:any, step:MissingPartStep){
    this.isSavingStep = step.stepIndex;
    step.comment = event.comment;

    if(step.stepIndex === 1 || step.stepIndex === 2){
      this.modifyMissingPartCauseFromStep(event.missingCauseId, event.supplier, (missingPartId:number) => {
        this.callCloseStep(step, () => {
          this.selectMissingPartById(missingPartId);
        })
      });
    } else {
      this.callCloseStep(step);
    }
  }

  reOpenStep(event:any, step:MissingPartStep){
    if(this.selectedMissingPart == null || step == null){ return;}

    this.isSavingStep = step.stepIndex;

    this.httpClient.put<any>(this.apiUrl + 'MissingPart/Step/Reopen/' + this.selectedMissingPart.id + '/' + step.stepIndex, null).subscribe(
      {
        next: (res) => {
          this.isSavingStep = -1;
          if(this.selectedMissingPart != null && this.selectedMissingPart.id === res.missingPartId && this.selectedMissingPart.steps != null){
            this.selectedMissingPart.steps[res.stepIndex - 1] = res;
          }
        },
        error: (e) => {
          console.error(e);
          this.isSavingStep = -1;
        }
      }
    );
  }

  modifyMissingPartCauseFromStep(newMissingCauseId: number, supplier:string | null, callback?:(missingPartId:number) => void){
    if(this.selectedMissingPart == null){ return;}

    this.selectedMissingPart.missingCauseId = newMissingCauseId || null;
    this.selectedMissingPart.missingCause = null;

    if(supplier != null && supplier !== ""){
      this.selectedMissingPart.supplierParmaCode = supplier;
    }

    const elementToSend = Object.assign({}, this.selectedMissingPart);
    elementToSend.steps = null;
    elementToSend.supplier = null;

    this.httpClient.put<MissingPart>(this.apiUrl + 'MissingPart', elementToSend).subscribe(
      {
        next: (res) => {
          console.log('missing cause changed from step', res);
          if(callback){
            callback(res.id!);
          }
        },
        error: (e) => {
          console.error(e);
        }
      }
    );
  }

  addFileToStep(event:any, step:MissingPartStep){
    if(this.selectedMissingPart == null || this.selectedMissingPart.id == null || step == null){ return;}
    const files: File[] = event.target.files;
    const formData:FormData = new FormData();

    for(let i = 0; i < files.length; i++){
      formData.append('files', files[i]);
    }

    this.httpClient.post<any[]>(this.apiUrl + 'MissingPart/Step/File/' + this.selectedMissingPart.id + '/' + step.stepIndex, formData).subscribe(
      res => {
        console.log("files added", res);
        for(let j = 0; j < res.length; j++){
          step.files = [...step.files!, res[j]];
        }
      }
    );
  }

  isLineImpacted(lineId?:number){
    if(lineId == null){ return;}

    return this.missingPartForm.controls['impactedProductionLines'].value.find(x=> x === lineId);
  }

  changeImpactedLine(event:any, lineId:number){
    if(this.selectedMissingPart == null || this.selectedMissingPart.impactedProductionLines == null){ return;}

    const formArray:FormArray = this.missingPartForm.get('impactedProductionLines') as FormArray;

    if(event.target.checked){
      //add line
      formArray.push(new FormControl(lineId));
    }
    else {
      //remove line
      formArray.controls.forEach((ctrl: any, index) => {
        if(ctrl.value === lineId) {
          formArray.removeAt(index);
          return;
        }
      });
    }

  }

  //PARTID
  getMissingPartOptions(){
    if(this.currentFactoryId == null || isNaN(this.currentFactoryId)){ return;}
    const lang = localStorage.getItem('language') != null ? localStorage.getItem('language') : Language.English;

    this.httpClient.get<PartViewModel[]>(this.apiUrl + 'MissingPart/Parts/ByFactoryId/' + this.currentFactoryId + "/" + lang).subscribe(
      (res: PartViewModel[]) => {
        console.log("get bom", res);
        this.missingPartOptions = res;
      }
    );
  }

  selectPreviousOption(){
    const allOptions: PartViewModel[] = this.filteredMissingPartOptions();

    if(this.toBeSelectedPartOption == null){
      this.toBeSelectedPartOption = allOptions[allOptions.length - 1];
    }
    else{
      const index:number = allOptions.findIndex(x => x.partId === this.toBeSelectedPartOption?.partId);

      if(index - 1 >= 0){
        this.toBeSelectedPartOption = allOptions[index - 1];
      }
      else{
        this.toBeSelectedPartOption = allOptions[allOptions.length - 1];
      }
    }
  }

  selectNextOption(){
    const allOptions: PartViewModel[] = this.filteredMissingPartOptions();

    if(this.toBeSelectedPartOption == null){
      this.toBeSelectedPartOption = allOptions[0];
    }
    else{
      const index:number = allOptions.findIndex(x => x.partId === this.toBeSelectedPartOption?.partId);

      if(index + 1 < allOptions.length){
        this.toBeSelectedPartOption = allOptions[index + 1];
      }
      else{
        this.toBeSelectedPartOption = allOptions[0];
      }
    }      
  }

  chooseOption(event:Event){
    event.stopPropagation();

    if(this.toBeSelectedPartOption != null){
      this.selectPartIdOption(this.toBeSelectedPartOption);
      this.blurMissingPartField();
    }
  }

  selectPartIdOption(el:PartViewModel){
    this.missingPartForm.controls['partId'].setValue(el.partId);
    
    if(this.missingPartForm.value.title == null || this.missingPartForm.value.title === ""){
      this.missingPartForm.controls['title'].setValue(el.description);
      this.missingPartForm.controls['title'].updateValueAndValidity();
    }

    this.selectedMissingPartOption = el;
  }

  isSelectedMissingPartOptionFullyDisplayed():boolean{
    return this.selectedMissingPartOption != null && 
            this.selectedMissingPartOption.rtAlias != null && this.selectedMissingPartOption.rtAlias.trim() !== "" &&
            this.selectedMissingPartOption.description != null && this.selectedMissingPartOption.description.trim() !== "";
  }

  blurMissingPartField(){
    this.inputMpFocused = false;
    this.toBeSelectedPartOption = null;

    const BOMOptions = this.filteredMissingPartOptions();
    if(BOMOptions.length === 1){
      this.selectPartIdOption(BOMOptions[0]);
    }

    const mp:PartViewModel = this.missingPartOptions.find(x => x.partId === this.missingPartForm.value.partId)!;

    if(mp != null){
      this.selectedMissingPartOption = mp;
      this.getSuppliers(mp.partId, this.currentFactoryId);
    }
    else {
      this.selectedMissingPartOption = null;
      this.selectedMissingPartPossibleSuppliers = [];
    }    

    if(this.missingPartForm.value.partId !=null && this.missingPartForm.value.partId !== "" && (this.selectedMissingPart != null 
       && this.missingPartForm.value.partId !== this.selectedMissingPart.partId || this.hasMissingPartToAdd)){
      this.checkMissingPartDuplicate(this.missingPartForm.value.partId);
    }
  }

  filteredMissingPartOptions(): PartViewModel[]{
    if(this.missingPartForm.value.partId == null || this.missingPartForm.value.partId === ""){ return this.missingPartOptions;}
    return this.missingPartOptions.filter((x:any) => 
              this.notSensitive(x.partId).includes(this.notSensitive(this.missingPartForm.value.partId)) ||
              this.notSensitive(x.rtAlias).includes(this.notSensitive(this.missingPartForm.value.partId)) ||
              this.notSensitive(x.description).includes(this.notSensitive(this.missingPartForm.value.partId)) ||
              this.notSensitive(x.demarcation).includes(this.notSensitive(this.missingPartForm.value.partId)));
  }

  removeOptionsLimit(){
    this.optionsLimit = Infinity;
  }

  notSensitive(s:string | null | undefined){
    if(s==null){ return "";}
    return Diacritics.remove(s).toLowerCase();
  }

  //MESSAGE POPUP
  displayMessagePopup(event:any){
    this.messagePopupType = event.type;
    this.messagePopupTitle = event.title;
    this.messagePopupText = event.text;
  }

  closeMessagePopup(){
    setTimeout(() => {
      this.messagePopupTitle = '';
    }, 1000);
  }

  selectPlannedMissingDate(event:MatDatepickerInputEvent<Date>){
    this.missingPartForm.value.plannedMissingDate.hour(12);
  }

  selectPlannedArrival(event:MatDatepickerInputEvent<Date>){
    this.missingPartForm.value.plannedArrival.hour(12);
  }

  //IMPACTED PRODUCT MP POPUP
  checkDateDifference() {
    if(this.missingPartForm.value.plannedMissingDate == null || this.missingPartForm.value.plannedArrival == null) { return; }
    
    const plannedMissingDate = new Date(this.missingPartForm.value.plannedMissingDate);
    const plannedArrival = new Date(this.missingPartForm.value.plannedArrival);
  
    // Calculate the difference in time (days)
    const dayDifference = (plannedArrival.getTime() - plannedMissingDate.getTime()) / (1000 * 3600 * 24);
  
    // Check if the difference is less than 14 days
    return dayDifference < 14 && dayDifference >= 0;
  }

  refreshImpactedProductBlockedReasons():string[]{
    const canRefreshImpactedProductReasons:string[] = []; 
    
    //conditions on partId
    if((this.missingPartForm.controls['partId'].disabled && (this.selectedMissingPart?.partId == null || this.selectedMissingPart.partId === ""))
      || (!this.missingPartForm.controls['partId'].disabled && (this.missingPartForm.value.partId == null || this.missingPartForm.value.partId === ""))){
      canRefreshImpactedProductReasons.push("FILL PARTID");
    }

    if((this.missingPartForm.controls['partId'].disabled && (this.missingPartOptions.find(x=>x.partId === this.selectedMissingPart?.partId) == null))
      || (!this.missingPartForm.controls['partId'].disabled && (this.missingPartOptions.find(x=>x.partId === this.missingPartForm.value.partId) == null))){
        canRefreshImpactedProductReasons.push("MISSING PART NOT IN THE PARTS");  
    }

    //condition on date null
    if(this.missingPartForm.value.plannedMissingDate == null){
      canRefreshImpactedProductReasons.push("FILL PLANNED MISSING DATE");
    }

    if(this.missingPartForm.value.plannedArrival == null){
      canRefreshImpactedProductReasons.push("FILL PLANNED ARRIVAL DATE");
    }

    //condition on date 2 weeks
    if(!this.checkDateDifference()){
      canRefreshImpactedProductReasons.push("DATES 2 WEEKS APART");
    }

    return canRefreshImpactedProductReasons;
  }

  refreshImpactedProduct(){
    if(this.selectedMissingPart == null && !this.checkDateDifference() && !this.isRefreshingImpactedProduct) { return };

    this.isRefreshingImpactedProduct = true;

    const body = {
      "PartId":this.missingPartForm.controls['partId'].disabled ? this.selectedMissingPart?.partId : this.missingPartForm.value.partId,
      "PlannedMissingDate":this.missingPartForm.value.plannedMissingDate,
      "PlannedArrival":this.missingPartForm.value.plannedArrival,
      "ImpactedProductionLines":[],
      "FactoryId":this.currentFactoryId,
    }

    this.missingPartForm.value.impactedProductionLines?.forEach(lineId => {
      body.ImpactedProductionLines.push(lineId);
    });

    this.httpClient.get<number>(this.apiUrl + 'MissingPart/ImpactedProductAmount/' + btoa(JSON.stringify(body))).subscribe(
      {
        next: (res:number) => {
          this.missingPartForm.controls['impactedProductsNumber'].setValue(res);
          this.missingPartForm.controls['impactedProductsNumber'].updateValueAndValidity();
          this.isRefreshingImpactedProduct = false;
        },
        error: (e) => {
          console.error(e)
          this.isRefreshingImpactedProduct = false;
        }
      }
    );
  }

  //CLIPBOARD
  copyDirectLink(id:string | number, event?:any){
    event?.stopPropagation();
    const httpName = window.location.href.split("/", 5)[2].includes("localhost") ? 'http://' : 'https://';
    const link = httpName + window.location.href.split("/", 5)[2] + '/missing-parts/' + this.currentFactoryId + '/' + this.currentLineId + '/' + id;
    this.clipboard.copy(link);
  }
}
