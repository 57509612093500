export enum ActionType {
    Creation = 0,
    Update = 1
}

export class DeviationHistory {
    deviationId:number;
    date: Date;
    actionType:number;
    propertyName:string;
    oldValue:string;
    newValue:string;
    user:string;
    userEmployeeId:string;
    operatorName:string | null;
    operatorEmployeeId:string | null;

    constructor(deviationId:number,
                date:Date,
                actionType:number,
                propertyName:string,
                oldValue:string,
                newValue:string,
                user:string,
                userEmployeeId:string,
                operatorName:string | null,
                operatorEmployeeId:string | null) {
        
        this.deviationId = deviationId;
        this.date = date;
        this.actionType = actionType;
        this.propertyName = propertyName;
        this.oldValue = oldValue;
        this.newValue = newValue;
        this.user = user;
        this.userEmployeeId = userEmployeeId;
        this.operatorName = operatorName;
        this.operatorEmployeeId = operatorEmployeeId;
    }
}