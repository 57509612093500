<div *ngIf="isDisplayed" class="production-tracking-container">
<span *ngIf="zones == null || zones.length == 0">{{"VOID LINE" | translate }}</span>
<div class="zone-container" *ngFor="let zone of zones; let indexZone = index; first as isFirst">
    <!-- WORKCELLS -->
    <div class="step-container" *ngFor="let workcell of zone.workcells; let indexWorkcell = index;" [ngClass]="[isDone(indexZone, indexWorkcell) ? 'todo' : 'done', isCurrent(workcell) ? 'current' : '', allLineChecked() ? 'end-of-line' : '']" >
        
        <div *ngIf="(indexZone > firstCheckpointZoneIndex || indexWorkcell > firstCheckpointWorkcellIndex) && workcell.isCheckpoint && (workcell.check != null || !workcell.disabled)" class="between-step" [ngClass]="isDone(indexZone, indexWorkcell) ? 'done' : 'todo'"></div>        
        
        <div #current *ngIf="isCurrent(workcell)"></div>
        <div class="tooltip-container workcell-container" *ngIf="workcell.isCheckpoint && (workcell.check != null || !workcell.disabled)">
            <div class="step" *ngIf="!isCurrent(workcell) || workcell.check != null" [ngClass]="[isDone(indexZone, indexWorkcell) ? 'done' : 'todo', getWorkcellStateClass(workcell), workcell.isCheckpoint ? 'checkpoint' : 'workcell']">

                <svg class="icon-cok" width="24" height="24" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="19" cy="19" r="19" fill="#00BA5E" fill-opacity="0.24"/>
                    <path d="M18.9914 7.47143C12.6314 7.47143 7.47144 12.6314 7.47144 18.9914C7.47144 25.3514 12.6314 30.5114 18.9914 30.5114C25.3514 30.5114 30.5114 25.3514 30.5114 18.9914C30.5114 12.6314 25.3514 7.47143 18.9914 7.47143ZM24.9194 16.2074L18.1514 22.9994C17.9355 23.2154 17.6234 23.3594 17.3113 23.3594C16.9993 23.3594 16.6874 23.2393 16.4713 22.9994L13.0634 19.5914C12.5835 19.1115 12.5835 18.3675 13.0634 17.8873C13.5434 17.4074 14.2874 17.4074 14.7675 17.8873L17.3115 20.4313L23.2395 14.5033C23.7194 14.0233 24.4634 14.0233 24.9436 14.5033C25.3994 14.9834 25.3994 15.7274 24.9195 16.2074L24.9194 16.2074Z" fill="#00BA5E"/>

                </svg>
                <svg class="icon-cnok" width="24" height="24" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="19" cy="19" r="19" fill="#FCDDDA"/>
                    <path d="M18.9918 7.08571C12.4154 7.08571 7.08569 12.4154 7.08569 18.9919C7.08569 25.5683 12.4154 30.898 18.9918 30.898C25.5683 30.898 30.898 25.5683 30.898 18.9919C30.898 12.4154 25.5683 7.08571 18.9918 7.08571V7.08571ZM24.598 22.9107C25.062 23.3748 25.062 24.1295 24.598 24.5935C24.1245 25.067 23.5574 25.2357 22.9152 24.5935L18.9917 20.6748L15.0729 24.5935C14.5292 25.1373 13.7417 24.9451 13.3901 24.5935C12.9261 24.1295 12.9261 23.3748 13.3901 22.9107L17.3089 18.9919L13.3901 15.0732C12.9261 14.6091 12.9261 13.8544 13.3901 13.3903C13.8542 12.9263 14.6089 12.9263 15.0729 13.3903L18.9917 17.3091L22.9105 13.3903C23.3745 12.9263 24.1293 12.9263 24.5933 13.3903C25.0574 13.8544 25.0574 14.6091 24.5933 15.0732L20.6745 18.9919L24.598 22.9107Z" fill="#EA2C1A"/>
                </svg>
                
            </div>
            <app-demo-button (clickEvent)="check(workcell)" *ngIf="isCurrent(workcell) && workcell.check == null" text="Check" type="btn-small" color="tertiary" [disable]=!checkRight tabindex="0"></app-demo-button>
            
            <span class="step-name">{{workcell.name}}<br><p *ngIf="workcell.isCheckpoint">Checkpoint</p></span>

            <div class="locator-icon" *ngIf="isCurrent(workcell) && workcell.check == null">
                <svg width="20" height="20" viewBox="0 0 81 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M40.5799 59.9299C50.0571 59.9299 57.7399 52.2471 57.7399 42.7699C57.7399 33.2927 50.0571 25.6099 40.5799 25.6099C31.1027 25.6099 23.4199 33.2927 23.4199 42.7699C23.4199 52.2471 31.1027 59.9299 40.5799 59.9299Z" stroke="black" stroke-width="3.49" stroke-miterlimit="10"/>
                    <path d="M74.13 60.7L48.53 104.82C47.6873 106.17 46.5183 107.286 45.1306 108.066C43.743 108.845 42.1815 109.263 40.59 109.28C39.0737 109.259 37.589 108.843 36.2825 108.074C34.976 107.304 33.8929 106.206 33.14 104.89L7.14 59.99C3.79296 54.0813 2.02286 47.4108 2 40.62C2 30.3773 6.06888 20.5542 13.3115 13.3115C20.5542 6.06888 30.3773 2 40.62 2C50.8627 2 60.6858 6.06888 67.9285 13.3115C75.1711 20.5542 79.24 30.3773 79.24 40.62C79.3709 47.6508 77.6058 54.5871 74.13 60.7V60.7Z" stroke="black" stroke-width="3.49" stroke-miterlimit="10"/>
                </svg>
            </div>
            

            <div class="tooltip-content" *ngIf="workcell.isCheckpoint && workcell.check != null">
                <span class="tooltip-text"><strong>{{workcell.check.userEmployeeId}} {{workcell.check.userName}}</strong></span>
                <span class="tooltip-text" *ngIf="workcell.check.operatorName != null && workcell.check.operatorName != ''">({{"OPERATOR ID" | translate}} {{workcell.check.operatorName}}<span *ngIf="workcell.check.operatorEmployeeId != null && workcell.check.operatorEmployeeId != ''"> {{workcell.check.operatorEmployeeId}}</span>)</span>
                <span class="tooltip-text">{{workcell.check.checkDate | demoDate}}</span>

                <!--Related Deviations-->
                <span class="tooltip-text deviations-title">{{(workcell.check.checkDeviations != null && workcell.check.checkDeviations.length > 0 ? "DEVIATIONS AT THIS CHECKPOINT:" : "NO DEVIATION") | translate}}</span>
                <div *ngFor="let dev of workcell.check.checkDeviations" class="tooltip-deviation-container">
                    <svg *ngIf="dev.state" class="icon-cok" width="20" height="20" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="19" cy="19" r="19" fill="#00BA5E" fill-opacity="0.24"/>
                        <path d="M18.9914 7.47143C12.6314 7.47143 7.47144 12.6314 7.47144 18.9914C7.47144 25.3514 12.6314 30.5114 18.9914 30.5114C25.3514 30.5114 30.5114 25.3514 30.5114 18.9914C30.5114 12.6314 25.3514 7.47143 18.9914 7.47143ZM24.9194 16.2074L18.1514 22.9994C17.9355 23.2154 17.6234 23.3594 17.3113 23.3594C16.9993 23.3594 16.6874 23.2393 16.4713 22.9994L13.0634 19.5914C12.5835 19.1115 12.5835 18.3675 13.0634 17.8873C13.5434 17.4074 14.2874 17.4074 14.7675 17.8873L17.3115 20.4313L23.2395 14.5033C23.7194 14.0233 24.4634 14.0233 24.9436 14.5033C25.3994 14.9834 25.3994 15.7274 24.9195 16.2074L24.9194 16.2074Z" fill="#00BA5E"/>
    
                    </svg>
                    <svg *ngIf="!dev.state" class="icon-cnok" width="20" height="20" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="19" cy="19" r="19" fill="#FCDDDA"/>
                        <path d="M18.9918 7.08571C12.4154 7.08571 7.08569 12.4154 7.08569 18.9919C7.08569 25.5683 12.4154 30.898 18.9918 30.898C25.5683 30.898 30.898 25.5683 30.898 18.9919C30.898 12.4154 25.5683 7.08571 18.9918 7.08571V7.08571ZM24.598 22.9107C25.062 23.3748 25.062 24.1295 24.598 24.5935C24.1245 25.067 23.5574 25.2357 22.9152 24.5935L18.9917 20.6748L15.0729 24.5935C14.5292 25.1373 13.7417 24.9451 13.3901 24.5935C12.9261 24.1295 12.9261 23.3748 13.3901 22.9107L17.3089 18.9919L13.3901 15.0732C12.9261 14.6091 12.9261 13.8544 13.3901 13.3903C13.8542 12.9263 14.6089 12.9263 15.0729 13.3903L18.9917 17.3091L22.9105 13.3903C23.3745 12.9263 24.1293 12.9263 24.5933 13.3903C25.0574 13.8544 25.0574 14.6091 24.5933 15.0732L20.6745 18.9919L24.598 22.9107Z" fill="#EA2C1A"/>
                    </svg>
                    <span class="tooltip-text">{{dev.deviationDisplayId}} {{getFaultTypeRank2Name(dev.deviationFaultType)}}</span>
                </div>
                
            </div>

        </div>
    
    </div>
    
</div>
</div>