export class DeviationLocation {

    deviationId:number;
    fileName:string;
    x:number;
    y:number;
    zone:string;
    id?:number;

    constructor(
        deviationId:number,
        fileName:string,
        x:number,
        y:number,
        zone:string,
        id?:number) {

        this.deviationId = deviationId;
        this.fileName = fileName;
        this.x = x;
        this.y = y;
        this.zone = zone;
        if(id!=null){ this.id = id;}
    }
}