<div class="title-container">
    <svg *ngIf="closed" class="icon-ok" width="32" height="32" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19" cy="19" r="19" fill="#00BA5E" fill-opacity="0.24"/><path d="M18.9914 7.47143C12.6314 7.47143 7.47144 12.6314 7.47144 18.9914C7.47144 25.3514 12.6314 30.5114 18.9914 30.5114C25.3514 30.5114 30.5114 25.3514 30.5114 18.9914C30.5114 12.6314 25.3514 7.47143 18.9914 7.47143ZM24.9194 16.2074L18.1514 22.9994C17.9355 23.2154 17.6234 23.3594 17.3113 23.3594C16.9993 23.3594 16.6874 23.2393 16.4713 22.9994L13.0634 19.5914C12.5835 19.1115 12.5835 18.3675 13.0634 17.8873C13.5434 17.4074 14.2874 17.4074 14.7675 17.8873L17.3115 20.4313L23.2395 14.5033C23.7194 14.0233 24.4634 14.0233 24.9436 14.5033C25.3994 14.9834 25.3994 15.7274 24.9195 16.2074L24.9194 16.2074Z" fill="#00BA5E"/></svg>
    <svg *ngIf="!closed && this.previousStepClosed" class="icon-progress" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 158 158"><circle cx="79" cy="79" r="79" fill-opacity="0.24"/><circle cx="78.641" cy="78.641" r="48.047"/><path class="cls-3" d="M58.246,75.906a4.72,4.72,0,1,1-4.72,4.72A4.72,4.72,0,0,1,58.246,75.906Z"/><path class="cls-3" d="M80.246,40.906a4.72,4.72,0,1,1-4.72,4.72A4.72,4.72,0,0,1,80.246,40.906Z"/><path class="cls-3" d="M80.246,75.906a4.72,4.72,0,1,1-4.72,4.72A4.72,4.72,0,0,1,80.246,75.906Z"/><rect class="cls-3" x="59" y="76" width="21" height="9"/><path class="cls-3" d="M76,80V46h9V80H76Z"/></svg>                       
    <div *ngIf="!closed && !this.previousStepClosed" class="icon-todo"></div>
    <span class="title">{{title}}</span>
</div>

<div class="step-content">
    <div class="step-form">
        <form [formGroup]="stepForm">
            <div class="field-container comment">
                <label for="comment">{{ 'ADD COMMENTS' | translate }} :<app-notification-message></app-notification-message>
                    <div class="tooltip-container" *ngIf="getCommentHistories().length > 0">
                        <mat-icon class="mat-icon-rtl-mirror">{{'history'}}</mat-icon>
                        <div class="tooltip-content">
                            <div *ngFor="let history of getCommentHistories()">
                                <div class="history-title"> 
                                    <span><strong>{{history.user}}</strong></span>
                                    <span>{{history.date | demoDate}}</span>
                                </div>
                                <span>{{history.newValue}}</span>
                            </div>
                        </div>
                    </div>
                </label>
                
                <textarea type="long" id="comment" class="form-control" formControlName="comment" rows="4"></textarea>
            </div>

            <div class="field-container file-container">
                <label>{{ 'ATTACHED DOCUMENTS' | translate }} :</label>
                <div class="attached-document" *ngFor="let file of files">
                    <span (click)="downloadFile(file)">{{file.displayName}}</span>
                    <svg width="16" height="16" viewBox="0 0 93 103" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="openRemoveConfirm(file)"><path d="M46.5 30.1055V86.316" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M62.8945 30.1055V86.316" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M30.1055 30.1055V86.316" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M76.993 100.369H16.0983C14.8559 100.369 13.6645 99.875 12.786 98.9965C11.9076 98.1181 11.4141 96.9266 11.4141 95.6843V16.0527H81.6772V95.6843C81.6772 96.9266 81.1837 98.1181 80.3052 98.9965C79.4268 99.875 78.2353 100.369 76.993 100.369Z" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M2 16.0527H91" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M67.5788 16.0526H25.4209L30.1051 2H62.8946L67.5788 16.0526Z" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </div>
            </div>

            <div class="field-container" *ngIf="missingCauses != null">
                <label for="cause">{{ 'CAUSE' | translate }}</label>
                <app-tree-data id="cause"
                    [missingCauseList]="missingCauses"
                    [currentSelectedMissingCause]="getMissingCauseFromList(selectedMissingCauseId!)"
                    (selectEvent)="setSelectedMissingCause($event)"
                    [isDisabled]="closed">
                </app-tree-data>
            </div>

            <div class="field-container" *ngIf="suppliers != null">
                <label>{{ 'SUPPLIER' | translate }}</label>
                <mat-select tabindex="0" id="supplier" formControlName="supplier">
                    <mat-option *ngFor="let supplier of suppliers" [value]="supplier.parmaCode">{{supplier.parmaCode}} - {{supplier.companyName}} ({{supplier.countryCode}})</mat-option>
                </mat-select>
            </div>

            <div class="field-container parts-delivered-container" *ngIf="partsDelivered != null && currentMissingPart != null">
                <label><strong>{{"MISSING PART" | translate}}: {{currentMissingPart.partId}} {{partLabel}}</strong></label>
                <label>{{sumMissingPartDelivered()}} {{"MISSING PART DELIVERED" | translate}}</label>
                <label>{{currentMissingPart.totalMissingPartsToBeDeliveredAmount}} {{"MISSING PART TO BE DELIVERED" | translate}}</label>
                <div class="table-container">
                    <div class="parts-delivered-table-header" id="partsDelivered">
                        <span>{{'AMOUNT DELIVERED' | translate}}</span>
                        <span>{{'DELIVERY ADDRESS' | translate}}</span>
                        <div></div>
                        <mat-icon class="mat-icon-rtl-mirror" class="small" (click)="openPartDeliveredPopup()" *ngIf="isOwner()">{{'add_circle'}}</mat-icon>
                    </div>
                    <div class="parts-delivered-table" *ngFor="let partDelivered of partsDelivered; let i = index">
                        <span>{{partDelivered.deliveredAmount}}</span>
                        <span>{{partDelivered.deliveryAddress}}</span>
                        <mat-icon class="mat-icon-rtl-mirror" class="small" (click)="openPartDeliveredPopup(partDelivered)" *ngIf="isOwner()">{{'edit'}}</mat-icon>
                        <mat-icon class="mat-icon-rtl-mirror" class="small" (click)="openDeletePartDeliveredPopup(partDelivered)" *ngIf="isOwner()">{{'delete'}}</mat-icon>
                    </div>
                </div>
            </div>

            <app-step-table 
                [isOwner]="isOwner()"
                [currentMissingPart]="currentMissingPart"
                [partsOrdered]="partsOrdered"
                (openPartOrderedPopupEvent)='openPartOrderedPopup($event)'
                (openDeletePartOrderedPopupEvent)='openDeletePartOrderedPopup($event)'>
            </app-step-table>
        </form>
    </div>
    <app-upload-file (addFileEvent)="addFiles($event)" *ngIf="!disabled"></app-upload-file>
</div>

<div class="button-container" *ngIf="!disabled">
    <app-demo-button *ngIf="!closed"
        text="{{ 'SAVE' | translate}}" 
        color="reverse-tertiary"
        type="btn-small"
        (clickEvent)="saveStep()">
    </app-demo-button>

    <div class="tooltip-container relative">
        <app-demo-button 
            text="{{ (closed ? 'CLOSED' : 'SAVE AND CLOSE') | translate}}" 
            color="{{closed ? 'grey' : 'tertiary'}}"
            svg="{{closed ? '' : 'save'}}"
            type="btn-small"
            (clickEvent)="openCloseConfirm()"
            [disable]=!canCloseStep()>
        </app-demo-button>
        <div class="tooltip-content display-top" *ngIf="!canCloseStep() && !closed">
            <span *ngIf="!isOwner()">{{"NOT STEP OWNER" | translate}}</span>
            <span *ngIf="!this.previousStepClosed">{{"PREVIOUS STEP NOT CLOSED" | translate}}</span>
            <span *ngIf="!(this.missingCauses == null || (this.selectedMissingCauseId != null && this.hasCauseFilledOut())) && !this.missingCauseLastChildRequired">{{"A CAUSE MUST BE FILLED OUT" | translate}}</span>
            <span *ngIf="!(this.missingCauses == null || (this.selectedMissingCauseId != null && this.hasCauseFilledOut())) && this.missingCauseLastChildRequired">{{"A CAUSE MUST BE FILLED OUT LAST CHILD" | translate}}</span>
            <span *ngIf="!(this.suppliers == null || this.suppliers.length == 0 || (this.stepForm.value.supplier != null && this.stepForm.value.supplier != ''))">{{"A SUPPLIER MUST BE FILLED OUT" | translate}}</span>
        </div>
    </div>

    <app-demo-button *ngIf="closed && !nextStepClosed"
        text="{{ 'RE-OPEN' | translate}}" 
        color="reverse-tertiary"
        type="btn-small"
        [disable]=!isMissingPartOwner()
        [tooltipText]="!isMissingPartOwner() ? 'NOT STEP OWNER RE-OPEN' : '' | translate"
        (clickEvent)="reOpenStep()">
    </app-demo-button>

    <div class="owner">
        <span>{{employeeId}} {{name}}</span>
        <mat-icon class="mat-icon-rtl-mirror">{{'workspace_premium'}}</mat-icon>
    </div>
</div>

<div class="inner-popup confirm-popup" *ngIf="isCloseConfirmOpen">
    <div class="popup">
        <svg (click)="closePopup()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062"><path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/><path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/></svg>
        <span class="title">{{"CLOSE THIS STEP" | translate}}</span>
        <span>{{"CLOSE STEP MESSAGE" | translate}}</span>
        <app-demo-button text="{{'SAVE AND CLOSE' | translate}}" color="tertiary" type="btn-small" (clickEvent)="closeStep()">
        </app-demo-button>
    </div>
</div>

<div class="inner-popup confirm-popup" *ngIf="isRemoveConfirmOpen">
    <div class="popup">
        <svg (click)="closePopup()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062"><path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/><path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/></svg>
        <span class="title">{{"REMOVE THIS FILE" | translate}}</span>
        <span>{{"REMOVE FILE MESSAGE" | translate}}</span>
        <app-demo-button text="{{'REMOVE' | translate}}" color="tertiary" type="btn-small" (clickEvent)="removeFile()">
        </app-demo-button>
    </div> 
</div>

<div class="inner-popup parts-delivered-popup" *ngIf="isPartDeliveredOpen || isDeletePartDeliveredOpen">
    <div class="popup">
        <svg (click)="closePopup()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062"><path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/><path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/></svg>
        <span class="title">{{ partsPopupTitle("DELIVERED") | translate}}</span>
        <form [formGroup]="partDeliveredForm" (ngSubmit)="submitPartDeliveredForm()" *ngIf="isPartDeliveredOpen">
            <div class="field-container">
                <label for="amountDelivered">{{ 'AMOUNT DELIVERED' | translate }}*</label>
                <input type="number" id="amountDelivered" class="form-control" formControlName="amountDelivered" autocomplete="off" min="0" required onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" (blur)="errorMessage = ''">
                <div class="error-message-container" *ngIf="errorMessage != ''">
                    <mat-icon class="mat-icon-rtl-mirror warning">{{'warning'}}</mat-icon>
                    <span class="error-message">{{ errorMessage | translate}}</span>
                </div>
            </div>
            <div class="field-container">
                <label for="deliveryAddress">{{ 'DELIVERY ADDRESS' | translate }}* <app-notification-message></app-notification-message></label>
                <input type="text" id="deliveryAddress" class="form-control" formControlName="deliveryAddress" autocomplete="off" required>
            </div>
            <button type="submit" [disabled]="!partDeliveredForm.valid || isSavingData" class="tertiary centered small">{{ (partDeliveredToEdit == null ? "ADD PARTS DELIVERED" : "EDIT PARTS DELIVERED") | translate }}</button>   
        </form>
        <app-demo-button text="{{'REMOVE' | translate}}" color="tertiary" type="btn-small" (clickEvent)="deletePartDelivered()" *ngIf="isDeletePartDeliveredOpen"></app-demo-button>
    </div> 
</div>

<div class="inner-popup parts-ordered-popup" *ngIf="isPartOrderedOpen || isDeletePartOrderedOpen">
    <div class="popup">
        <svg (click)="closePopup()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062"><path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/><path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/></svg>
        <span class="title">{{ partsPopupTitle("ORDERED") | translate}}</span>
        <form [formGroup]="partOrderedForm" (ngSubmit)="submitPartOrderedForm()" *ngIf="isPartOrderedOpen">
            <div class="field-container">
                <label for="amountOrdered">{{ 'AMOUNT ORDERED' | translate }}*</label>
                <input type="number" id="amountOrdered" class="form-control" formControlName="amountOrdered" autocomplete="off" min="0.001" required onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" (blur)="errorMessage = ''">
                <div class="error-message-container" *ngIf="errorMessage != ''">
                    <mat-icon class="mat-icon-rtl-mirror warning">{{'warning'}}</mat-icon>
                    <span class="error-message">{{ errorMessage | translate}}</span>
                </div>
            </div>
            <div class="field-container time-picker">
                <label for="promisedDeliveryDate">{{ 'PROMISED DELIVERY DATE' | translate }}*</label>
                <input matInput [matDatepicker]="pickerPromisedDeliveryDate" placeholder="{{getDateFormatString()}}" formControlName="promisedDeliveryDate">
                <mat-datepicker-toggle matIconSuffix [for]="pickerPromisedDeliveryDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerPromisedDeliveryDate></mat-datepicker>

                <mat-select id="promisedDeliveryHour" formControlName="promisedDeliveryHour" placeholder="HH">
                    <mat-option *ngFor="let hour of getHoursList()" class="small-width" [value]="hour">{{ hour }}</mat-option>
                </mat-select>
                <span>:</span>
                <mat-select id="promisedDeliveryMinute" formControlName="promisedDeliveryMinute" placeholder="MM">
                    <mat-option *ngFor="let minute of getMinutesList()" class="small-width" [value]="minute">{{ minute }}</mat-option>
                </mat-select>
                <mat-select id="promisedDeliveryPeriod" formControlName="promisedDeliveryPeriod" class="small-width" placeholder="AM/PM" *ngIf="is12HourFormat()">
                    <mat-option value="AM">AM</mat-option>
                    <mat-option value="PM">PM</mat-option>
                </mat-select>
                <label class="subtext">{{ 'TIME BASED ON FACTORY TIMEZONE' | translate }}</label>
            </div>
            <div class="field-container">
                <label for="orderedBy">{{ 'ORDERED BY' | translate }} <app-notification-message></app-notification-message></label>
                <input type="text" id="orderedBy" class="form-control" formControlName="orderedBy" autocomplete="off">
            </div>
            <button type="submit" [disabled]="!partOrderedForm.valid || isSavingData" class="tertiary centered small">{{ (partOrderedToEdit == null ? "ADD PARTS ORDERED" : "EDIT PARTS ORDERED") | translate }}</button>   
        </form>
        <app-demo-button text="{{'REMOVE' | translate}}" color="tertiary" type="btn-small" (clickEvent)="deletePartOrdered()" *ngIf="isDeletePartOrderedOpen"></app-demo-button>
    </div> 
</div>

<div class="inner-popup confirm-popup" *ngIf="isLoadingFile || isSavingData">
    <mat-spinner></mat-spinner>
</div>

<div class="step-separator"></div>