export class Responsible {

    //LANGUAGE SETTING
    id:number | null;
    parentId:number | null;
    code:string;
    level1:string;
    level2:string | null;
    level3:string | null;
    nameEN:string | null;
    nameFR:string | null;
    nameNL:string | null;
    disabled:boolean;


    constructor(
            parentId:number | null,
            code:string,
            level1:string,
            level2:string | null,
            level3:string | null,
            nameEN:string | null,
            nameFR:string | null,
            nameNL:string | null, 
            disabled:boolean, 
            id:number) {

        this.parentId = parentId;
        this.code = code;
        this.level1 = level1;
        this.level2 = level2;
        this.level3 = level3;
        this.nameEN = nameEN;
        this.nameFR = nameFR;
        this.nameNL = nameNL;
        this.disabled = disabled;
        this.id = id;
    }
}