import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Agent } from 'src/app/models/agent.model';
import { LocalstorageDataService } from 'src/app/services/localstorage-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-operator-scanning',
  templateUrl: './operator-scanning.component.html',
  styleUrls: ['./operator-scanning.component.less']
})
export class OperatorScanningComponent {
  @Input() currentFactoryId = -1;
  @Input() allowUnknownOperator = false;
  @Input() headerVersion = false;
  @Output() userScannedEvent = new EventEmitter<any>();
  @Output() isScanningEvent = new EventEmitter<any>();

  apiUrl:string =  environment.API_URL;
  isScanning = false;
  hasScanningError = false;
  scanningErrorMessage = "INVALID BARCODE";
  scanText = "";
  scannedOperatorId = "";
  scannedAgent?:Agent | null;
  rememberMe = false;

  @ViewChild('scanInput') scanInput!: ElementRef;
  @HostListener('window:keydown.escape') escapeEvent() { this.escapeScan(); } 

  constructor(
    private httpClient:HttpClient,
    private localStorageService: LocalstorageDataService
  ) { }

  ngOnInit(){
    this.setOperator();
    this.userScannedEvent.emit(this.scannedOperatorId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentFactoryId']) {
      this.setOperator();
    }    
  }

  setOperator(){
    this.localStorageService.scannedOperatorIdValue.subscribe((nextValue:any) => {
      this.scannedOperatorId = nextValue;
      this.getScannedOperatorName(this.currentFactoryId, this.scannedOperatorId, (agent:Agent) => {
        this.scannedAgent = agent;
      });
    });
  }

  ngOnDestroy(){
    if(this.rememberMe){
      this.localStorageService.scannedOperatorId = this.scannedOperatorId;
    }
  }

  startScan(){
    this.isScanning = true;
    this.isScanningEvent.emit(this.isScanning);
    setTimeout(()=>{
      this.scanInput.nativeElement.focus();
    },100);
  }

  escapeScan(event?:any){
    event?.stopPropagation();
    this.isScanning = false;
    this.scanText = "";
    this.isScanningEvent.emit(this.isScanning);
  }

  stopScan(){
    this.scanText = this.scanText.replace(/[^a-zA-Z0-9]/g, '');
    if(this.scanText.startsWith("1H")){
      this.getScannedOperatorName(this.currentFactoryId, this.scanText.substring(2), (agent:Agent) => {
        if(!this.allowUnknownOperator && agent?.agentName === "UNKNOWN"){
          this.setScanningError("UNKNOWN OPERATOR NOT ALLOWED");
        }else{
          this.scannedOperatorId = this.scanText.substring(2);
          this.scannedAgent = agent;
          this.isScanning = false;
          if(this.headerVersion){
            this.localStorageService.scannedOperatorId = this.scannedOperatorId;
          }
          this.userScannedEvent.emit(this.scannedOperatorId);
          this.isScanningEvent.emit(this.isScanning);
          this.scanText = "";
        }
      });
    } else {
      this.setScanningError("INVALID BARCODE");
    }
  }

  setScanningError(message:string){
    this.hasScanningError = true;
    this.scanningErrorMessage = message;
    setTimeout(()=>{
      this.hasScanningError = false;
    },1500);
    this.scanText = "";
  }

  stopPropagation(event:any){
    event.stopPropagation();
  }

  getScannedOperatorName(factoryId:number | null | undefined, agentNumber:string, callback?:(agent:Agent) => void){
    if(factoryId == null || agentNumber == null || agentNumber === ""){ return;}

    this.httpClient.get<Agent>(this.apiUrl + "Agent/ByFactoryIdAndAgentNumber/" + factoryId + "/" + agentNumber).subscribe(
      {
        next: (res:Agent) => {
          if(callback){
            callback(res);}
        },
        error: (e) => {
          if((e.status === 404 || e.status === 403) && callback){
            callback(new Agent("UNKNOWN", "UNKNOWN", "UNKNOWN", "UNKNOWN", -2));
          }
        }
      }
    );
  }

  removeScannedUser(event:any){
    event.stopPropagation();
    this.localStorageService.scannedOperatorId = "";
    localStorage.removeItem("scannedOperatorId");
    this.scannedOperatorId = "";
    this.scannedAgent = null;
    this.scanText = "";
  }
}
