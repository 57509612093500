import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.less']
})

export class UploadFileComponent {

  @Output() addFileEvent = new EventEmitter<any>();

  isUserAboutToDropFile = false;
  acceptedTypes:string[] = ["image", "pdf", "doc", "docx", "xslx", "xsl"];
  maxSize:number = 10000000;

  @ViewChild('fileInput') fileInput:any;

  addFile(event:any){
    if(this.isAllFilesAcceptedType(event.target.files) && this.isAllFilesAcceptedSize(event.target.files)){
      this.isUserAboutToDropFile = false;
      this.addFileEvent.emit(event);
    }
    this.fileInput.nativeElement.value = '';
  }

  dropEventContainsFiles(event:any) {
    if (event.dataTransfer.types) {
      for (let i = 0; i < event.dataTransfer.types.length; i++) {
        if (event.dataTransfer.types[i] === "Files") {
          return true;
        }
      }
    }
    return false;
  }

  isAllFilesAcceptedType(files:FileList){
    let isAcceptedFiles:boolean = false;

    Array.from(files).forEach(file => {
      if(this.isAcceptedType(file)){
        isAcceptedFiles = true;
      }
    });

    return isAcceptedFiles;
  }

  isAcceptedType(file:any){
    let isAcceptedFile:boolean = false;

    this.acceptedTypes.forEach(type => {
      if(file.type.includes(type)){
        isAcceptedFile = true;
      }
    });

    return isAcceptedFile;
  }

  isAllFilesAcceptedSize(files:FileList){
    let isAcceptedSize:boolean = true;
    Array.from(files).forEach(file => {
      console.log("file size", file.size)
      if(file.size > this.maxSize){
        isAcceptedSize = false;
      }
    });
    return isAcceptedSize;
  }
}
