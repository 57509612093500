import { Component, EventEmitter, HostListener, Output, ViewChild } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BarcodeFormat, BrowserMultiFormatReader, Result } from '@zxing/library';
import { ResultAndError } from '@zxing/ngx-scanner/lib/ResultAndError';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrl: './barcode-scanner.component.less'
})
export class BarcodeScannerComponent {
  @HostListener('window:keydown.escape') escapeEvent() { this.close(); }
  
  @Output() closeEvent = new EventEmitter<any>();

  title = 'barcode-scanner';
  @ViewChild('scanner') scanner!:ZXingScannerComponent;
  qrResult!:ResultAndError;

  hasDevices = false;
  availableDevices:MediaDeviceInfo[] = [];
  selectedDevice!:MediaDeviceInfo | undefined;
  isLoadingCamera = true;

  selectedOption="";

  private camerasFoundSubscription!: Subscription;
  private camerasNotFoundSubscription!: Subscription;

  allowedFormats = [
    BarcodeFormat.QR_CODE,
    BarcodeFormat.CODE_128,
    BarcodeFormat.CODE_39,
    BarcodeFormat.EAN_13,
    BarcodeFormat.EAN_8,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.UPC_A,
    BarcodeFormat.UPC_E,
    BarcodeFormat.ITF,
    BarcodeFormat.PDF_417,
    BarcodeFormat.AZTEC
  ];

  ngAfterViewInit():void{
    this.isLoadingCamera = true;

    // Subscribe to the camerasFound event
    this.camerasFoundSubscription = this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
      this.availableDevices = devices;
    });

    // Handle cameras not found
    this.camerasNotFoundSubscription = this.scanner.camerasNotFound.subscribe(() => {
      this.isLoadingCamera = false;
    });    
  }

  ngOnDestroy(): void {
    // Unsubscribe from the subscriptions to prevent memory leaks
    if (this.camerasFoundSubscription) {
      this.camerasFoundSubscription.unsubscribe();
    }
    if (this.camerasNotFoundSubscription) {
      this.camerasNotFoundSubscription.unsubscribe();
    }
  }

  onCodeResult(resultString: string) {
    this.closeEvent.emit(resultString);
  }

  close(){
    this.closeEvent.emit();
  }

  onDeviceSelectChange(event: any): void {
    const device = this.availableDevices.find(x => x.deviceId === event.target.value);
    this.selectedDevice = device;
    localStorage.setItem("favCameraScan", JSON.stringify(device));
  }

  cameraAutoStarted(){
    const favCameraScan = JSON.parse(localStorage.getItem("favCameraScan")!);

    if(favCameraScan != null && this.availableDevices.find(x=>x.deviceId === favCameraScan.deviceId)){
      this.selectedDevice = favCameraScan;
    }
    else{
      const availableDevicesSamsung = this.availableDevices.find(device => device.label.includes('camera2 0, facing back'));
      if(availableDevicesSamsung != null){
        this.selectedDevice = availableDevicesSamsung;//select the correct camera if Samsung user
      }
      else{
        const availableDevicesWithBack = this.availableDevices.filter(device => device.label.includes('back'));
        if(availableDevicesWithBack != null && availableDevicesWithBack.length !== 0){
          this.selectedDevice = availableDevicesWithBack[0];//select the first back camera
        }
        else{
          this.selectedDevice = this.availableDevices[0]; // Select the first device by default
        }
      }
    }

    this.selectedOption = this.selectedDevice?.deviceId || "";
    this.isLoadingCamera = false;
  }
}
