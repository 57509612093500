import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageDataService{
  scannedOperatorIdValue = new BehaviorSubject(this.scannedOperatorId);

 set scannedOperatorId(value) {
   this.scannedOperatorIdValue.next(value); // this will make sure to tell every subscriber about the change.
   localStorage.setItem('scannedOperatorId', value);
 }

 get scannedOperatorId() {
   return localStorage.getItem('scannedOperatorId') || "";
 }
}
