import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSelectModule } from '@angular/material/select';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { msalConfig, loginRequest, protectedResources } from './auth-config';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { DemoButtonComponent } from './components/demo-button/demo-button.component';
import { DemoCardComponent } from './components/demo-card/demo-card.component';
import { DesignPageComponent } from './pages/design-page/design-page.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { ProductionTrackingComponent } from './components/production-tracking/production-tracking.component';
import { DeviationsListPageComponent } from './pages/deviations-list-page/deviations-list-page.component';
import { SearchPipe } from './pipes/search.pipe';
import { SearchMissingPartPipe } from './pipes/searchMissingPart.pipe';
import { MissingPartsMgPageComponent } from './pages/missing-parts-mg-page/missing-parts-mg-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { SkeletonDirective } from './directives/skeleton.directive';
import { RectangleComponent } from './components/skeleton/rectangle/rectangle.component';
import { DemoDatePipe } from './pipes/demoDate.pipe';
import { FilterSelectFunctionPipe } from './pipes/filterSelectFunction.pipe';
import { TreeDataComponent } from './components/tree-data/tree-data.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NotificationMessageComponent } from './components/notification-message/notification-message.component';
import { HealthCheckComponent } from './components/health-check/health-check.component';
import { ErrorHandlerService } from './error-handler.service';
import { MonitoringService } from './monitoring.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { SuppliersPageComponent } from './pages/suppliers-page/suppliers-page.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { CacheInterceptorService } from './services/cache-interceptor.service';
import { ProblemSolvingStepComponent } from './components/problem-solving-step/problem-solving-step.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LocatePopupComponent } from './components/locate-popup/locate-popup.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { MessagePopupComponent } from './components/message-popup/message-popup.component';
import { DeviationPopupComponent } from './components/deviation-popup/deviation-popup.component';
import { DemoNotificationComponent } from './components/demo-notification/demo-notification.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { StepTableComponent } from './components/step-table/step-table.component';
import { OperatorScanningComponent } from './components/operator-scanning/operator-scanning.component';
import { SolvePopupComponent } from "./components/solve-popup/solve-popup.component";
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { BarcodeScannerComponent } from './components/barcode-scanner/barcode-scanner.component';
import { StakeholdersListComponent } from './components/stakeholders-list/stakeholders-list.component';
import { SingleActionPopupComponent } from './components/single-action-popup/single-action-popup.component';
import { SearchSupplierQualityPipe } from './pipes/search-supplier-quality.pipe';
import { ImpactedProductsListComponent } from './components/impacted-products-list/impacted-products-list.component';
import { WorkloadsListComponent } from './components/workloads-list/workloads-list.component';
import { TagElementComponent } from './components/tag/tag-element/tag-element.component';
import { TagsListComponent } from './components/tag/tags-list/tags-list.component';


//Azure Connection
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map < string,
        Array < string >> ();
    protectedResourceMap.set(protectedResources.demoApi.endpoint, protectedResources.demoApi.scopes);
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
      interactionType: InteractionType.Redirect,
      authRequest: loginRequest
  };
}

//Data Back Connection
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DemoButtonComponent,
    DemoCardComponent,
    DesignPageComponent,
    BackButtonComponent,
    ProductionTrackingComponent,
    DeviationsListPageComponent,
    SearchPipe,
    SearchMissingPartPipe,
    SearchSupplierQualityPipe,
    FilterSelectFunctionPipe,
    DemoDatePipe,
    MissingPartsMgPageComponent,
    HomePageComponent,
    SkeletonDirective,
    RectangleComponent,
    TreeDataComponent,
    NotificationMessageComponent,
    HealthCheckComponent,
    SuppliersPageComponent,
    UploadFileComponent,
    ProblemSolvingStepComponent,
    LocatePopupComponent,
    TooltipDirective,
    MessagePopupComponent,
    DeviationPopupComponent,
    SolvePopupComponent,
    DemoNotificationComponent,
    ErrorPageComponent,
    StepTableComponent,
    OperatorScanningComponent,
    BarcodeScannerComponent,
    StakeholdersListComponent,
    SingleActionPopupComponent,
    ImpactedProductsListComponent,
    WorkloadsListComponent,
    TagElementComponent,
    TagsListComponent,
  ],
  imports: [
    BrowserModule,
    MsalModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    DragDropModule,
    //MatSelectSearchModule,
    MatTreeModule,
    MatIconModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    ZXingScannerModule,
  ],
  providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
    }, {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
    }, {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    }, {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
    }, { 
      provide: ErrorHandler, 
      useClass: ErrorHandlerService 
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptorService,
      multi: true,
    },
    MonitoringService,
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
