<div class="related-element overview-workload">
    <div class="top-part">
        <span>{{"OVERVIEW WORKLOAD" | translate}}</span>
        <mat-icon class="mat-icon-rtl-mirror" (click)="openAddWorkloadPopup()">{{'add_circle'}}</mat-icon>
    </div>
    <div class="content-part" *ngIf="selectedSupplier != null">
        <div class="workload" *ngFor="let workload of selectedSupplier.workloads" (click)="openModifyWorkloadPopup(workload)"> 
            <span><strong>{{workload.employeeId}} {{workload.name}}</strong> {{workload.email}}</span>
            <span>{{workload.workload}}{{"HOUR SHORT" | translate}}</span>
            <mat-icon class="mat-icon-rtl-mirror" (click)="openRemoveWorkloadPopUp(workload, $event)">{{'do_not_disturb_on'}}</mat-icon>
        </div>
    </div>
</div>

<div class="popup-container" [ngClass]="{'active' : hasWorkloadToAdd || workloadToModify != null}" (pointerdown)="closePopUp()">
    <div class="popup" (pointerdown)="cancelClosePopUp()">
            <svg (click)="closePopUp()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062">
                <path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/>
                <path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/>
            </svg>
            
            <span class="title">{{ getPopUpTitle() | translate }}</span>

            <form [formGroup]="workloadForm">               
                <div class="field-container free-text-suggestions">
                    <label for="name">{{ 'NAME' | translate }}* <app-notification-message></app-notification-message></label>
                    <input #nameInput type="text" id="name" formControlName="name" [(ngModel)]="nameFilter" (focus)="inputNameFocused = true" (blur)="inputNameFocused = false"  autocomplete="off" (keyup.backspace)="inputNameFocused = true" required>
                    <ul class="suggestions" [ngClass]="{'active': inputNameFocused}">
                        <li (mousedown)="getAzureUsersList()" *ngIf="nameFilter != ''"><mat-icon class="mat-icon-rtl-mirror">{{'search'}}</mat-icon>{{"SEARCH" | translate}}</li>
                        <li *ngFor="let agent of azureUsersList" (mousedown)="selectAzureUser(agent)"><strong>{{agent?.employeeId}} {{agent?.agentName}}</strong> {{agent?.email}}</li>
                    </ul>
                </div>
               
                <div class="field-container">
                    <label for="workloadHours">{{ 'WORKLOAD HOURS' | translate }}*</label>
                    <input type="number" id="workloadHours" class="form-control" formControlName="workloadHours" autocomplete="off" min="0" required (keypress)="validateKeyPress($event)">
                </div>
                <app-demo-button 
                    text="{{ (hasWorkloadToAdd ? 'ADD WORKLOAD' : 'MODIFY WORKLOAD') | translate}}"
                    (clickEvent)="hasWorkloadToAdd ? saveWorkload() : modifyWorkload()"
                    [disable]="!workloadForm.valid || isSavingData"
                    color="tertiary"
                    svg="save"
                    tabindex="0">
                </app-demo-button>
            </form>
    </div>
</div>

<app-single-action-popup
                    [isPopupOpen]="workloadToRemove != null"
                    title="{{'REMOVE THIS WORKLOAD' | translate}}"
                    description="{{'REMOVE WORKLOAD MESSAGE' | translate}}"
                    actionText="{{'REMOVE' | translate}}"
                    (closePopupEvent)="closePopUp()"
                    (actionPopupEvent)="removeWorkload()">
</app-single-action-popup>  

<div class="spinner-container" *ngIf="isSavingData">
    <mat-spinner></mat-spinner>
</div>
