import { Directive, Input, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { RectangleComponent } from '../components/skeleton/rectangle/rectangle.component';

@Directive({
  selector: '[skeleton]'
})
export class SkeletonDirective {

  @Input('skeleton') isLoading = false;
  @Input('skeletonRepeat') size = 1;
  @Input('skeletonWidth') width: string = "";
  @Input('skeletonHeight') height: string = "";
  @Input('skeletonClassName') className: string ="";
  @Input('skeletonMargin') margin: string ="";

  constructor(private tpl: TemplateRef<any>, 
              private vcr: ViewContainerRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isLoading']) {
      this.vcr.clear();

      if (changes['isLoading'].currentValue) {
         Array.from({ length: this.size }).forEach(() => {
           const ref = this.vcr.createComponent(RectangleComponent);
           
           Object.assign(ref.instance, {
             width: this.width,
             height: this.height,
             className: this.className,
             margin: this.margin
           })
         })
      } else {
        this.vcr.createEmbeddedView(this.tpl);
      }
    }
  }

}
