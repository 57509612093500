import { ActionType } from "./deviationHistory.model";

export enum SupplierQualityHistoryType {
    Creation = 0, // SupplierQuality creation
    Update = 1, // Property modification
    Addition = 2, // New element in a list
    Deletion = 3 // Element deleted from a list
}

export class SupplierQualityHistoryLine {
    id?:number;
    supplierQualityId:number;
    date:Date;
    actionType:SupplierQualityHistoryType;
    propertyName:string | null;
    oldValue:string | null;
    newValue:string | null;
    userEmployeeId:string | null;
    userName:string | null;
    userEmail:string | null;

    constructor(supplierQualityId:number,
                date:Date,
                actionType:SupplierQualityHistoryType,
                propertyName:string | null,
                oldValue:string | null,
                newValue:string | null,
                userEmployeeId:string | null,
                userName:string | null,
                userEmail:string | null,
                id?:number) {
        
        this.id = id;
        this.date = date;
        this.actionType = actionType;
        this.propertyName = propertyName;
        this.oldValue = oldValue;
        this.newValue = newValue;
        this.userEmployeeId = userEmployeeId;
        this.supplierQualityId = supplierQualityId;
        this.userName = userName;
        this.userEmail = userEmail;
    }
}