import { Factory } from "./factory.model";

export class Role {

    name: string;
    factory!: Factory | null;
    factoryId: number;
    administrateData: boolean;
    createDeviation: boolean;
    deleteDeviation: boolean;
    readDeviation: boolean;
    modifyDeviation: boolean;
    solveDeviation: boolean;
    readProductQualityStatus: boolean;
    readMissingPart:boolean;
    missingPartManagement:boolean;
    validateMissingPart:boolean;
    createMissingPart:boolean;
    modifySolvedDeviation:boolean;
    checkCheckpoint:boolean;

    readSupplierQuality:boolean;
    createSupplierQuality:boolean;
    modifySupplierQuality:boolean;

    id?:number;

    constructor(name: string,
                factory: Factory,
                factoryId: number,
                administrateData: boolean,
                createDeviation: boolean,
                deleteDeviation: boolean,
                readDeviation: boolean,
                modifyDeviation: boolean,
                solveDeviation: boolean,
                readProductQualityStatus: boolean,
                readMissingPart:boolean,
                missingPartManagement:boolean,
                validateMissingPart:boolean, 
                createMissingPart:boolean, 
                modifySolvedDeviation:boolean,
                checkCheckpoint:boolean, 
                readSupplierQuality:boolean,
                createSupplierQuality:boolean,
                modifySupplierQuality:boolean,
                id:number) {
        this.name = name;
        this.factory = factory;
        this.factoryId = factoryId;
        this.administrateData = administrateData;
        this.createDeviation = createDeviation;
        this.deleteDeviation = deleteDeviation;
        this.readDeviation = readDeviation;
        this.modifyDeviation = modifyDeviation;
        this.solveDeviation = solveDeviation;
        this.readProductQualityStatus = readProductQualityStatus;
        this.readMissingPart = readMissingPart;
        this.missingPartManagement = missingPartManagement;
        this.validateMissingPart = validateMissingPart;
        this.createMissingPart = createMissingPart;
        this.modifySolvedDeviation = modifySolvedDeviation;
        this.checkCheckpoint = checkCheckpoint;

        this.readSupplierQuality = readSupplierQuality;
        this.createSupplierQuality = createSupplierQuality;
        this.modifySupplierQuality = modifySupplierQuality;
        
        this.id = id;
    }
}