import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserRole } from '../models/userRole.model';
import { Role } from '../models/role.model';

@Injectable({
  providedIn: 'root'
})
export class UserRolesService {
  private apiUrl = environment.API_URL;
  private rolesSubject = new BehaviorSubject<any>(null); // To store roles data
  public roles$: Observable<any> = this.rolesSubject.asObservable();

  constructor(private httpClient: HttpClient) { }

  // Method to fetch roles by user and factory ID
  getRolesByUserAndFactory(factoryId: number, username: string): void {
    this.httpClient.get(`${this.apiUrl}UserRole/ByUserAndFactoryId/${encodeURIComponent(username)}/${factoryId}`)
      .pipe(
        tap((res: any) => {
          // Handle the response data and update the BehaviorSubject
          const userRoles = this.findFactoryRole(res.userRoles, factoryId);
          const isGenericUser = res.isGenericUser;
          this.rolesSubject.next({ userRoles, isGenericUser });
        }),
        catchError((error: any) => {
          // Handle errors like 403 or 404
          if (error.status === 404 || error.status === 403) {
            const userRoles = this.setNoAuthorization(factoryId);
            const isGenericUser = false;
            this.rolesSubject.next({ userRoles, isGenericUser });
          }
          throw error; // Optional: rethrow the error if needed
        })
      ).subscribe();
  }

  private findFactoryRole(userRoles:UserRole[], factoryId:number):Role{
    const combinedRole:Role = {
      name: "",
      factory: null,
      factoryId: factoryId,
      administrateData: false,
      createDeviation: false,
      deleteDeviation: false,
      readDeviation: false,
      modifyDeviation: false,
      solveDeviation: false,
      readProductQualityStatus: false,
      readMissingPart:false,
      missingPartManagement:false,
      validateMissingPart:false,
      createMissingPart:false,
      modifySolvedDeviation:false,
      checkCheckpoint:false,
      readSupplierQuality:false,
      createSupplierQuality:false,
      modifySupplierQuality:false,
      // When adding attributes here also add them below
    };

    console.log('roles from service', userRoles);

    if(userRoles.length === 1){
      return userRoles[0].role!;
    }
    else{
      userRoles.forEach(userRole => {
        if (userRole.role == null){ return;}

        combinedRole.administrateData ||= userRole.role.administrateData;
        combinedRole.createDeviation ||= userRole.role.createDeviation;
        combinedRole.deleteDeviation ||= userRole.role.deleteDeviation;
        combinedRole.readDeviation ||= userRole.role.readDeviation;
        combinedRole.modifyDeviation ||= userRole.role.modifyDeviation;
        combinedRole.solveDeviation ||= userRole.role.solveDeviation;
        combinedRole.readProductQualityStatus ||= userRole.role.readProductQualityStatus;
        combinedRole.readMissingPart ||= userRole.role.readMissingPart;
        combinedRole.missingPartManagement ||= userRole.role.missingPartManagement;
        combinedRole.validateMissingPart ||= userRole.role.validateMissingPart;
        combinedRole.createMissingPart ||= userRole.role.createMissingPart;
        combinedRole.modifySolvedDeviation ||= userRole.role.modifySolvedDeviation;
        combinedRole.checkCheckpoint ||= userRole.role.checkCheckpoint;
        combinedRole.readSupplierQuality ||= userRole.role.readSupplierQuality;
        combinedRole.createSupplierQuality ||= userRole.role.createSupplierQuality;
        combinedRole.modifySupplierQuality ||= userRole.role.modifySupplierQuality;
      });
    }

    return combinedRole;
  }

  private setNoAuthorization(factoryId:number):Role{
    return {
      name: "",
      factory: null,
      factoryId: factoryId,
      administrateData: false,
      createDeviation: false,
      deleteDeviation: false,
      readDeviation: false,
      modifyDeviation: false,
      solveDeviation: false,
      readProductQualityStatus: false,
      readMissingPart:false,
      missingPartManagement:false,
      validateMissingPart:false,
      createMissingPart:false,
      modifySolvedDeviation:false,
      checkCheckpoint:false,
      readSupplierQuality:false,
      createSupplierQuality:false,
      modifySupplierQuality:false,
    };
  }
}
