import { BaseStepFile } from "./baseStepFile.model";

export class BaseStep {

    stepIndex:number;
    closed:boolean;
    comment:string | null;
    ownerEmployeeId:string | null;
    ownerName:string | null;
    ownerEmail:string | null;
    files?:BaseStepFile[];

    constructor(
        stepIndex:number,
        closed:boolean,
        comment:string,
        ownerEmployeeId:string,
        ownerName:string,
        ownerEmail:string,
        files?:BaseStepFile[]
    ) {
        this.stepIndex = stepIndex;
        this.closed = closed;
        this.comment = comment;
        this.ownerEmployeeId = ownerEmployeeId;
        this.ownerName = ownerName;
        this.ownerEmail = ownerEmail;
        this.files = files;
    }
}