export class MissingCause {

    id:number;
    code:string;
    level1:string;
    level2:string;
    level3:string;
    nameEN:string;
    nameFR:string;
    nameSV:string;
    nameDE:string;
    nameNL:string;
    namePT:string;
    disabled:boolean;

    constructor(   
        id:number,
        code:string,
        level1:string,
        level2:string,
        level3:string,
        nameEN:string,
        nameFR:string,
        nameSV:string,
        nameDE:string,
        nameNL:string,
        namePT:string,
        disabled:boolean) {

        this.id = id;
        this.code = code;
        this.level1 = level1;
        this.level2 = level2;
        this.level3 = level3;
        this.nameEN = nameEN;
        this.nameFR = nameFR;
        this.nameSV = nameSV;
        this.nameDE = nameDE;
        this.nameNL = nameNL;
        this.namePT = namePT;
        this.disabled = disabled;
    }
}