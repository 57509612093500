<div class="file-container" [ngClass]="{'about-to-drop': isUserAboutToDropFile}">
    <div class="title-container">
        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.2955 0.454505C14.8562 0.015165 14.1438 0.015165 13.7045 0.454505L6.54505 7.61396C6.10571 8.0533 6.10571 8.76561 6.54505 9.20495C6.98439 9.64429 7.6967 9.64429 8.13604 9.20495L13.375 3.96599V20.75C13.375 21.3713 13.8787 21.875 14.5 21.875C15.1213 21.875 15.625 21.3713 15.625 20.75V3.96599L20.864 9.20495C21.3033 9.64429 22.0156 9.64429 22.455 9.20495C22.8943 8.76561 22.8943 8.0533 22.455 7.61396L15.2955 0.454505Z"/><path d="M2.875 17C2.875 16.3787 2.37132 15.875 1.75 15.875C1.12868 15.875 0.625 16.3787 0.625 17V26.75C0.625 27.3713 1.12868 27.875 1.75 27.875H27.25C27.8713 27.875 28.375 27.3713 28.375 26.75V17C28.375 16.3787 27.8713 15.875 27.25 15.875C26.6287 15.875 26.125 16.3787 26.125 17V25.625H2.875V17Z"/></svg>
        <span class="title">{{'DRAG YOUR FILE HERE' | translate}}</span>
        <span class="subtitle">{{'OR CLICK THIS BUTTON TO BROWSE' | translate}}</span>
    </div>

    <span class="text-centered">{{'VALID FORMAT' | translate}}: image .pdf .xlsx .xls .docx .doc</span>
    <span class="text-centered">{{'MAX FILE SIZE' | translate}}: 10Mb</span>

    <label for="file-upload" class="custom-file-upload">
        <mat-icon class="mat-icon-rtl-mirror">{{'upload'}}</mat-icon>{{'UPLOAD DOCUMENT' | translate}}
    </label>
    <input 
        type="file" 
        id="file-upload" 
        class="file" 
        (change)="addFile($event)" 
        accept="image/*,.pdf, .xlsx, .xls, .doc, .docx" 
        multiple 
        (dragenter)="isUserAboutToDropFile = dropEventContainsFiles($event)" 
        (dragleave)="isUserAboutToDropFile = false" 
        #fileInput>
</div>