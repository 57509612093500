import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSpinner } from '@angular/material/progress-spinner';
import { Deviation } from 'src/app/models/deviation.model';
import { Product } from 'src/app/models/product.model';
import { environment } from 'src/environments/environment';
import { DemoButtonComponent } from '../demo-button/demo-button.component';

@Component({
  selector: 'app-solve-popup',
  templateUrl: './solve-popup.component.html',
  styleUrl: './solve-popup.component.less'
})
export class SolvePopupComponent {

  apiUrl:string =  environment.API_URL;
  @HostListener('window:keydown.escape') escapeEvent() { if(!this.isScanning && this.isPopupOpen){ this.closePopUp() }}
  @HostListener('window:keydown.enter') enterEvent() { if(this.isPopupOpen){ this.solve() }}

  @ViewChild('firstSolveInput') firstSolveInput!: ElementRef;

  @Input() selectedProduct!:Product | null;
  @Input() selectedDeviation!:Deviation | null;
  @Input() isPopupOpen:boolean = false;
  @Input() currentFactoryId:number = -1;
  @Input() operatorScanningEnabled:boolean = false;
  @Input() isGenericUser:boolean = false;
  
  @Input() solver:string | undefined = "";
  @Input() solverId:string = "";


  @Output() closePopupEvent = new EventEmitter<any>();
  @Output() solvePopupEvent = new EventEmitter<any>();

  solveForm = new FormGroup({
    solver : new FormControl(''),
    solverId: new FormControl(''),
    solveComment : new FormControl('')
  });

  isSavingData:boolean = false;

  //USER SCANNED
  userScanned:string = "";
  isScanning:boolean = false;

  constructor(private httpClient:HttpClient){
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isPopupOpen'] && changes['isPopupOpen'].currentValue) {
        this.setFormValues();
    }  
  }

  closePopUp(){
      this.isPopupOpen = false;
      this.selectedDeviation = null;
      this.solveForm.enable();
      this.closePopupEvent.emit(this.selectedDeviation);
  }

  setFormValues(){
    this.solveForm.reset();
    
    this.solveForm.setValue({
      solver: this.solver || "",
      solverId: this.solverId,
      solveComment: ""
    });
    this.isSavingData = false;

    setTimeout(()=>{
      this.firstSolveInput.nativeElement.focus();
    },100);
  }

  getProductFullName(p:Product){
    return p != null ? p.fabNumber + p.controlLetter : "";
  }

  solve(){
    if(!this.solveForm.valid || this.isSavingData || !this.hasIdentifiedUser()){ return;}

    const deviationToSolve:Deviation = Object.assign({}, this.selectedDeviation);

    this.isSavingData = true;
    deviationToSolve.solver = this.solveForm.value.solver || "";
    deviationToSolve.solverEmployeeId = this.solveForm.value.solverId || "";
    deviationToSolve.resolutionComment = this.solveForm.value.solveComment || "";
    deviationToSolve.operatorId = this.userScanned !== "" ? this.userScanned : null;
    
    this.httpClient.put<Deviation>(this.apiUrl + 'Deviation/Solve', deviationToSolve).subscribe(
      (res:Deviation) => {
        this.solvePopupEvent.emit(res);
      }
    );
  }

  hasIdentifiedUser():boolean{
    return this.isGenericUser ? this.userScanned !== "" : true;
  }
}
