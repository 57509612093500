import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as Diacritics from 'diacritics';

@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrl: './tags-list.component.less'
})
export class TagsListComponent {
  @Input() tagList = [{text:"Test Tag", id:1}, {text:"Second Test", id:2}];
  @Input() hasRightToModifyTag = false;

  @ViewChild('newTagInput') newTagInput!: ElementRef;

  tagSuggections = [
    {text:"Fourth tag", id:4},
    {text:"Fifth tag", id:5},
    {text:"Sixth tag", id:6},
    {text:"Seventh tag", id:7},
    {text:"Eighth tag", id:8},
    {text:"Nineth tag", id:9},
  ]

  addElementVisible = false;
  newTagElement = "";

  //Keyboard selection
  tagToBeSelected:any = null;

  setAddElementVisible(){
    this.addElementVisible = true;
    this.newTagInput.nativeElement.focus();
  }

  addNewElement(){
    if(this.tagToBeSelected != null){
      this.tagList.push(this.tagToBeSelected);
    }
    else if(this.newTagElement !== "") { 
      this.tagList.push({text:this.newTagElement, id:3});
    }
    this.newTagElement = "";
    this.addElementVisible = false;
    this.tagToBeSelected = null;
  }

  addElement(element:any){
    this.tagList.push(element);
    this.newTagElement = "";
    this.addElementVisible = false;
    this.tagToBeSelected = null;
  }

  removeTag(event:any){
    this.tagList.splice(this.tagList.findIndex(x => event.id === x.id), 1);
  }

  tagSuggectionsFiltered(){
    return this.tagSuggections.filter(x=>this.notSensitive(x.text).includes(this.notSensitive(this.newTagElement)))
  }

  notSensitive(s:string | null | undefined){
    if(s==null) { return "";}
    return Diacritics.remove(s).toLowerCase();
  }

  //Keyboard navigation
  selectNextTag(){
    const list = this.tagSuggectionsFiltered();
    const nextIndex:number = this.tagToBeSelected == null ? 0 : (list.findIndex(x => x.id === this.tagToBeSelected?.id) + 1) % list.length;
    this.tagToBeSelected = list[nextIndex];
  }
  selectPreviousTag(){
    const list = this.tagSuggectionsFiltered();
    const nextIndex:number = this.tagToBeSelected == null ? list.length - 1 : (list.findIndex(x => x.id === this.tagToBeSelected?.id) - 1 + list.length) % list.length;
    this.tagToBeSelected = list[nextIndex];
  }
}
