import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-rectangle',
  host: {
    'class': 'pulse'
  },
  templateUrl: './rectangle.component.html',
  styleUrls: ['./rectangle.component.less']
})
export class RectangleComponent implements OnInit {

  width = "";
  height = "";
  className = "";
  margin = "";

  constructor(private host: ElementRef<HTMLElement>) { }

  ngOnInit() {
    const host = this.host.nativeElement;

    if (this.className) {
      host.classList.add(this.className);
    }

    host.style.setProperty('--skeleton-rect-width', this.width ?? '20px');
    host.style.setProperty('--skeleton-rect-height', this.height ?? '20px');
    host.style.setProperty('--skeleton-rect-margin', this.margin ?? '8px 10px');
  }
}
