import { Component } from '@angular/core';

@Component({
  selector: 'app-demo-notification',
  templateUrl: './demo-notification.component.html',
  styleUrls: ['./demo-notification.component.less']
})
export class DemoNotificationComponent {
  showNotification() {
    if ('Notification' in window) {
      // Request permission to show notifications
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          // Create a notification
          const notification = new Notification('Hello, Angular!', {
            body: 'This is a sample notification from your Angular app.',
            icon: '/path-to-your-icon.png' // Set your own icon path
          });
        }
      });
    }
  }
}
