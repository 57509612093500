<a *ngIf="url !== ''" [ngClass]="[type, color, disable ? 'disable' : 'enable']" href="{{url}}">
    <span>{{text}}</span>
</a>

<a *ngIf="url === ''" [ngClass]="[type, color, disable ? 'disable' : 'enable']">
    <svg *ngIf="svg === 'check'" width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.9931 0.777145C4.9051 0.777145 0.7771 4.90515 0.7771 9.99314C0.7771 15.0811 4.9051 19.2091 9.9931 19.2091C15.0811 19.2091 19.2091 15.0811 19.2091 9.99314C19.2091 4.90515 15.0811 0.777145 9.9931 0.777145ZM14.7355 7.76595L9.3211 13.1995C9.14833 13.3723 8.8987 13.4875 8.64903 13.4875C8.39936 13.4875 8.14987 13.3915 7.97696 13.1995L5.2507 10.4731C4.86673 10.0892 4.86673 9.49398 5.2507 9.10988C5.63467 8.72591 6.22987 8.72591 6.61397 9.10988L8.64913 11.145L13.3915 6.40264C13.7755 6.01868 14.3707 6.01868 14.7548 6.40264C15.1195 6.78675 15.1195 7.38191 14.7355 7.76591L14.7355 7.76595Z"/>
    </svg>
    <svg *ngIf="svg === 'upload'" height="15" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.2955 0.454505C14.8562 0.015165 14.1438 0.015165 13.7045 0.454505L6.54505 7.61396C6.10571 8.0533 6.10571 8.76561 6.54505 9.20495C6.98439 9.64429 7.6967 9.64429 8.13604 9.20495L13.375 3.96599V20.75C13.375 21.3713 13.8787 21.875 14.5 21.875C15.1213 21.875 15.625 21.3713 15.625 20.75V3.96599L20.864 9.20495C21.3033 9.64429 22.0156 9.64429 22.455 9.20495C22.8943 8.76561 22.8943 8.0533 22.455 7.61396L15.2955 0.454505Z"/>
        <path d="M2.875 17C2.875 16.3787 2.37132 15.875 1.75 15.875C1.12868 15.875 0.625 16.3787 0.625 17V26.75C0.625 27.3713 1.12868 27.875 1.75 27.875H27.25C27.8713 27.875 28.375 27.3713 28.375 26.75V17C28.375 16.3787 27.8713 15.875 27.25 15.875C26.6287 15.875 26.125 16.3787 26.125 17V25.625H2.875V17Z"/>
    </svg>
    <svg *ngIf="svg === 'save'" height="15" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.925385 0.745226C0.752405 0.761431 0.605694 0.923363 0.606448 1.09719V14.8252C0.606448 15.0095 0.774148 15.1772 0.958417 15.1772H15.0384C15.2227 15.1772 15.3904 15.0095 15.3904 14.8252V4.08919C15.3911 3.99552 15.3526 3.9021 15.2859 3.83617L12.2939 0.844201C12.2284 0.780731 12.1375 0.74439 12.0464 0.745128H0.925442L0.925385 0.745226ZM1.31039 1.44916H4.83039V4.26523C4.83039 4.4495 4.99808 4.61719 5.18235 4.61719H10.8144C10.9986 4.61719 11.1663 4.44949 11.1663 4.26523V1.44916H11.9033L14.6863 4.23219V14.4732H12.3983V7.43319C12.3983 7.24892 12.2306 7.08123 12.0463 7.08123H3.91732C3.74434 7.09755 3.59751 7.25949 3.59839 7.43319V14.4732H1.31035L1.31039 1.44916ZM5.53439 1.44916H8.87839V3.9131H5.53439V1.44916ZM9.58239 1.44916H10.4624V3.9131H9.58239V1.44916ZM4.30239 7.78516H11.6944V14.4732H4.30239V7.78516Z" fill="white"/>
    </svg>
    <mat-icon *ngIf="svg != '' && svg != 'save' && svg != 'check' && svg != 'upload'" class="mat-icon-rtl-mirror" class="small">{{svg}}</mat-icon>
    <span *ngIf="type != 'btn-icon'">{{text}}</span>
</a>

<div class="tooltip-content" *ngIf="tooltipText != '' || tooltipTexts.length !== 0" [ngClass]="'tooltip-' + type">
    {{tooltipText | translate}}
    <span *ngFor="let text of tooltipTexts">{{text | translate}}</span>
</div>
