<div class="list-stakeholders" *ngIf="errorMessageSelectedCase == ''">  
    <div class="stakeholder-head">
        <span class="title">{{ "STAKEHOLDERS" | translate}}</span>
        <app-demo-button text="{{'NOTIFY STAKEHOLDERS' | translate}}" type="btn-small" color="reverse-grey" svg="mail" (clickEvent)="openNotifyStakeholdersPopup()"></app-demo-button>
    </div>
    <div class="stakeholder free-text-suggestions" *ngIf="(selectedMissingPart != null && roleAuth.missingPartManagement) || (selectedSupplier != null && roleAuth.modifySupplierQuality)"> 
        <input #addStakeholderInput type="text" id="partId" class="form-control" [(ngModel)]="stakeholderFilter" placeholder="{{'ADD A USER' | translate}}" (focus)="addStakeholderInputFocus()" (blur)="inputStakeholdersFocused = false" (keyup.enter)="addStakeholder()" autocomplete="off">
        <ul class="suggestions" [ngClass]="{'active': inputStakeholdersFocused}">
            <li (mousedown)="getAzureUsersList()" *ngIf="stakeholderFilter != ''"><mat-icon class="mat-icon-rtl-mirror">{{'search'}}</mat-icon>{{"SEARCH" | translate}}</li>
            <li *ngFor="let agent of azureUsersList" (mousedown)="addStakeholder(agent)"><strong>{{agent?.employeeId}} {{agent?.agentName}}</strong> {{agent?.email}}<mat-icon class="mat-icon-rtl-mirror">{{'add_circle'}}</mat-icon></li>
        </ul>
    </div>
    <div class="stakeholder" *ngFor="let s of selectedCaseStakeholders()"> 
        <span><strong>{{s.employeeId}} {{s.name}}</strong> {{s.email}}</span>
        <app-demo-button *ngIf="!isStakeholderOwner(s) && roleAuth?.missingPartManagement" text="{{'ASSIGN' | translate}}" color="reverse-grey" type="btn-small" svg="workspace_premium" (clickEvent)="openAssignPopUp(s)"></app-demo-button>
        <mat-icon *ngIf="!isStakeholderOwner(s) && roleAuth?.missingPartManagement" class="mat-icon-rtl-mirror" (click)="openRemoveStakeholderPopUp(s)">{{'do_not_disturb_on'}}</mat-icon>
        <mat-icon *ngIf="isStakeholderOwner(s)" class="mat-icon-rtl-mirror">{{'workspace_premium'}}</mat-icon>
    </div>
    <span *ngIf="warningStakeholderMessage != ''" class="stakeholder-warning"><mat-icon class="mat-icon-rtl-mirror" class="small">{{'warning'}}</mat-icon>{{warningStakeholderMessage | translate}}</span>
</div>

<div class="popup-container" [ngClass]="{'active' : stakeholderToRemove != null || hasNotificationToSend || hasMissingPartToAssign}" (pointerdown)="closePopUp()">
    <div class="popup" (pointerdown)="cancelClosePopUp()">
            <svg (click)="closePopUp()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062">
                <path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/>
                <path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/>
            </svg>
            
            
            <span class="title">{{ getPopUpTitle() | translate }}</span>
            
            <span class="selected-product" *ngIf="hasMissingPartToAssign && stakeholderToAssign != null">{{ "ASSIGN STAKEHOLDER CONFIRMATION MESSAGE" | translate }} {{stakeholderToAssign.name}} ?</span>
            <span class="selected-product" *ngIf="stakeholderToRemove != null">{{ "REMOVE STAKEHOLDER MESSAGE" | translate }}</span>
            <span class="selected-product" *ngIf="hasNotificationToSend">{{ "NOTIFY STAKEHOLDERS MESSAGE" | translate }}</span>
                 

            <app-demo-button text="{{ 'ASSIGN' | translate }}" color="tertiary" (clickEvent)="assignMissingPart()" [disable]=isSavingData *ngIf="hasMissingPartToAssign && stakeholderToAssign != null"></app-demo-button>
            <app-demo-button text="{{ 'REMOVE STAKEHOLDER' | translate }}" color="tertiary" (clickEvent)="removeStakeholder()" [disable]=isSavingData *ngIf="stakeholderToRemove != null"></app-demo-button>
            <app-demo-button text="{{ 'NOTIFY STAKEHOLDERS' | translate }}" color="tertiary" (clickEvent)="notifyStakeholders()" [disable]=isSavingData *ngIf="hasNotificationToSend"></app-demo-button>
           
    </div>

</div>
