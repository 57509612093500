<app-tag-element *ngFor="let tag of tagList" text="{{tag.text}}" [id]="tag.id" [hasRightToModifyTag]="hasRightToModifyTag" (removeEvent)="removeTag($event)"></app-tag-element>

<mat-icon class="add" (click)="setAddElementVisible()" [ngClass]="{'active':!addElementVisible}" *ngIf="hasRightToModifyTag && tagList.length < 3">{{'add'}}</mat-icon>

<div class="add-element-container" [ngClass]="{'active':addElementVisible}">
    <input #newTagInput type="text" (blur)="addNewElement()" (keydown.enter)="addNewElement()" (keydown.arrowdown)="selectNextTag()" (keydown.arrowup)="selectPreviousTag()" [(ngModel)]="newTagElement">
    <div class="suggestions-container">
        <span *ngFor="let suggestion of tagSuggectionsFiltered()" 
            (mousedown)="addElement(suggestion)"
            [ngClass]="{'to-be-selected':tagToBeSelected?.id === suggestion.id}">
            {{suggestion.text}}
        </span>
    </div>
</div>
