<div class="popup-container component-solve-popup" [ngClass]="{'active' : isPopupOpen}">
    <div class="popup">
        <div class="popup-content">

            <div class="close-container">
                <svg (click)="closePopUp()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062">
                    <path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/>
                    <path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/>
                </svg>
            </div>

            <span class="title">{{title}}</span>
            <span class="description">{{description}}</span>
            <app-demo-button 
                        class="centered"
                        text="{{actionText}}"
                        (clickEvent)="triggerMainAction()"
                        [disable]="isSavingData"
                        color="tertiary"
                        svg="save"
                        tabindex="0">
            </app-demo-button>

            <div class="spinner-container" *ngIf="isSavingData">
                <mat-spinner></mat-spinner>
            </div>
        </div>

    </div>
</div>