<h1>Health Check</h1>
<p>{{ result.apiUrl }}</p>

<table class='table table-striped' aria-labelledby="tableLabel" *ngIf="result">
  <thead>
    <tr>
      <th>Name</th>
      <th>Response Time</th>
      <th>Status</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr class="global">
        <td>Global</td>
        <td>{{ result.totalResponseTime }}ms</td>
        <td class="status {{ result.totalStatus }}">{{ result.totalStatus }}</td>
    </tr>
    <tr>
        <td>----------</td>
        <td>----------</td>
        <td>----------</td>
        <td>----------</td>
    </tr>
    <tr *ngFor="let check of result.checks">
      <td>{{ check.name }}</td>
      <td>{{ check.responseTime }}ms</td>
      <td class="status {{ check.status }}">{{ check.status }}</td>
      <td>{{ check.description }}</td>
    </tr>
  </tbody>
</table>