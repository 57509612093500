import { Deviation } from './deviation.model';
import { ProductionLine } from './productionLine.model';
import { ProductionZone } from './productionZone.model';
import { Workcell } from './workcell.model';

export class ProductLocation {
    
    productId:number;
    assemblyDate: Date;
    rank: number;
    currentWorkcell?:Workcell;
    currentWorkcellId?: number;
    currentProductionZone?:ProductionZone; 
    currentProductionZoneId?:number;
    currentProductionLine?: ProductionLine;
    currentProductionLineId?: number;
    order:number;


    constructor(productId:number, assemblyDate: Date, rank: number, order:number, currentWorkcell?:Workcell, currentWorkcellId?: number, currentProductionZone?:ProductionZone, currentProductionZoneId?:number, currentProductionLine?: ProductionLine, currentProductionLineId?: number) {
        this.productId = productId;
        this.assemblyDate = assemblyDate;
        this.rank = rank;
        this.currentWorkcell = currentWorkcell;
        this.currentWorkcellId = currentWorkcellId;
        this.currentProductionZone = currentProductionZone;
        this.currentProductionZoneId = currentProductionZoneId;
        this.currentProductionLine = currentProductionLine;
        this.currentProductionLineId = currentProductionLineId;
        this.order = order;
    }
}