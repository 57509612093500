<div class="page-container">
    <div class="content">
        <span class="welcome">{{"WELCOME TO DEMO" | translate }}</span>

        <div class="button-container">  
            <app-demo-button text="{{'GO TO DEVIATION PAGE' | translate }}" url="/deviations-list"></app-demo-button>
            <app-demo-button text="{{'GO TO MISSING PART PAGE' | translate }}" url="/missing-parts"></app-demo-button>
        </div>

        <div *ngIf="envTest">
            <iframe title="Generic DeMO HomePage" width="1140" height="541.25" [src]="powerbiURL"
            frameborder="0" allowFullScreen="true">
            </iframe>
        </div>
    </div>
</div>
