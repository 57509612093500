import { Directive, ElementRef, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  private tooltip!: HTMLElement | null;

  @HostListener('mouseenter') onMouseEnter() {
    if (this.isTextOverflowed(this.el.nativeElement)) {
      this.showTooltip();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hideTooltip();
  }

  @HostListener('mousewheel', ['$event']) onDocumentMousewheelEvent(event:Event) {
    this.positionTooltip();
  }

  private isTextOverflowed(element: HTMLElement): boolean {
    return element.scrollWidth > element.clientWidth;
  }

  private showTooltip() {
    this.tooltip = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltip, 'text-too-long-tooltip');
    this.renderer.appendChild(this.tooltip, document.createTextNode(this.el.nativeElement.textContent));
    this.renderer.appendChild(this.el.nativeElement, this.tooltip);

    this.positionTooltip();
  }

  private hideTooltip() {
    if (this.tooltip) {
      this.renderer.removeChild(this.el.nativeElement, this.tooltip);
      this.tooltip = null;
    }
  }

  private positionTooltip() {
    if (this.tooltip) {
      this.renderer.setStyle(this.tooltip, 'top', this.el.nativeElement.parentElement.parentElement.offsetTop - this.el.nativeElement.parentElement.parentElement.parentElement.scrollTop + 55 + 'px');
    }
  }
}
