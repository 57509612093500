import { Point } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ThrottlingUtils } from '@azure/msal-common';
import { Deviation } from 'src/app/models/deviation.model';
import { DeviationLocation } from 'src/app/models/deviationLocation.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-locate-popup',
  templateUrl: './locate-popup.component.html',
  styleUrls: ['./locate-popup.component.less']
})
export class LocatePopupComponent {

  @HostListener('window:keyup.escape') escapeEvent() { this.close(); }
  @HostListener('window:keydown.ArrowRight') nextEvent() { this.selectNextImage(); }
  @HostListener('window:keydown.ArrowLeft') previousEvent() { this.selectPreviousImage(); }
  
  @Input() selectedDeviation!:Deviation | null;
  @Input() productDeviations!:Deviation[] | null | undefined;
  @Input() selectedLocations!:DeviationLocation[] | null;
  @Input() selectedWorkcellId!:number | null;
  @Input() disable = false;
  @Input() openPopup = false;

  @Output() saveEvent = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<any>();

  apiUrl:string =  environment.API_URL;
  isLoadingImage = false;

  tmpLocations:DeviationLocation[] = [];

  imagePool:any;
  selectedImage = "";

  imageUrl = "";
  svgContent!:SafeHtml | null;

  constructor(
    private httpClient:HttpClient,
    private sanitizer: DomSanitizer){
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['openPopup'].currentValue) {
      this.getImage();
      if(this.selectedDeviation?.locations != null && this.selectedLocations != null && this.tmpLocations.length === 0){
        this.tmpLocations = [...this.selectedDeviation?.locations] || null;
      }
    }
  }
  
  getLocationsOfImage():DeviationLocation[]{
    if(this.selectedImage === "" || this.tmpLocations == null) { return [];}

    return this.tmpLocations.filter(x=>x.fileName === this.selectedImage);
  }

  resetLocation(){
    this.tmpLocations = [];
  }

  close(){
    this.closeEvent.emit({locations: this.tmpLocations});
  }

  addPin(event:any){
    if(this.disable){ return;}

    const zoneName = event?.srcElement?.attributes?.functiongroups?.value != null ? event.srcElement.attributes.functiongroups.value : "";
    if(event?.srcElement?.attributes?.functiongroups?.value != null){
      console.log("zone", event?.srcElement.classList)
      event?.srcElement.classList.add("selected");
    }

    if(this.selectedDeviation?.id != null){
      this.tmpLocations.push(new DeviationLocation(this.selectedDeviation.id, this.selectedImage, event.offsetX, event.offsetY, zoneName))}
    else{
      this.tmpLocations.push(new DeviationLocation(0, this.selectedImage, event.offsetX, event.offsetY, zoneName))}
  }

  removePin(toRemove:DeviationLocation){
    if(this.tmpLocations.filter(x=>x.fileName === this.selectedImage && x.zone === toRemove.zone)?.length === 1){
      document.querySelector(`[functiongroups="${toRemove.zone}"]`)?.classList.remove("selected");
    }
    this.tmpLocations.splice(this.tmpLocations.indexOf(toRemove), 1)
  }

  getImage(){
    if((this.selectedDeviation == null || this.selectedDeviation.locations == null) && this.selectedWorkcellId != null){
      this.getImageByWorkcell(this.selectedWorkcellId);}
    else if(this.selectedDeviation != null){
      this.getImageByDeviation(this.selectedDeviation.id!);}
  }

  getImageByWorkcell(workcellId:number | null){
    if(workcellId == null){ return;}
    this.isLoadingImage = true;
    this.imageUrl = "";
    this.httpClient.get(this.apiUrl + "Deviation/LocationFile/ByWorkcellId/" + workcellId, { responseType: 'json'}).subscribe(
      (next:any) => {
        this.imagePool = next;
        this.selectedImage = this.findFirstImage();
        this.selectImage();
      },
      (error) => {
        console.error('Error loading image', error);
        this.isLoadingImage = false;
      }
    );
    
  }

  getImageByDeviation(deviationId:number | null){
    if(deviationId == null){ return;}
    this.isLoadingImage = true;
    this.imageUrl = "";
    this.httpClient.get(this.apiUrl + "Deviation/LocationFile/ByDeviationId/" + deviationId, { responseType: 'json'}).subscribe(
      (next:any) => {
        this.imagePool = next;
        this.selectedImage = this.findFirstImage();
        this.selectImage();    
      },
      (error) => {
        console.error('Error loading image', error);
        this.isLoadingImage = false;
      }
    );
    
  }

  findFirstImage():string{
    const firstImageWithPin = this.imagePool.find((x:any)=>this.hasLocation(x));

    if(firstImageWithPin != null){
      return firstImageWithPin.FileName;}
    else if(this.productDeviations != null){
      const otherDeviationsWithPin:Deviation[] = this.productDeviations.filter(x=> x.locations != null && x.locations.length !== 0);
      const latestDeviation:Deviation = otherDeviationsWithPin.sort((a, b) => (a.detectionDate! < b.detectionDate!) ? -1 : 1).reverse()[0];

      if(latestDeviation != null && latestDeviation.locations != null && latestDeviation.locations.length > 0){
        return latestDeviation.locations[0].fileName;}     
    }

    return this.imagePool[0].FileName;
  }

  hasLocation(image:any):boolean{
    return this.selectedDeviation?.locations?.find(x=>x.fileName === image.FileName) != null;
  }

  selectImage(event?:any){
    this.isLoadingImage = true;

    //find image in the pool
    const chosenImage:any = this.imagePool.find((x:any)=>x.FileName === this.selectedImage);

    if(chosenImage != null){
      if(!chosenImage.ContentType.includes("svg")){
        this.imageUrl = "data:" + chosenImage.ContentType + ";base64," + chosenImage.FileBytes;
        this.svgContent = null;
      }
      else{
        this.imageUrl = '';
        this.svgContent = this.sanitizer.bypassSecurityTrustHtml(atob(chosenImage.FileBytes));
      }

      this.isLoadingImage = false;

      setTimeout(()=>{
        const imageLocations = this.getLocationsOfImage();
        if(imageLocations != null){
          imageLocations.forEach(location => {
            if(location != null && location.zone !== "" && location.zone != null){
              document.querySelector(`[functiongroups="${location.zone}"]`)?.classList.add("selected");
            }
          });
        }
      },100);
    }
  }

  selectNextImage(){
    const nextIndex:number = ((this.imagePool.findIndex((x:any)=>x.FileName === this.selectedImage) + 1) % this.imagePool.length);
    if(nextIndex != null){
      this.selectedImage = this.imagePool[nextIndex].FileName;
      this.selectImage();
    }
  }

  selectPreviousImage(){
    const nextIndex:number = ((this.imagePool.findIndex((x:any)=>x.FileName === this.selectedImage) + this.imagePool.length - 1) % this.imagePool.length);
    if(nextIndex != null){
      this.selectedImage = this.imagePool[nextIndex].FileName;
      this.selectImage();
    }
  }
}
