<div (click)="clickOnComponent()">
    <div class="head {{type}}">
        <mat-icon class="mat-icon-rtl-mirror" *ngIf="type == '' || type == 'success'">{{'new_releases'}}</mat-icon>
        <mat-icon class="mat-icon-rtl-mirror" *ngIf="type == 'error'">{{'error'}}</mat-icon>
        <span>{{title}}</span>
        <span *ngIf="messageNumber > 1">({{messageNumber}})</span>
    </div>
    <div class="content">{{content}}</div>
    <app-demo-button text="{{'RELOAD PAGE' | translate}}" color="tertiary" type="btn-small" svg="refresh" (clickEvent)="reloadPage()" *ngIf="reloadPageBtn"></app-demo-button>
    
    <mat-icon class="mat-icon-rtl-mirror close-btn" (click)="close()">{{'close'}}</mat-icon>
</div>
