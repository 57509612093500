<div class="container-scan" (click)="startScan()" [ngClass]="{'header-version':headerVersion}">
    <svg *ngIf="scannedOperatorId == ''" class="scan-icon" width="20" height="20" viewBox="0 0 107 95" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.39551 33.1314C4.39551 28.6855 6.16162 24.4217 9.30532 21.278C12.449 18.1343 16.7128 16.3682 21.1587 16.3682H61.8692V49.8945H21.1587C16.7128 49.8945 12.449 48.1284 9.30532 44.9847C6.16162 41.841 4.39551 37.5772 4.39551 33.1314V33.1314Z" stroke="black" stroke-width="4" stroke-linejoin="round"/><path d="M16.3684 49.8945L2 76.2366L13.9258 84.7619C14.537 85.1943 15.2319 85.4939 15.9658 85.6415C16.6998 85.7892 17.4565 85.7815 18.1873 85.6191C18.9182 85.4567 19.6069 85.1431 20.2092 84.6985C20.8116 84.254 21.3142 83.6882 21.6847 83.0377L40.3158 49.8945" stroke="black" stroke-width="4" stroke-linejoin="round"/><path d="M52.2899 49.8945V59.7129C52.2292 62.1708 51.2258 64.5112 49.4872 66.2497C47.7487 67.9882 45.4083 68.9917 42.9505 69.0524H30.7373" stroke="black" stroke-width="4" stroke-linejoin="round"/><path d="M81.0264 69.0522H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 83.4209H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 93H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 59.4736H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 45.1055H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 35.5264H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 25.9478H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 11.5791H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 2H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
    <mat-icon *ngIf="scannedOperatorId != ''" class="mat-icon-rtl-mirror">{{'badge'}}</mat-icon>
        {{scannedOperatorId != "" ?  scannedOperatorId + " " + (scannedAgent?.agentName || "" | translate) : "SCAN AN OPERATOR ID" | translate}}
        <div *ngIf="headerVersion && scannedOperatorId != ''" (click)="removeScannedUser($event)" class="forget-me">{{"FORGET ME" | translate}}</div>
        <div class="scan-input-container" *ngIf="isScanning">
            <input #scanInput id="scan-input" type="text" [(ngModel)]="scanText" autocomplete="off" (keydown.enter)="stopPropagation($event); stopScan()" (keyup.s)="stopPropagation($event)">
        </div>
        <div class="scanning-screen" [ngClass]="{'active':isScanning, 'error':hasScanningError}">
            <mat-icon class="close" (click)="escapeScan($event)">{{'close'}}</mat-icon>
            <span [ngClass]="{'message-on':hasScanningError}" class="error-message">{{scanningErrorMessage | translate}}</span>
            <span *ngIf="!hasScanningError">{{scanText}}</span>
            <svg width="200" height="200" viewBox="0 0 107 95" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.39551 33.1314C4.39551 28.6855 6.16162 24.4217 9.30532 21.278C12.449 18.1343 16.7128 16.3682 21.1587 16.3682H61.8692V49.8945H21.1587C16.7128 49.8945 12.449 48.1284 9.30532 44.9847C6.16162 41.841 4.39551 37.5772 4.39551 33.1314V33.1314Z" stroke="black" stroke-width="4" stroke-linejoin="round"/><path d="M16.3684 49.8945L2 76.2366L13.9258 84.7619C14.537 85.1943 15.2319 85.4939 15.9658 85.6415C16.6998 85.7892 17.4565 85.7815 18.1873 85.6191C18.9182 85.4567 19.6069 85.1431 20.2092 84.6985C20.8116 84.254 21.3142 83.6882 21.6847 83.0377L40.3158 49.8945" stroke="black" stroke-width="4" stroke-linejoin="round"/><path d="M52.2899 49.8945V59.7129C52.2292 62.1708 51.2258 64.5112 49.4872 66.2497C47.7487 67.9882 45.4083 68.9917 42.9505 69.0524H30.7373" stroke="black" stroke-width="4" stroke-linejoin="round"/><path d="M81.0264 69.0522H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 83.4209H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 93H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 59.4736H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 45.1055H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 35.5264H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 25.9478H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 11.5791H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 2H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
</div>
<div class="checkbox-container" *ngIf="!headerVersion">
    <label>
        <input type="checkbox" [(ngModel)]="rememberMe" class="small">
        {{"REMEMBER ME" | translate}}
    </label>
</div>
