export class FunctionGroupView {
    id:number;
    parentId?:number; // null if no parent
    code:string;
    name:string | null; // name in the specified language

    constructor(id:number, code:string, name:string | null, parentId?:number){ 
        this.id = id;
        this.parentId = parentId;
        this.code = code;
        this.name = name;
    }
}
