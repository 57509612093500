import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.less']
})
export class HomePageComponent implements OnInit {

  powerbiURL!:SafeResourceUrl;
  envTest:boolean = environment.name === "test";

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if(localStorage.getItem('chosenFactory')){
      this.powerbiURL = this.sanitizer.bypassSecurityTrustResourceUrl("https://app.powerbi.com/reportEmbed?reportId=e64b552c-8af1-4474-82fa-911346665205&autoAuth=true&ctid=f25493ae-1c98-41d7-8a33-0be75f5fe603&filter=Factories/Id%20eq%20" + localStorage.getItem('chosenFactory'));
    }
  }
}
