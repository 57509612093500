import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HealthCheckComponent } from './components/health-check/health-check.component';
import { DesignPageComponent } from './pages/design-page/design-page.component';
import { DeviationsListPageComponent } from './pages/deviations-list-page/deviations-list-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { MissingPartsMgPageComponent } from './pages/missing-parts-mg-page/missing-parts-mg-page.component';
import { SuppliersPageComponent } from './pages/suppliers-page/suppliers-page.component';

const routes: Routes = [
  {
  path: 'design-elements',
  component: DesignPageComponent
  },
  {
    path: '',
    component: HomePageComponent
  },
  {
    path: 'health',
    component: HealthCheckComponent
  },
  {
    path: 'deviations-list',
    component: DeviationsListPageComponent
  },
  {
    path: 'deviations-list/displayid/:displayId',
    component: DeviationsListPageComponent
  },
  {
    path: 'deviations-list/:factoryId/:lineId',
    component: DeviationsListPageComponent
  },
  {
    path: 'missing-parts',
    component: MissingPartsMgPageComponent
  },
  {
    path: 'missing-parts/:factoryId/:lineId',
    component: MissingPartsMgPageComponent
  },
  {
    path: 'missing-parts/:factoryId/:lineId/:missingPartId',
    component: MissingPartsMgPageComponent
  },
  {
    path: 'suppliers',
    component: SuppliersPageComponent
  },
  {
    path: 'suppliers/:factoryId/:lineId',
    component: SuppliersPageComponent
  },
  {
    path: 'suppliers/:factoryId/:lineId/:supplierCaseId',
    component: SuppliersPageComponent
  },
  { path: '**', pathMatch: 'full',  
        component: ErrorPageComponent }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
