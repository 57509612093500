import { Check } from './check.model';
import { Deviation } from './deviation.model';
import { ProductLocation } from './productLocation.model';

export class Product {
    
    chassisId:string;
    deviations:Deviation[];
    productionLocation:string | null;
    brandProductionLocation: string | null;
    id:number;
    locations:ProductLocation[];
    chassisSeries:string;
    chassisNumber:string;
    fabNumber:string;
    controlLetter:string;
    checks:Check[] | null;


    constructor(
            chassisId:string, 
            deviations:Deviation[],
            productionLocation:string | null,
            brandProductionLocation: string | null,
            id:number,
            locations:ProductLocation[],
            chassisSeries:string,
            chassisNumber:string,
            fabNumber:string,
            controlLetter:string,
            checks:Check[] | null) {
        this.chassisId= chassisId;
        this.deviations=deviations;
        this.productionLocation=productionLocation;
        this.brandProductionLocation = brandProductionLocation;
        this.id = id;
        this.locations = locations;
        this.chassisSeries =chassisSeries;
        this.chassisNumber = chassisNumber;
        this.fabNumber = fabNumber;
        this.controlLetter = controlLetter;
        this.checks = checks;
    }
}