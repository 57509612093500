import { DatePipe } from '@angular/common';
import { DeviationLocation } from './deviationLocation.model';
import { DeviationTagLink } from './deviationTagLink.model';
import { FaultType } from './faultType.model';
import { FunctionGroup } from './functionGroup.model';
import { Responsible } from './responsible.model';
import { Workcell } from './workcell.model';

export enum MissingStatus{
    ToBeConfirmed,
    Confirmed
}

export class Deviation {
    description:string;
    detectionWorkcell?:Workcell | null;
    detectionWorkcellId:number;
    detectionDate:Date | null;
    resolutionDate:Date | null;
    productId:number;
    creator:string;
    creatorEmployeeId:string;
    missingPartId:number | null;
    missingParts:string;
    missingPartTitle?:string | null;
    missingPartStatus:MissingStatus;
    missingPartValidationDate:Date| null;
    resolutionWorkcell:Workcell | null;
    resolutionWorkcellId:number | null;
    resolutionComment:string;
    solver:string;
    solverEmployeeId:string;
    severity:number | null;
    faultTypeId:number | null;
    faultType: FaultType | null;

    responsibleId:number | null;
    responsible:Responsible | null;

    responsibleLevel1Id:number | null;
    responsibleLevel1:Responsible | null;
    responsibleLevel2Id:number | null;
    responsibleLevel2:Responsible | null;
    responsibleLevel3Id:number | null;
    responsibleLevel3:Responsible | null;

    functionGroup:FunctionGroup | null;
    functionGroupId:number | null;
    functionGroupLevel1?:FunctionGroup | null;
    functionGroupLevel1Id?:number | null;
    functionGroupLevel2?:FunctionGroup | null;
    functionGroupLevel2Id?:number | null;
    functionGroupLevel3?:FunctionGroup | null;
    functionGroupLevel3Id?:number | null;
    functionGroupLevel4?:FunctionGroup | null;
    functionGroupLevel4Id?:number | null;
    problemDetail:FunctionGroup | null;
    problemDetailId:number | null;
    problemDetailLevel1?:FunctionGroup | null;
    problemDetailLevel1Id?:number | null;
    problemDetailLevel2?:FunctionGroup | null;
    problemDetailLevel2Id?:number | null;
    problemDetailLevel3?:FunctionGroup | null;
    problemDetailLevel3Id?:number | null;
    problemDetailLevel4?:FunctionGroup | null;
    problemDetailLevel4Id?:number | null;
    factoryId?:number;
    id?:number;
    displayId?:string;
    missingPartsAmount:number;
    locations?:DeviationLocation[] | null;
    operatorId:string | null;
    tagLinks:DeviationTagLink[] | null;
   

    constructor(
            description:string,
            detectionWorkcell:Workcell,
            detectionWorkcellId:number,
            detectionDate:Date,
            resolutionDate:Date,
            productId:number,
            creator:string,
            creatorEmployeeId:string,
            missingPartId:number,
            missingParts:string,
            missingPartStatus:MissingStatus,
            missingPartValidationDate:Date,
            resolutionWorkcell:Workcell,
            resolutionWorkcellId:number,
            resolutionComment:string,
            solver:string,
            solverEmployeeId:string,
            severity:number,
            faultTypeId:number,
            faultType:FaultType,
            responsibleId:number,
            responsible:Responsible,
            responsibleLevel1Id:number | null,
            responsibleLevel1:Responsible | null,
            responsibleLevel2Id:number | null,
            responsibleLevel2:Responsible | null,
            responsibleLevel3Id:number | null,
            responsibleLevel3:Responsible | null,
            missingPartsAmount:number,
            functionGroup:FunctionGroup,
            functionGroupId:number,
            problemDetail:FunctionGroup,
            problemDetailId:number,
            operatorId:string,
            tagLinks:DeviationTagLink[] | null,
            functionGroupLevel1?:FunctionGroup,
            functionGroupLevel1Id?:number,
            functionGroupLevel2?:FunctionGroup,
            functionGroupLevel2Id?:number,
            functionGroupLevel3?:FunctionGroup,
            functionGroupLevel3Id?:number,
            functionGroupLevel4?:FunctionGroup,
            functionGroupLevel4Id?:number,
            problemDetailLevel1?:FunctionGroup,
            problemDetailLevel1Id?:number,
            problemDetailLevel2?:FunctionGroup,
            problemDetailLevel2Id?:number,
            problemDetailLevel3?:FunctionGroup,
            problemDetailLevel3Id?:number,
            problemDetailLevel4?:FunctionGroup,
            problemDetailLevel4Id?:number,
            missingPartTitle?:string | null,
            locations?:DeviationLocation[] | null,
            factoryId?:number,
            id?:number,
            displayId?:string) {
        this.description = description;
        this.detectionWorkcell = detectionWorkcell;
        this.detectionWorkcellId = detectionWorkcellId;
        this.detectionDate = detectionDate;
        this.resolutionDate = resolutionDate;
        this.productId = productId;
        this.creator = creator;
        this.creatorEmployeeId = creatorEmployeeId;
        this.missingPartId = missingPartId;
        this.missingParts = missingParts;
        this.missingPartStatus = missingPartStatus;
        this.missingPartValidationDate = missingPartValidationDate;
        this.resolutionWorkcell = resolutionWorkcell;
        this.resolutionWorkcellId = resolutionWorkcellId;
        this.resolutionComment = resolutionComment;
        this.solver = solver;
        this.solverEmployeeId = solverEmployeeId;
        this.severity = severity;
        this.faultTypeId = faultTypeId;
        this.faultType = faultType;
        this.responsibleId = responsibleId;
        this.responsible = responsible;
        this.responsibleLevel1Id = responsibleLevel1Id;
        this.responsibleLevel1 = responsibleLevel1;
        this.responsibleLevel2Id = responsibleLevel2Id;
        this.responsibleLevel2 = responsibleLevel2;
        this.responsibleLevel3Id = responsibleLevel3Id;
        this.responsibleLevel3 = responsibleLevel3;
        
        this.functionGroup = functionGroup;
        this.functionGroupId = functionGroupId;
        this.functionGroupLevel1 = functionGroupLevel1;
        this.functionGroupLevel2 = functionGroupLevel2;
        this.functionGroupLevel3 = functionGroupLevel3;
        this.functionGroupLevel4 = functionGroupLevel4;
        this.functionGroupLevel1Id = functionGroupLevel1Id;
        this.functionGroupLevel2Id = functionGroupLevel2Id;
        this.functionGroupLevel3Id = functionGroupLevel3Id;
        this.functionGroupLevel4Id = functionGroupLevel4Id;
        this.problemDetail = problemDetail;
        this.problemDetailId = problemDetailId;
        this.problemDetailLevel1 = problemDetailLevel1;
        this.problemDetailLevel2 = problemDetailLevel2;
        this.problemDetailLevel3 = problemDetailLevel3;
        this.problemDetailLevel4 = problemDetailLevel4;
        this.problemDetailLevel1Id = problemDetailLevel1Id;
        this.problemDetailLevel2Id = problemDetailLevel2Id;
        this.problemDetailLevel3Id = problemDetailLevel3Id;
        this.problemDetailLevel4Id = problemDetailLevel4Id;
        this.missingPartTitle = missingPartTitle;
        this.factoryId = factoryId;
        this.id = id;
        this.displayId = displayId;
        this.missingPartsAmount = missingPartsAmount;
        this.locations = locations;
        this.operatorId = operatorId;
        this.tagLinks = tagLinks;
    }
}