import { Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, BrowserUtils, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { Location } from "@angular/common";
import { NavigationEnd, Router } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MonitoringService } from './monitoring.service';
import { Language } from './models/language.enum';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { MissingPart } from './models/missingPart.model';
import { Page } from './models/page.enum';
import { DeviceCodeInfo } from '@azure/identity';
import settings, { AppSettings } from './appSettings';
import * as graphHelper from 'src/app/graphHelper';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy{
  apiUrl:string =  environment.API_URL;
  title = 'DeMO-Frontend';
  param = {value: 'world'};

  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  
  translations:any = [];

  //Notification
  private hubConnection:HubConnection | null = null;
  popupMessageNumber = 0;
  popupNotificationMessage = "";
  popupNotificationTitle = "";
  popupVisibleOnPage:Page | null = null;

  //Loading
  appFullyLaunched = false;
  
  @ViewChild(HeaderComponent) headerElement!:HeaderComponent;

  constructor(
    public translate: TranslateService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private httpClient:HttpClient) {
    
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None), takeUntil(this._destroying$)).subscribe(() => {
        this.setLoginDisplay();
        this.setActiveAccount();
        this.setLangs();
        this.initializeGraph(settings);
        this.reloadPage();
        this.appFullyLaunched = true;
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(
      (event:any) => {
        // Do something when the route changes
        this.popupMessageNumber = 0;
        this.popupNotificationMessage = "";
        this.popupNotificationTitle = "";
        this.popupVisibleOnPage = null;
      }
    );
  }

  setLangs(){
    //LANGUAGE SETTING
    this.translate.addLangs([Language.English, Language.French, Language.Dutch]);

    const urlTranslation:string = this.apiUrl + 'Translation/';

    //if previously setted lang
    if(localStorage.getItem('language')){
      const tmp:string = localStorage.getItem('language') || "";
      this.httpClient.get(urlTranslation + tmp).subscribe(
        res => {
          this.translate.setTranslation(tmp, res);
          this.translate.setDefaultLang(tmp);
          this.translate.use(tmp);
        }
      );
    //else lang of the navigator
    } else if(this.translate.getLangs().find(l=>l === navigator.language.split("-", 1)[0])){
      const tmp:string = navigator.language.split("-", 1)[0];
      this.httpClient.get(urlTranslation + tmp).subscribe(
        res => {
          this.translate.setTranslation(tmp, res);
          this.translate.setDefaultLang(tmp);
          this.translate.use(tmp);
          localStorage.setItem("language",tmp);
        }
      );
    //else english by default
    } else {
      this.httpClient.get(urlTranslation + 'en').subscribe(
        res => {
          this.translate.setTranslation('en', res);
          this.translate.setDefaultLang('en');
          this.translate.use('en');
          localStorage.setItem("language",'en');
        }
      );
    }
  }

  setLanguage(lang:string){
    this.httpClient.get(this.apiUrl + 'Translation/' + lang).subscribe(
      res => {
        this.translate.setTranslation(lang, res);
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
        localStorage.setItem("language",lang);
      }
    );
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    console.log("app.component - list get all accounts", this.authService.instance.getAllAccounts());
  }

  setActiveAccount(){
    if(this.loginDisplay){
      this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
    }
  }

  reloadPage(){
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
    this.headerElement?.getAuthorizedFactories();
    this.setupSignalRConnection();
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        if (this.msalGuardConfig.authRequest) {
          this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
            .subscribe((response: AuthenticationResult) => {
              this.authService.instance.setActiveAccount(response.account);
            });
        } else {
          this.authService.loginPopup()
            .subscribe((response: AuthenticationResult) => {
              this.authService.instance.setActiveAccount(response.account);
            });
        }
      } else {
        if (this.msalGuardConfig.authRequest) {
          this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
          this.authService.loginRedirect();
        }
      }
  }
  logout() {
    this.authService.logout();
  }

  setupSignalRConnection() {
    this.authService.acquireTokenSilent({
      scopes: [environment.SCOPES],
    }).subscribe(tokenResponse => {
      // Build the SignalR connection with the access token
      const options: IHttpConnectionOptions = {
        accessTokenFactory: () => tokenResponse.accessToken,
      };

      this.hubConnection = new HubConnectionBuilder()
        .withUrl(this.apiUrl.slice(0, -4) + "NotificationHub", options)
        .withAutomaticReconnect([30000, 120000, 240000, 480000])
        .configureLogging(signalR.LogLevel.Information)
        .withHubProtocol(new signalR.JsonHubProtocol())
        .build();
  
      this.hubConnection.start().then(() => {
        console.log('SignalR connection started');
      });
      
      // Receive notifications from the hub
      this.hubConnection.on('NewMissingPartNotification', (mp:MissingPart) => {
        console.log('Received NewMissingPartNotification:', mp);
        // Handle the notification in your Angular app
        if(mp.factoryId === this.getCurrentFactory()){
          this.popupNotificationTitle = 'NEW MISSING PART ADDED';
          this.popupNotificationMessage = 'NEW MISSING PART ADDED MESSAGE';
          this.popupMessageNumber++;
          this.popupVisibleOnPage = Page.MissingPart;
          this.showWindowsNotification(this.popupNotificationTitle, this.popupNotificationMessage);
        }
      });
  
      // Receive notifications from the hub
      this.hubConnection.on('DeviationLinkedToMissingPartNotification', (mp:MissingPart) => {
        console.log('Received DeviationLinkedToMissingPartNotification:', mp);
        // Handle the notification in your Angular app
        if(mp.factoryId === this.getCurrentFactory()){
          this.popupNotificationTitle = 'DEVIATION LINKED TO MISSING PART';
          this.popupNotificationMessage = 'DEVIATION LINKED TO MISSING PART MESSAGE';
          this.popupMessageNumber++;
          this.popupVisibleOnPage = Page.MissingPart;
          this.showWindowsNotification(this.popupNotificationTitle, this.popupNotificationMessage);
        }
      });

       // Receive notifications from the hub
       this.hubConnection.on('RefreshPageNotification', () => {
        console.log('Received RefreshPageNotification');
        // Handle the notification in your Angular app
        this.popupNotificationTitle = 'APP UPDATE AVAILABLE';
        this.popupNotificationMessage = 'APP UPDATE AVAILABLE MESSAGE';
        this.popupMessageNumber++;
        this.popupVisibleOnPage = null;
      });

      // Receive test notifications from the hub
      this.hubConnection.on('TestNotification', () => {
        console.log('Received TestNotification' + this.getCurrentFactory());
        // Handle the notification in your Angular app
        this.popupNotificationTitle = 'TEST NOTIFICATION';
        this.popupNotificationMessage = 'TEST NOTIFICATION MESSAGE';
        this.popupMessageNumber++;
        this.popupVisibleOnPage = Page.MissingPart;
        this.getCurrentFactory()
        this.showWindowsNotification(this.popupNotificationTitle, this.popupNotificationMessage);
        
      });
    });
  }

  showWindowsNotification(title:string, message:string) {
    if(localStorage.getItem("browserNotificationsEnabled") == null || localStorage.getItem("browserNotificationsEnabled") === "false") { return; }
    
    if ('Notification' in window) {
      // Request permission to show notifications
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          this.translate.stream(title).subscribe((titleTranslation:string) => {
            this.translate.stream(message).subscribe((messageTranslation:string) => {
              const notification = new Notification(titleTranslation, {
                body: messageTranslation,
                icon: '../assets/images/demo-icon.png'
              });
            });
          });
        }
      });
    }
  }

  isActivePage(page:Page | null){
    if(page == null) { return true };

    if((this.router.url.includes('deviations-list') && page === Page.Deviation) || (this.router.url.includes('missing-parts') && page === Page.MissingPart) || (this.router.url.includes('suppliers') && page === Page.Suppliers)){
      return true;
    }   
    
    return false;
  }

  getCurrentFactory():number{
    return Number(this.router.url.split('/', 4)[2]);
  }

  initializeGraph(settings: AppSettings) {
    graphHelper.initializeGraphForUserAuth(settings, (info: DeviceCodeInfo) => {
      console.log(info.message);
    });
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    if (this.hubConnection != null && this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.hubConnection.stop().then(() => {
        console.log('SignalR connection closed');
      });
    }
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
