<svg class="close-cross" (click)="close()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062"><path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/><path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/></svg>


<mat-select id="image-pool" [(ngModel)]="selectedImage" (selectionChange)="selectImage()">
    <mat-option *ngFor="let image of imagePool" [value]="image.FileName">{{image.FileName.substring(0, image.FileName.lastIndexOf('.'))}}</mat-option>
</mat-select>

<mat-spinner class="field-spinner"></mat-spinner>

<div class="image-container">
    <img class="images location-img" [src]="imageUrl" (click)="addPin($event)" *ngIf="imageUrl != ''" alt="location-img"/>
    <div [innerHTML]="svgContent" *ngIf="svgContent != null" (click)="addPin($event)"></div>
    <mat-icon class="mat-icon-rtl-mirror pin" *ngFor="let location of getLocationsOfImage()" [ngStyle]="{'top.px':location.y - 15, 'left.px':location.x - 15}" (click)="removePin(location)">{{'location_on'}}</mat-icon>
</div>

<div class="previous-button-container">
    <mat-icon class="mat-icon-rtl-mirror" (click)="selectPreviousImage()">{{'navigate_before'}}</mat-icon>
</div>
<div class="next-button-container">
    <mat-icon class="mat-icon-rtl-mirror" (click)="selectNextImage()">{{'navigate_next'}}</mat-icon>
</div>

<app-demo-button 
    text="{{ 'BACK' | translate}}"
    (clickEvent)="close()"
    color="reverse-grey"
    svg="arrow_back">
</app-demo-button>