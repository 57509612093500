<div class="popup-container component-solve-popup" [ngClass]="{'active' : isPopupOpen}">
    <div class="popup">
    
        <div class="close-container">
            <svg (click)="closePopUp()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062">
                <path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/>
                <path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/>
            </svg>
        </div>

        <div class="solve-popup">

            <span class="title">{{ 'SOLVE DEVIATION' | translate }}</span>
            <span class="selected-product">{{getProductFullName(selectedProduct!)}}</span>
            <span class="text">{{ 'SOLVE DEVIATION TEXT' | translate }}<br>{{ 'SOLVE DEVIATION CONFIRM' | translate }}</span>
            <!--OPERATOR ID-->
            <app-operator-scanning 
                [currentFactoryId]="currentFactoryId"
                [allowUnknownOperator]="allowUnknownOperator"
                [triggerRememberMeEffect] = "isSavingData"
                *ngIf="operatorScanningEnabled && isPopupOpen" 
                (userScannedEvent)="userScanned = $event"
                (isScanningEvent)="isScanning = $event">
            </app-operator-scanning>
            <!--FORM SOLVE-->
            <form [formGroup]="solveForm">
                <div class="field-container">
                    <label for="solver">{{ 'SOLVER' | translate }}* <app-notification-message></app-notification-message></label>
                    <input #firstSolveInput type="text" id="solver" class="form-control" formControlName="solver" autocomplete="off" required>
                </div>
                <div class="field-container">
                    <label for="solverId">{{ 'SOLVER ID' | translate }}* <app-notification-message></app-notification-message></label>
                    <input type="text" id="solverId" class="form-control" formControlName="solverId" autocomplete="off" required>
                </div>
                <div class="field-container">
                    <label for="solveComment">{{ 'SOLVE COMMENT' | translate }} <app-notification-message></app-notification-message></label>
                    <input type="text" id="solveComment" class="" formControlName="solveComment" autocomplete="off">
                </div>
                <app-demo-button 
                    text="{{ 'SOLVE' | translate }}"
                    (clickEvent)="solve()"
                    [disable]="!solveForm.valid || isSavingData || !hasIdentifiedUser()"
                    color="tertiary"
                    [tooltipText]="!hasIdentifiedUser() ? 'MISSING OPERATOR MESSAGE' : '' | translate"
                    tabindex="0"
                    class="centered"
                    id="solve-popup-button">
                </app-demo-button>
            </form>
            <div class="spinner-container" *ngIf="isSavingData">
                <mat-spinner></mat-spinner>
            </div>
        </div>

    </div>
</div>

