import { Component } from '@angular/core';

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.less']
})
export class NotificationMessageComponent {
  
}
