import { ErrorHandler, Injectable } from '@angular/core';
import { MonitoringService } from './monitoring.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private monitoringService: MonitoringService) {
        super();
    }

    override handleError(error: Error) {
        this.monitoringService.logException(error); // Manually log exception
        const originalError = this.getOriginalError(error);
        if (originalError !== error) {
            this.monitoringService.logException(originalError); // Manually log original exception
        }
    }

    private getOriginalError(error: any) {
        while (error && error.originalError) {
            error = error.originalError;
        }
        return (error);
    }
}