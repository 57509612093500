import { Pipe, PipeTransform } from "@angular/core";
import { FunctionGroup } from "../models/functionGroup.model";

@Pipe({
    name:'filterSelectFunction'
})
export class FilterSelectFunctionPipe implements PipeTransform{
    transform(functions: FunctionGroup[], searchText:string) {
        if(functions == null || functions.length === 0 || searchText === ''){
            return functions;
        } else {
            return functions.filter((f) => 
            {
                return f.code.concat(' ').concat(f.nameEN || "").toLowerCase().includes(searchText.toLowerCase())
            }) 
        }
    }
}