import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-demo-card',
  templateUrl: './demo-card.component.html',
  styleUrls: ['./demo-card.component.less']
})
export class DemoCardComponent implements OnInit {

  @Input() type = "big";
  @Input() text = "Card Text";
  @Input() image = "";

  constructor() { }

  ngOnInit(): void {
  }

}
