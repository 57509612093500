<div class="related-element list-vehicles" *skeleton="selectedSupplier == null; height: '164px'; width: '100%'; margin:'0 auto 0px';">
    <div class="top-part">
        <span class="title">{{"LIST OF VEHICLES" | translate}}</span>
    </div>
    <div class="vehicle free-text-suggestions"> 
        <input #addStakeholderInput type="text" id="partId" class="form-control" [(ngModel)]="vehicleFilter" placeholder="{{'ADD A VEHICLE' | translate}}" (focus)="addVehicleInputFocus()" (blur)="inputVehiclesFocused = false" (keyup.enter)="addVehicle()" (ngModelChange)="errorMessageInSearch = ''" autocomplete="off">
        <ul class="suggestions" [ngClass]="{'active': inputVehiclesFocused}">
            <li (mousedown)="searchVehicleOutOfTheList()" *ngIf="vehicleFilter != '' && filteredVehicleList().length == 0"><mat-icon class="mat-icon-rtl-mirror">{{'search'}}</mat-icon>{{"SEARCH" | translate}}</li>
            <li *ngFor="let vehicle of filteredVehicleList()" (mousedown)="addVehicle(vehicle)">
                <strong *ngIf="vehicle.chassisSeries != '' || vehicle.chassisNumber != ''">{{vehicle.chassisSeries}}{{vehicle.chassisNumber}}</strong>
                <mat-icon class="mat-icon-rtl-mirror">{{'add_circle'}}</mat-icon>
            </li>
            <li *ngIf="errorMessageInSearch != ''">{{errorMessageInSearch | translate}}</li>
        </ul>
    </div>
    <div class="vehicle" *ngFor="let v of selectedSupplier?.impactedProducts"> 
        <span><strong>{{v.product?.chassisSeries}}{{v.product?.chassisNumber}}</strong></span>
        <mat-icon class="mat-icon-rtl-mirror" (click)="openRemoveVehiclePopUp(v)">{{'do_not_disturb_on'}}</mat-icon>
    </div>
</div>

<app-single-action-popup
            [isPopupOpen]="vehicleToRemove != null"
            title="{{'REMOVE THIS IMPACTED VEHICLE' | translate}}"
            description="{{'REMOVE IMPACTED VEHICLE MESSAGE' | translate}}"
            actionText="{{'REMOVE' | translate}}"
            (closePopupEvent)="closePopup()"
            (actionPopupEvent)="removeVehicle()">
</app-single-action-popup>
