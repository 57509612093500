import { Pipe, PipeTransform } from "@angular/core";
import * as Diacritics from 'diacritics';
import { SupplierQuality } from "../models/supplierQuality.model";

@Pipe({
    name:'searchSupplierQuality'
})
export class SearchSupplierQualityPipe implements PipeTransform{
    transform(sqs: SupplierQuality[], searchText:string) {
        if(sqs == null || sqs.length === 0 || searchText === ''){
            return sqs;
        } else {
            return sqs.filter((sq) => 
            {
                return this.notSensitive(sq.partId).includes(this.notSensitive(searchText)) || 
                       this.notSensitive(sq.part?.rviAlias).includes(this.notSensitive(searchText)) ||
                       this.notSensitive(sq.title).includes(this.notSensitive(searchText))
                       ||
                       this.notSensitive(sq.ownerName).includes(this.notSensitive(searchText)) || 
                       this.notSensitive(sq.ownerEmployeeId).includes(this.notSensitive(searchText)) ||
                       this.notSensitive(sq.ownerEmail).includes(this.notSensitive(searchText))
            }) 
        }
    }

    notSensitive(s:string | null | undefined){
        if(s==null){ return "";}
        return Diacritics.remove(s).toLowerCase();
    }
}