import { Pipe, PipeTransform } from "@angular/core";
import { MissingPart } from "../models/missingPart.model";
import * as Diacritics from 'diacritics';

@Pipe({
    name:'searchMissingPartId'
})
export class SearchMissingPartPipe implements PipeTransform{
    transform(products: MissingPart[], searchText:string) {
        if(products == null || products.length === 0 || searchText === ''){
            return products;
        } else {
            return products.filter((product) => 
            {
                return this.notSensitive(product.partId).includes(this.notSensitive(searchText)) || 
                       this.notSensitive(product.part?.rviAlias).includes(this.notSensitive(searchText)) ||
                       this.notSensitive(product.title).includes(this.notSensitive(searchText))
                       ||
                       this.notSensitive(product.ownerName).includes(this.notSensitive(searchText)) || 
                       this.notSensitive(product.ownerEmployeeId).includes(this.notSensitive(searchText)) ||
                       this.notSensitive(product.ownerEmail).includes(this.notSensitive(searchText))
            }) 
        }
    }

    notSensitive(s:string | null | undefined){
        if(s==null){ return "";}
        return Diacritics.remove(s).toLowerCase();
    }
}