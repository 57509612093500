import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DeviationHistory } from 'src/app/models/deviationHistory.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import settings, { AppSettings } from 'src/app/appSettings';
import * as graphHelper from 'src/app/graphHelper';
import { DeviceCodeInfo } from '@azure/identity';

@Component({
  selector: 'app-design-page',
  templateUrl: './design-page.component.html',
  styleUrls: ['./design-page.component.less']
})
export class DesignPageComponent implements OnInit {

  apiUrl:string =  environment.API_URL;

  i18nTest:string=$localize`:@@i18nTest:Test EN`;
  testImg:string="placeholder-cards.png";

  workcells = ["UEP1", "UEP2", "Assembly L2", "UEP3"];

  constructor(private httpClient:HttpClient) { }

  ngOnInit(): void {
    // Initialize Graph
    this.initializeGraph(settings);
  }

  onDrop(event: CdkDragDrop<string []>){
    moveItemInArray(
      event.container.data, event.previousIndex, event.currentIndex
    );
  }

  getTestNotification(){
    this.httpClient.get<any>(this.apiUrl + 'Notification/Test').subscribe(
      {
        next: (res) => {
          
        },
        error: (e) => {
          
        }
      }
    );
  }

  getTestMSGraph(){
    console.log("in get test msgraph", settings)

    // Greet the user by name
    this.greetUserAsync();

    this.getUsersAsync();
    try {
      console.log("try get users")
      const userDetails = graphHelper.getUserEmail("cedric.fromont@volvo.com");
      console.log(userDetails);
    } catch (error) {
      throw error;
    }
  }

  // <InitializeGraphSnippet>
  initializeGraph(settings: AppSettings) {
    console.log("in initializegraph")
    graphHelper.initializeGraphForUserAuth(settings, (info: DeviceCodeInfo) => {
      // Display the device code message to
      // the user. This tells them
      // where to go to sign in and provides the
      // code to use.
      console.log("has initialize")
      console.log(info.message);
    });
  }
  // </InitializeGraphSnippet>

  // <GreetUserSnippet>
  async greetUserAsync() {
    console.log("in get greetuserasync")
    try {
      const user = await graphHelper.getUserAsync();
      console.log(`Hello, ${user?.displayName}!`);
      // For Work/school accounts, email is in mail property
      // Personal accounts, email is in userPrincipalName
      console.log(`Email: ${user?.mail ?? user?.userPrincipalName ?? ''}`);
    } catch (err) {
      console.log(`Error getting user: ${err}`);
    }
  }
  // </GreetUserSnippet>

    // <GreetUserSnippet>
    async getUsersAsync() {
      console.log("in get getusersasync")
      try {
        const user = await graphHelper.getUsers();
        console.warn("result", user)
      } catch (err) {
        console.log(`Error getting user: ${err}`);
      }
    }
    // </GreetUserSnippet>

  getUserMe(){
    try {
      const userDetails = graphHelper.getUserAsync();
      console.log(userDetails);
    } catch (error) {
      throw error;
    }
  }

}
