import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.less']
})
export class MessagePopupComponent {
  @Input() type = ""; //error, success, void for default
  @Input() title = "No text"; //any string you want
  @Input() messageNumber = 1; //number of messages 
  @Input() content = "No text"; //any string you want
  @Input() reloadPageBtn = false; //display button reload
  @Input() closeOnClick = false; //close component when clicked

  @Output() closeEvent = new EventEmitter<any>();

  close(){
    this.closeEvent.emit(); 
  }

  clickOnComponent(){
    if(this.closeOnClick){
      this.close();
    }
  }

  reloadPage(){
    window.location.reload();
    this.close();
  }
}

