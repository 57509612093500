export class BaseStakeholder {

    name:string | null;
    employeeId:string | null;
    email:string | null;
    id?:number;

    constructor(
        name:string | null,
        employeeId:string | null,
        email:string | null,
        id?:number
    ) {
        this.name = name;
        this.employeeId = employeeId;
        this.email = email;
        this.id = id;
    }
}