import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-single-action-popup',
  templateUrl: './single-action-popup.component.html',
  styleUrl: './single-action-popup.component.less'
})
export class SingleActionPopupComponent {
  @Input() isPopupOpen = false;
  @Input() title = "";
  @Input() description= "";
  @Input() actionText= "";

  @Output() closePopupEvent = new EventEmitter<any>();
  @Output() actionPopupEvent = new EventEmitter<any>();

  isSavingData = false;

  closePopUp(){
    this.isPopupOpen = false;
    this.closePopupEvent.emit();
  }

  triggerMainAction(){
    this.actionPopupEvent.emit();
  }

  triggerClosePopup(){
    this.closePopupEvent.emit();
  }
}
