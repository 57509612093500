import { ProductionLine } from "./productionLine.model";

export class MissingPartImpactedProductionLine {

    missingPartId:number;
    productionLineId:number;
    productionLine:ProductionLine | null;

    constructor(
        missingPartId:number,
        productionLineId:number,
        productionLine:ProductionLine | null) {

        this.missingPartId = missingPartId;
        this.productionLineId = productionLineId;
        this.productionLine = productionLine;
    }
}