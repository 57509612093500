<div class="popup-container" [ngClass]="{'active' :isPopupOpen}">
    <div class="popup">
    
        <div class="close-container">
            <svg (click)="closePopUp()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062">
                <path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/>
                <path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/>
            </svg>
        </div>

    <!--DEVIATION-->
    <div class="deviation-popup" *ngIf="isPopupOpen" id="deviation-popup-content">

        <div class="head">
            <div class="head-left">
                <span class="title">{{ getPopUpTitle() | translate }}</span>
                <div class="head-product">
                    <span class="selected-product">{{getProductFullName(selectedProduct!)}}</span>
                </div>
            </div>
            <div class="deviation-dates" *ngIf="selectedDeviation != null">
                <span class="">{{selectedDeviation.displayId}} </span>
                <span class="tooltip-container">{{ 'CREATED' | translate }} {{ 'BY' | translate }} {{selectedDeviation.creator | slice:0:12}}... : {{selectedDeviation.detectionDate | demoDate}}
                    <div class="tooltip-content">
                        <span class="">{{ 'CREATED' | translate }} {{ 'BY' | translate }} {{selectedDeviation.creator}} {{selectedDeviation.creatorEmployeeId}}</span>
                    </div>
                </span>
                <span class="tooltip-container" *ngIf="selectedDeviation.resolutionDate != null"> {{ 'SOLVED' | translate }} {{ 'BY' | translate }} {{selectedDeviation.solver | slice:0:12 }}... : {{selectedDeviation.resolutionDate | demoDate}}
                    <div class="tooltip-content">
                        <span class="">{{ 'SOLVED' | translate }} {{ 'BY' | translate }} {{selectedDeviation.solver}} {{selectedDeviation.solverEmployeeId}}</span>
                    </div>
                </span>
                <mat-icon class="mat-icon-rtl-mirror history-btn" *ngIf="selectedDeviation != null" (click)="showHistory()">{{'history'}}</mat-icon>
            </div>
        </div>
            
        <app-demo-button text="{{ getShowHistoryText() | translate }}" color="reverse-secondary" type="btn-small" (clickEvent)="showHistory()" *ngIf="showDeviationHistory"></app-demo-button>
        
        <!--DEVIATION HISTORY-->
        <div *ngIf="showDeviationHistory" style="width: 100%;">
            <div class="history-container" *skeleton="loadingDeviationHistory; repeat: 2; height: '100px'; width: 'calc(100% - 20px)'; margin:'10px';">
                <div *ngFor="let devHist of deviationHistories" class="history-element">
                    <div class="main-info">
                        <span class="property-name" *ngIf="isCreation(devHist?.actionType!)">{{"CREATED" | translate}} {{"BY" | translate}} {{devHist?.user}} {{devHist?.userEmployeeId}} <span *ngIf="devHist.operatorName != null && devHist.operatorName != ''">({{"OPERATOR ID" | translate}} {{devHist?.operatorName}}<span *ngIf="devHist?.operatorEmployeeId != null && devHist?.operatorEmployeeId != ''"> {{devHist?.operatorEmployeeId}}</span>)</span></span>
                        <span class="property-name" *ngIf="isUpdate(devHist?.actionType!)">{{getHistoryPropertyName(devHist?.propertyName!) | translate}}</span>
                        <span class="date">{{devHist?.date | demoDate}}</span>
                        <span class="user" *ngIf="isUpdate(devHist?.actionType!)">{{"MODIFIED" | translate}} {{"BY" | translate}} {{devHist?.user}} <span *ngIf="devHist.operatorName != null && devHist.operatorName != ''">({{"OPERATOR ID" | translate}} {{devHist?.operatorName}}<span *ngIf="devHist?.operatorEmployeeId != null && devHist?.operatorEmployeeId != ''"> {{devHist?.operatorEmployeeId}}</span>)</span></span>
                    </div>

                    <span class="old-value" *ngIf="isUpdate(devHist?.actionType!)">{{"FROM" | translate}} {{translateHistoryValue(devHist?.propertyName!) ? (getHistoryValue(devHist?.oldValue!, devHist?.propertyName!) | translate) : getHistoryValue(devHist?.oldValue!, devHist?.propertyName!)}} </span>
                    <span class="new-value" *ngIf="isUpdate(devHist?.actionType!) && !isHistoryValueDate(devHist?.propertyName!)">{{"TO" | translate}} {{translateHistoryValue(devHist?.propertyName!) ? (getHistoryValue(devHist?.newValue!, devHist?.propertyName!) | translate) : getHistoryValue(devHist?.newValue!, devHist?.propertyName!)}}</span>
                    <span class="new-value" *ngIf="isUpdate(devHist?.actionType!) && isHistoryValueDate(devHist?.propertyName!)">{{"TO" | translate}} {{getHistoryValue(devHist?.newValue!, devHist?.propertyName!) | demoDate}}</span>
                </div>
                <span *ngIf="errorMessage != ''">{{errorMessage | translate}}</span>
            </div>
        </div>

        <!--OPERATOR ID-->
        <app-operator-scanning 
            [currentFactoryId]="currentFactoryId" 
            [allowUnknownOperator]="allowUnknownOperator" 
            *ngIf="operatorScanningEnabled && !showDeviationHistory" 
            (userScannedEvent)="userScanned = $event"
            (isScanningEvent)="isScanning = $event">
        </app-operator-scanning>

        <!--DEVIATION FORM-->
        <form [formGroup]="deviationForm" (ngSubmit)="onDeviationSubmit()" (keydown.enter)="$event.preventDefault()" *ngIf="!showDeviationHistory">
            <div class="top-form-part">
                <!--DETECTION WORKCELL-->
                <div class="field-container" [ngClass]="{'full-width':!locationEnabled}">
                    <mat-label>{{ 'DETECTION WORKCELL' | translate }} *</mat-label>
                    <mat-select #firstDeviationInput id="detection-workcell" formControlName="detectionWorkcell" name="detection-workcell" required (selectionChange)="setDeviationLists()">
                        <mat-option *ngIf="selectedDeviation != null && !isSelectedDWInList()" [value]="selectedDeviation.detectionWorkcellId">{{selectedDeviation.detectionWorkcell?.name}}</mat-option>
                        <mat-option class="disabled" *ngIf="selectedDeviation != null && isSelectedDWInList() && selectedDeviation.detectionWorkcell?.disabled" [value]="selectedDeviation.detectionWorkcellId">{{selectedDeviation.detectionWorkcell?.name}}</mat-option>
                        <ng-container *ngFor="let workcell of detectionWorkcellList">
                            <mat-option *ngIf="!workcell.disabled" [value]="workcell.id">{{workcell.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-spinner [diameter]="20" [strokeWidth]="4" class="field-spinner" *ngIf="isLoadingDetectionWorkcells"></mat-spinner>
                </div>
                <!--FAULT TYPE--> 
                <div class="field-container" [ngClass]="{'full-width':!locationEnabled}">
                    <mat-label>{{ 'FAULT TYPE' | translate }} *</mat-label>
                    <mat-select tabindex="0" id="fault-type" formControlName="faultType" required (selectionChange)="setResponsibles()" *skeleton="!faultTypesLoaded && selectedDeviation == null; height: '30px'; width: '100%';">
                        <mat-option class="disabled" *ngIf="selectedDeviation != null && (!faultTypesLoaded || selectedDeviation.faultType?.disabled)" [value]="selectedDeviation.faultTypeId"><strong>{{selectedDeviation.faultType?.rank2Code}}</strong> - {{getFaultTypeRank2Name(selectedDeviation.faultType)}}</mat-option>
                        <mat-option *ngFor="let faultType of faultTypes" [value]="faultType.id"><strong>{{faultType.rank2Code}}</strong> - {{getFaultTypeRank2Name(faultType)}}</mat-option>
                    </mat-select>
                </div>

                <!--LOCATOR-->
                <div *ngIf="locationEnabled" class="field-container-location">
                    <div class="location-toggle" [ngClass]="{'disable' :this.deviationForm.controls['detectionWorkcell'].value == null || this.deviationForm.controls['detectionWorkcell'].value == ''}" (click)="openLocatePopUp()">
                        <mat-icon class="mat-icon-rtl-mirror">{{'local_shipping'}}</mat-icon>
                        <mat-icon class="mat-icon-rtl-mirror pin-icon">{{'location_on'}}</mat-icon>
                        <span class="locations-number" *ngIf="this.selectedLocations != null && this.selectedLocations.length != 0">{{this.selectedLocations.length}}</span>
                    </div>
                </div>
            </div>

            <!-- MISSING PART -->
            <div class="line-container-missing-part" [ngClass]="{'grid-line': quantityVisible}" *ngIf="isMissingPartType()">
                <div class="field-container free-text-suggestions field-container-missing-part" *ngIf="isMissingPartType()">
                    <label for="missing-part" class="full-width">{{ 'MISSING PARTS' | translate }} * <app-notification-message *ngIf="!deviationForm.disabled"></app-notification-message></label>
                    <svg class="scan-icon" width="17" height="17" viewBox="0 0 107 95" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.39551 33.1314C4.39551 28.6855 6.16162 24.4217 9.30532 21.278C12.449 18.1343 16.7128 16.3682 21.1587 16.3682H61.8692V49.8945H21.1587C16.7128 49.8945 12.449 48.1284 9.30532 44.9847C6.16162 41.841 4.39551 37.5772 4.39551 33.1314V33.1314Z" stroke="black" stroke-width="4" stroke-linejoin="round"/><path d="M16.3684 49.8945L2 76.2366L13.9258 84.7619C14.537 85.1943 15.2319 85.4939 15.9658 85.6415C16.6998 85.7892 17.4565 85.7815 18.1873 85.6191C18.9182 85.4567 19.6069 85.1431 20.2092 84.6985C20.8116 84.254 21.3142 83.6882 21.6847 83.0377L40.3158 49.8945" stroke="black" stroke-width="4" stroke-linejoin="round"/><path d="M52.2899 49.8945V59.7129C52.2292 62.1708 51.2258 64.5112 49.4872 66.2497C47.7487 67.9882 45.4083 68.9917 42.9505 69.0524H30.7373" stroke="black" stroke-width="4" stroke-linejoin="round"/><path d="M81.0264 69.0522H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 83.4209H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 93H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 59.4736H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 45.1055H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 35.5264H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 25.9478H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 11.5791H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 2H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>        
                    <input type="text" id="missing-part" formControlName="missingParts" (focus)="inputMpFocused = true" (blur)="blurMissingPartField()" (keyup.enter)="chooseOption($event)" autocomplete="off" (keyup.arrowdown)="selectNextOption()" (keyup.arrowup)="selectPreviousOption()" (keyup.backspace)="inputMpFocused = true" (keydown.tab)="selectFirstMissingPart()" [ngClass]="{'full-width': !isSelectedMissingPartOptionFullyDisplayed()}">
                    <ul class="suggestions" [ngClass]="{'active': inputMpFocused}">
                        <li *ngFor="let mp of filteredMissingPartOptions()" (mousedown)="selectMissingPart(mp)" [ngClass]="{'to-be-selected': toBeSelectedMissingPartOption?.partId == mp.partId}"><strong>{{ mp.partId }}</strong> {{ displayRTAlias ? mp.rtAlias : "" }} {{mp.description}} {{mp.demarcation != null && mp.demarcation != "" ? "- " + mp.demarcation : ""}}</li>
                        <div class="suggestions-separator" *ngIf="filteredMissingPartOptions().length != 0"></div>
                        <li *ngFor="let mp of filteredBOMOptions()" (mousedown)="selectMissingPart(mp)" [ngClass]="{'to-be-selected': toBeSelectedMissingPartOption?.partId == mp.partId}"><strong>{{ mp.partId }}</strong> {{ displayRTAlias ? mp.rtAlias : "" }} {{mp.description}} {{mp.demarcation != null && mp.demarcation != "" ? "- " + mp.demarcation : ""}}</li>
                    </ul>
                    <span *ngIf="isSelectedMissingPartOptionFullyDisplayed()" class="part-description" title="{{selectedMissingPartOption?.demarcation}}">{{displayRTAlias ? selectedMissingPartOption?.rtAlias : ""}} {{selectedMissingPartOption?.description}} {{selectedMissingPartOption?.demarcation != null && selectedMissingPartOption?.demarcation != "" ? "- " + selectedMissingPartOption?.demarcation : ""}}</span>
                    
                    <!--Warning and errors-->
                    <mat-icon *ngIf="!isInputMissingPartInBOM" class="mat-icon-rtl-mirror warning">{{'warning'}}</mat-icon>
                    <span *ngIf="isInputMissingPartInParts && !isInputMissingPartInBOM" class="warning-message">{{"MISSING PART NOT IN THE BOM" | translate}}</span>
                    <span *ngIf="!isInputMissingPartInParts && !isInputMissingPartInBOM" class="warning-message">{{"MISSING PART NOT IN THE PARTS" | translate}}</span>
                </div>
                <!-- QUANTITY -->
                <div class="field-container" *ngIf="quantityVisible">
                    <label for="quantity">{{ 'QUANTITY' | translate }}</label>
                    <input type="number" id="quantity" class="form-control" formControlName="quantity" autocomplete="off" min="0">
                </div>
            </div>

            <!-- FUNCTION -->
            <div class="field-container">
                <mat-label>{{ 'FUNCTION' | translate }}</mat-label>
                <app-tree-data id="functionField" 
                (click)="scrollToFunction('functionField')" 
                [functionsList]="functionsView" 
                [currentSelectedFunction]="getFunctionGroup(selectedFunctionId!)" 
                [isDisabled]="deviationForm.disabled" 
                [temporaryPlaceholder]="getFunctionFullPath(selectedDeviation!)" 
                (selectEvent)="setSelectedFunction($event)"
                (fieldNotVoidEvent)="setWarning($event, 'functionField')"
                *skeleton="(!lineFunctionsLoaded || !functionsLoaded) && selectedFunctionId == null; height: '30px'; width: '100%';"></app-tree-data>
            </div>

            <!-- PROBLEM DETAIL -->
            <div class="field-container" *ngIf="problemDetailEnabled">
                <mat-label>{{ 'PROBLEM DETAIL' | translate }}</mat-label>
                <app-tree-data id="problemDetailField" 
                (click)="scrollToFunction('problemDetailField')" 
                [functionsList]="functionsView" 
                [currentSelectedFunction]="getFunctionGroup(selectedProblemDetailId!)" 
                [isDisabled]="deviationForm.disabled" 
                [temporaryPlaceholder]="getProblemDetailFullPath(selectedDeviation!)" 
                (selectEvent)="setSelectedProblemDetail($event)" 
                (fieldNotVoidEvent)="setWarning($event, 'problemDetailField')"
                *skeleton="(!lineFunctionsLoaded || !functionsLoaded) && selectedProblemDetailId == null; height: '30px'; width: '100%';"></app-tree-data>
            </div>

            <!--DUE TO / RESPONSIBLE -->
            <div class="field-container">
                <mat-label for="responsible-workcell">{{ 'RESPONSIBLE' | translate }}</mat-label>
                <app-tree-data id="responsibleField" 
                (click)="scrollToFunction('responsibleField')" 
                [responsiblesList]="responsibles"
                [currentSelectedResponsible]="getResponsibleElement(selectedResponsibleId!)"
                [isDisabled]="deviationForm.disabled" 
                [temporaryPlaceholder]="getResponsibleFullPath(selectedDeviation!)" 
                (selectEvent)="setSelectedResponsible($event)"
                (fieldNotVoidEvent)="setWarning($event, 'responsibleField')"
                *skeleton="!responsiblesLoaded && selectedDeviation == null; height: '30px'; width: '100%';"></app-tree-data>
            </div>

            <!-- SEVERITY -->
            <div class="field-container">
                <mat-label for="severity">{{ 'SEVERITY' | translate }}</mat-label>
                <mat-select id="severity" formControlName="severity">
                    <mat-option [value]=null>{{ "NONE" | translate }}</mat-option>
                    <mat-option [value]=0>0</mat-option>
                    <mat-option [value]=1>1</mat-option>
                    <mat-option [value]=5>5</mat-option>
                    <mat-option [value]=25>25</mat-option>
                    <mat-option [value]=100>100</mat-option>
                </mat-select>
            </div>
            
            <!--COMMENTS-->
            <div class="field-container form-group">
                <label for="comment">{{ 'COMMENT' | translate }} <app-notification-message *ngIf="!deviationForm.disabled"></app-notification-message></label>
                <textarea name="comment" class="form-control" cols="40" rows="1" id="comment" formControlName="comment"></textarea>
            </div>

            <!--SOLVE COMMENT-->
            <form [formGroup]="solveForm" *ngIf="selectedDeviation?.resolutionDate != null && !showDeviationHistory">
                <div class="field-container">
                    <label for="solveComment">{{ 'SOLVE COMMENT' | translate }} <app-notification-message *ngIf="!deviationForm.disabled"></app-notification-message></label>
                    <input type="text" id="solveComment" class="form-control" formControlName="solveComment" autocomplete="off">
                </div>
            </form>

            <div class="spinner-container" *ngIf="isSavingData">
                <mat-spinner></mat-spinner>
            </div>
            
            <div class="button-container">
                <app-demo-button text="{{ 'CANCEL' | translate }}" color="reverse-grey" (clickEvent)="closePopUp()"></app-demo-button>
                <div class="save-button-container">
                    <app-demo-button 
                        text="{{ 'EDIT AND CONFIRM DEVIATION' | translate}}"
                        (clickEvent)="saveAndConfirmDeviation()"
                        [disable]="!deviationForm.valid || isSavingData || !hasIdentifiedUser() || !isFormValid"
                        color="reverse-tertiary"
                        svg="save"
                        [tooltipText]="getValidateFormTooltipText() | translate"
                        *ngIf="canActivateSaveAndConfirm && !isFormDisabled && selectedDeviation != null && selectedDeviation.missingPartStatus == 0 && roleAuth?.validateMissingPart && this.selectedDeviation.missingParts == this.deviationForm.value.missingParts" tabindex="0">
                    </app-demo-button>
                    <app-demo-button 
                        text="{{(selectedDeviation == null ? 'SAVE AND SOLVE DEVIATION': 'EDIT AND SOLVE DEVIATION') | translate}}"
                        (clickEvent)="saveAndSolveDeviation()"
                        [disable]="!deviationForm.valid || isSavingData || !hasIdentifiedUser() || !isFormValid"
                        color="reverse-tertiary"
                        svg="save"
                        [tooltipText]="getValidateFormTooltipText() | translate"
                        *ngIf="canActivateSaveAndSolve && !isFormDisabled && roleAuth?.solveDeviation && selectedDeviation?.resolutionDate == null" tabindex="0">
                    </app-demo-button>

                    <app-demo-button 
                        text="{{(selectedDeviation == null ? 'SAVE DEVIATION': 'EDIT DEVIATION') | translate}}"
                        (clickEvent)="onDeviationSubmit()"
                        [disable]="!deviationForm.valid || isSavingData || !hasIdentifiedUser() || !isFormValid"
                        color="tertiary"
                        svg="save"
                        [tooltipText]="getValidateFormTooltipText() | translate"
                        *ngIf="!isFormDisabled" tabindex="0">
                    </app-demo-button>
                </div>
            </div>
        </form>
    </div>

    </div>
</div>

<app-locate-popup 
    [ngClass]="{'active' : showLocatePopUp}"
    [selectedDeviation]="selectedDeviation"
    [productDeviations]="selectedProduct?.deviations"
    [selectedLocations]="selectedLocations"
    [selectedWorkcellId]="this.deviationForm.controls['detectionWorkcell'].value"
    [disable]="deviationForm.disabled || isSavingData"
    [openPopup]="showLocatePopUp"
    (closeEvent)='closeLocatePopUp($event)'>
</app-locate-popup>