<div class="page-container" [ngClass]="{'no-product-list' :!isProductListOpen}">
    <div class="not-autorized" *ngIf="roleAuth != null && !roleAuth.readMissingPart">
        <div class="message">
            <span>{{"NO ACCESS RIGHTS MESSAGE" | translate}}</span>
        </div>
    </div>

    <div class="close-btn" (click)="toggleProductList()" [ngClass]="{'opens' :!isProductListOpen}" *ngIf="roleAuth == null || roleAuth?.readMissingPart">
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.7199 0.862854C15.2502 0.862854 15.6799 1.29263 15.6799 1.82285C15.6799 2.35308 15.2502 2.78285 14.7199 2.78285H1.27995C0.749718 2.78285 0.319946 2.35308 0.319946 1.82285C0.319946 1.29263 0.749718 0.862854 1.27995 0.862854H14.7199ZM14.7199 6.04685C15.2502 6.04685 15.6799 6.47663 15.6799 7.00685C15.6799 7.53708 15.2502 7.96685 14.7199 7.96685H1.27995C0.749718 7.96685 0.319946 7.53708 0.319946 7.00685C0.319946 6.47663 0.749718 6.04685 1.27995 6.04685H14.7199ZM14.7199 11.2309C15.2502 11.2309 15.6799 11.6606 15.6799 12.1909C15.6799 12.7211 15.2502 13.1509 14.7199 13.1509H1.27995C0.749718 13.1509 0.319946 12.7211 0.319946 12.1909C0.319946 11.6606 0.749718 11.2309 1.27995 11.2309H14.7199Z" fill="#333333"/>
        </svg>
    </div>

    <div class="list-container" *ngIf="roleAuth == null || roleAuth?.readMissingPart" [ngClass]="{'active' :isProductListOpen}">
        <div class="title-container" cdkTrapFocus>
            <span class="title">{{ 'MISSING PARTS' | translate }}</span>
            <div class="search-container">
                <input #search tabindex="0" id="search" type="text" [(ngModel)]="searchText" name="search" autocomplete="off" placeholder="{{'SEARCH' | translate}}" (focus)="searchIsFocus = true" (blur)="searchIsFocus = false">
                <svg width="17" height="17" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M40.0166 78.0332C61.0126 78.0332 78.0332 61.0126 78.0332 40.0166C78.0332 19.0206 61.0126 2 40.0166 2C19.0206 2 2 19.0206 2 40.0166C2 61.0126 19.0206 78.0332 40.0166 78.0332Z" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M72.6552 61.144L97.6376 86.1264C99.15 87.6608 99.9978 89.7287 99.9978 91.8832C99.9978 94.0377 99.15 96.1056 97.6376 97.64C96.1032 99.1524 94.0352 100 91.8808 100C89.7263 100 87.6583 99.1524 86.124 97.64L61.1416 72.6577" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>

        <div class="sorting">
            <div class="tooltip-container relative">
                <svg  (click)="setFilter(0)" (keyup.enter)="setFilter(0)" [ngClass]="{'active' :filters.includes(0)}" class="potential" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabindex="0"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>        
                <div class="tooltip-content display-top">{{"POTENTIAL" | translate}}</div>
            </div>
            <div class="tooltip-container relative">
                <svg (click)="setFilter(1)" (keyup.enter)="setFilter(1)" [ngClass]="{'active' :filters.includes(1)}" class="to-be-confirmed" height="16" viewBox="0 0 92 102" fill="none" xmlns="http://www.w3.org/2000/svg" tabindex="0"><path d="M34.6525 85.3682C34.5043 86.1313 34.4267 86.9065 34.4209 87.684C34.4209 90.7549 35.6408 93.7 37.8123 95.8715C39.9838 98.043 42.9289 99.2629 45.9998 99.2629C49.0708 99.2629 52.0159 98.043 54.1874 95.8715C56.3589 93.7 57.5788 90.7549 57.5788 87.684C57.573 86.9065 57.4954 86.1313 57.3472 85.3682" stroke-linecap="round" stroke-linejoin="round"/><path d="M90 85.3684H2C18.2105 85.3684 18.2105 43.6842 18.2105 43.6842C18.2189 37.5956 20.2266 31.6782 23.9252 26.8416C27.6237 22.005 32.8084 18.517 38.6821 16.9137C37.408 15.3073 36.7216 13.3134 36.7368 11.2632C36.7368 8.80642 37.7128 6.45029 39.45 4.71312C41.1871 2.97594 43.5433 2 46 2C48.4567 2 50.8129 2.97594 52.55 4.71312C54.2872 6.45029 55.2632 8.80642 55.2632 11.2632C55.2784 13.3134 54.592 15.3073 53.3179 16.9137C59.1916 18.517 64.3763 22.005 68.0748 26.8416C71.7734 31.6782 73.7811 37.5956 73.7895 43.6842C73.7895 43.6842 73.7895 85.3684 90 85.3684Z" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <div class="tooltip-content display-top">{{"TO BE CONFIRMED" | translate}}</div>
            </div>
            <div class="tooltip-container relative">
                <svg (click)="setFilter(2)" (keyup.enter)="setFilter(2)" [ngClass]="{'active' :filters.includes(2)}" class="in-progress" height="16" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg" tabindex="0"><path d="M56.15 110.3C86.0562 110.3 110.3 86.0562 110.3 56.15C110.3 26.2438 86.0562 2 56.15 2C26.2438 2 2 26.2438 2 56.15C2 86.0562 26.2438 110.3 56.15 110.3Z" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path class="primary-stroke" d="M56.1504 21.6909V56.15" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/><path class="primary-stroke" d="M56.1503 56.1499H31.5366" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <div class="tooltip-content display-top">{{"IN PROGRESS" | translate}}</div>
            </div>
            <div class="tooltip-container relative">
                <svg (click)="setFilter(3)" (keyup.enter)="setFilter(3)" [ngClass]="{'active' :filters.includes(3)}" class="closed" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" tabindex="0"><path d="M9.9931 0.777145C4.9051 0.777145 0.7771 4.90515 0.7771 9.99314C0.7771 15.0811 4.9051 19.2091 9.9931 19.2091C15.0811 19.2091 19.2091 15.0811 19.2091 9.99314C19.2091 4.90515 15.0811 0.777145 9.9931 0.777145ZM14.7355 7.76595L9.3211 13.1995C9.14833 13.3723 8.8987 13.4875 8.64903 13.4875C8.39936 13.4875 8.14987 13.3915 7.97696 13.1995L5.2507 10.4731C4.86673 10.0892 4.86673 9.49398 5.2507 9.10988C5.63467 8.72591 6.22987 8.72591 6.61397 9.10988L8.64913 11.145L13.3915 6.40264C13.7755 6.01868 14.3707 6.01868 14.7548 6.40264C15.1195 6.78675 15.1195 7.38191 14.7355 7.76591L14.7355 7.76595Z" fill="#00BA5E"/></svg>
                <div class="tooltip-content display-top">{{"CLOSED" | translate}}</div>
            </div>
        </div>

        <span *ngIf="errorMessage != ''" class="error-message">{{errorMessage | translate}}</span>
    
        <div class="list-vi" *skeleton="filteredMissingParts == null; repeat: 10; height: '20px'; width: 'calc(100% - 20px)'; margin:'10px'" tabindex="0">
            <div *ngFor="let mp of filteredMissingParts | searchMissingPartId: searchText">
            <div class="vi" [ngClass]="{'active' :selectedMissingPart?.id === mp.id}" (click)="selectMissingPart(mp)" appTooltip>
                <span *ngIf="!currentFactory.displayRTAlias || mp.part == null" appTooltip><strong>{{mp.partId}}</strong> {{mp.title}}</span>
                <span *ngIf="mp.part != null && currentFactory.displayRTAlias" appTooltip><strong>{{mp.part.rviAlias}}</strong> {{mp.title}}</span>

                <svg *ngIf="mp?.missingPartStatus == 0" class="potential" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>        
                <svg *ngIf="mp?.missingPartStatus == 1" class="to-be-confirmed" height="16" width="16" viewBox="0 0 92 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M34.6525 85.3682C34.5043 86.1313 34.4267 86.9065 34.4209 87.684C34.4209 90.7549 35.6408 93.7 37.8123 95.8715C39.9838 98.043 42.9289 99.2629 45.9998 99.2629C49.0708 99.2629 52.0159 98.043 54.1874 95.8715C56.3589 93.7 57.5788 90.7549 57.5788 87.684C57.573 86.9065 57.4954 86.1313 57.3472 85.3682" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M90 85.3684H2C18.2105 85.3684 18.2105 43.6842 18.2105 43.6842C18.2189 37.5956 20.2266 31.6782 23.9252 26.8416C27.6237 22.005 32.8084 18.517 38.6821 16.9137C37.408 15.3073 36.7216 13.3134 36.7368 11.2632C36.7368 8.80642 37.7128 6.45029 39.45 4.71312C41.1871 2.97594 43.5433 2 46 2C48.4567 2 50.8129 2.97594 52.55 4.71312C54.2872 6.45029 55.2632 8.80642 55.2632 11.2632C55.2784 13.3134 54.592 15.3073 53.3179 16.9137C59.1916 18.517 64.3763 22.005 68.0748 26.8416C71.7734 31.6782 73.7811 37.5956 73.7895 43.6842C73.7895 43.6842 73.7895 85.3684 90 85.3684Z" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg *ngIf="mp?.missingPartStatus == 2" class="in-progress" height="16" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M56.15 110.3C86.0562 110.3 110.3 86.0562 110.3 56.15C110.3 26.2438 86.0562 2 56.15 2C26.2438 2 2 26.2438 2 56.15C2 86.0562 26.2438 110.3 56.15 110.3Z" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path class="primary-stroke" d="M56.1504 21.6909V56.15" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/><path class="primary-stroke" d="M56.1503 56.1499H31.5366" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/></svg>
            
                <svg *ngIf="mp?.missingPartStatus == 3" class="closed" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.9931 0.777145C4.9051 0.777145 0.7771 4.90515 0.7771 9.99314C0.7771 15.0811 4.9051 19.2091 9.9931 19.2091C15.0811 19.2091 19.2091 15.0811 19.2091 9.99314C19.2091 4.90515 15.0811 0.777145 9.9931 0.777145ZM14.7355 7.76595L9.3211 13.1995C9.14833 13.3723 8.8987 13.4875 8.64903 13.4875C8.39936 13.4875 8.14987 13.3915 7.97696 13.1995L5.2507 10.4731C4.86673 10.0892 4.86673 9.49398 5.2507 9.10988C5.63467 8.72591 6.22987 8.72591 6.61397 9.10988L8.64913 11.145L13.3915 6.40264C13.7755 6.01868 14.3707 6.01868 14.7548 6.40264C15.1195 6.78675 15.1195 7.38191 14.7355 7.76591L14.7355 7.76595Z" fill="#00BA5E"/>
                </svg>
                
            </div>
            </div>
        </div>

        <app-demo-button text="{{ 'ADD A PART' | translate }}" type="btn-small" color="reverse-grey" (clickEvent)="openMissingPartPopUp()" tabindex="0" *ngIf="roleAuth?.createMissingPart"></app-demo-button>
    </div>
    
    <!--CONTENT MISSING PART-->
    <div *ngIf="roleAuth == null || roleAuth?.readMissingPart">
        <div class="details-container" *ngIf="errorMessageSelectedMP != ''">
            <div class="head error-message">
                <svg width="50" height="50" viewBox="0 0 109 116" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M44.218 12.9597L3.348 89.4997C2.41723 91.2441 1.95406 93.1999 2.00359 95.1765C2.05313 97.1531 2.61369 99.0833 3.63068 100.779C4.64767 102.475 6.08643 103.878 7.80686 104.852C9.52729 105.827 11.4708 106.339 13.448 106.34H94.608C96.5852 106.339 98.5287 105.827 100.249 104.852C101.97 103.878 103.408 102.475 104.425 100.779C105.442 99.0833 106.003 97.1531 106.052 95.1765C106.102 93.1999 105.639 91.2441 104.708 89.4997L63.838 12.9697C62.8471 11.2472 61.417 9.81853 59.6936 8.82943C57.9701 7.84033 56.0151 7.32625 54.028 7.33965C52.0389 7.30949 50.0784 7.81541 48.3523 8.80428C46.6261 9.79315 45.1981 11.2285 44.218 12.9597V12.9597Z" stroke="black" stroke-width="4" stroke-miterlimit="10"/><path d="M59.0786 89.7289C59.0786 87.4968 58.0157 86.4339 56.1024 86.4339H51.9571C49.9376 86.4339 48.8747 87.4968 48.8747 89.7289V92.5987C48.8747 94.9371 49.9376 96 51.9571 96H56.1024C58.0157 96 59.0786 94.9371 59.0786 92.5987V89.7289ZM59.2911 24.5731V24.148C59.2911 22.0222 58.2282 21.0655 56.2087 21.0655H51.8508C49.9376 21.0655 48.7684 22.0222 48.7684 24.148V24.5731L49.9376 72.0847C50.0439 74.4231 51.0005 75.5923 53.02 75.5923H55.2521C57.1653 75.5923 58.0157 74.4231 58.1219 72.0847L59.2911 24.5731Z" fill="black"/></svg>
                <span>{{errorMessageSelectedMP | translate}}</span>
            </div>
        </div>
        <div class="details-container" *skeleton="(!roleAuth?.missingPartManagement || (selectedMissingPart == null && errorMessageSelectedMP == '')) && filteredMissingParts == null; height: '247px'; width: '80%'; margin:'0 auto'">
            <div class="head" *ngIf="selectedMissingPart != null">
                <div class="top-part">
                    <span><span class="missing-part-id">({{selectedMissingPart.id}})</span> {{selectedMissingPart.title}}
                        <mat-icon class="mat-icon-rtl-mirror copy-link" (click)="copyDirectLink(selectedMissingPart.id, $event)" *ngIf="selectedMissingPart.id != null">{{'content_copy'}}</mat-icon>
                    </span>
                    <div class="state" [ngClass]="{'potential':selectedMissingPart.missingPartStatus == 0, 'to-be-confirmed':selectedMissingPart.missingPartStatus == 1, 'in-progress' :selectedMissingPart.missingPartStatus == 2, 'closed':selectedMissingPart.missingPartStatus == 3}">
                        <div class="state-details">
                            <span>{{ getMissingPartStatusTranslation(selectedMissingPart.missingPartStatus) | translate}}</span>
                        </div>
                        <span class="date" *ngIf="getMissingPartStatusDate() != null">{{"PRE DATE" | translate}} {{getMissingPartStatusDate() | demoDate:"onlyDate"}}</span>
                    </div>
                </div>
                <div class="content-part">
                    <div class="mp-information">
                        <span title="{{getDescriptionTranslation() | titlecase}} - {{selectedMissingPart.part?.demarcation}}">
                            {{'PARTID' | translate}}: <strong>{{selectedMissingPart.partId}} </strong><strong *ngIf="selectedMissingPart.part != null && currentFactory.displayRTAlias">/ {{selectedMissingPart.part.rviAlias}} </strong><strong>{{getDescriptionTranslation() | titlecase}}</strong><strong>{{selectedMissingPart.part?.demarcation != null ? "- " + selectedMissingPart.part?.demarcation : ""}}</strong>
                        </span>
                        <span>{{'NUMBER OF IMPACTED VEHICLE' | translate}}: <strong>{{selectedMissingPart.impactedProductsNumber}}</strong></span>
                        <span>{{'CAUSE' | translate}}: <strong>{{getMissingCauseFullPath()}}</strong></span>
                        <span title="{{selectedMissingPart.creatorOperatorEmployeeId}} {{selectedMissingPart.creatorOperatorName}} {{selectedMissingPart.creator}}">
                            {{'CREATED BY' | translate}}: <strong *ngIf="selectedMissingPart.creatorOperatorEmployeeId != null || selectedMissingPart.creatorOperatorName != null">{{selectedMissingPart.creatorOperatorEmployeeId}} {{selectedMissingPart.creatorOperatorName}} </strong><strong>{{selectedMissingPart.creator}}</strong>
                        </span>
                        <span>{{'OWNER' | translate}}: <strong>{{selectedMissingPart.ownerEmployeeId}} {{selectedMissingPart.ownerName}}</strong></span>
                        <span>{{'CREATED' | translate}}: <strong>{{selectedMissingPart.creationDate | demoDate}}</strong></span>
                        <span>{{'PLANNED MISSING DATE' | translate}}: <strong *ngIf="selectedMissingPart.plannedMissingDate != null">{{selectedMissingPart.plannedMissingDate | demoDate:"onlyDate"}}</strong></span>
                        <span>{{'PLANNED ARRIVAL' | translate}}: <strong *ngIf="selectedMissingPart.plannedArrival != null">{{selectedMissingPart.plannedArrival | demoDate:"onlyDate"}}</strong></span>
                        <span>{{'DEFAULT RESPONSIBLE' | translate}}: <strong>{{getDefaultResponsibleFullPath()}}</strong></span>
                        <span>{{'COMMENT' | translate}}: <strong>{{selectedMissingPart.comment}}</strong></span>
                    </div>
                    
                    <fieldset class="supplier-information">
                        <legend>{{'SUPPLIER INFORMATION' | translate}}</legend>

                        <span>{{'COMPANY NAME' | translate}}: <strong>{{selectedMissingPart.supplier?.companyName}}</strong></span>
                        <span class="col-2">{{'SUPPLIER CODE' | translate}}: <strong>{{selectedMissingPart.supplier?.supplierCode}}</strong></span>

                        <span>{{'COUNTRY CODE' | translate}}: <strong>{{selectedMissingPart.supplier?.countryCode}}</strong></span>
                        <span>{{'PICKUP ADDRESS' | translate}}: <strong>{{selectedMissingPart.supplier?.pickupAddress}}</strong></span>

                        <span>{{'PARMA CODE' | translate}}: <strong>{{selectedMissingPart.supplier?.parmaCode}}</strong></span>
                        <span>{{'MATERIAL CONTROLLER' | translate}}: <strong>{{selectedMissingPart.materialControllerEmployeeId}} {{selectedMissingPart.materialControllerName}}</strong></span>
                        
                    </fieldset>
                </div>
                <div class="button-part">
                    <app-demo-button text="{{ 'EDIT' | translate }}" type="btn-small" (clickEvent)="openMissingPartPopUp(selectedMissingPart!)" tabindex="0" *ngIf="roleAuth?.missingPartManagement"></app-demo-button>
                    <app-demo-button text="{{ 'VIEW' | translate }}" type="btn-small" (clickEvent)="openMissingPartPopUp(selectedMissingPart!)" tabindex="0" *ngIf="!roleAuth?.missingPartManagement"></app-demo-button>
                    <app-demo-button text="{{ 'ASSIGN TO ME' | translate }}" type="btn-small" color="reverse-tertiary" svg="workspace_premium" (clickEvent)="openAssignPopUp()" tabindex="0" *ngIf="selectedMissingPart.missingPartStatus != 3 && selectedMissingPart.ownerEmployeeId != getActiveAccount()?.idTokenClaims?.['mailNickName'] && roleAuth?.missingPartManagement"></app-demo-button>
                        
                    <div class="tooltip-container relative" *ngIf="selectedMissingPart.missingPartStatus != 3 && roleAuth?.missingPartManagement">
                        <app-demo-button text="{{ 'CLOSE' | translate }}" type="btn-small" (clickEvent)="openClosePopUp()" [disable]=!canCloseMissingPart() tabindex="0"></app-demo-button>
                        <div class="tooltip-content display-top" *ngIf="!canCloseMissingPart()">
                            <span *ngIf="!hasAllDeviationsValidated()">{{"ALL DEVIATIONS MUST BE CONFIRMED" | translate}}</span>
                            <span *ngIf="!hasCauseFilledOut()">{{"A CAUSE MUST BE FILLED OUT" | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        
            <!--PROBLEM SOLVING STATUS-->
            <div *ngIf="currentFactory.missingPartProblemSolvingEnabled && errorMessageSelectedMP == ''" (click)="openProblemStatus()">
                <div class="problem-solving-status" *skeleton="selectedMissingPart == null; height: '180px'; width: '100%'; margin:'0 auto 0px'; className:'head'">
                    <div class="top-part">
                        <span>{{"PROBLEM SOLVING STATUS" | translate}}</span>
                        
                    </div>
                    <div class="status-tracking">
                        <div class="step" *ngFor="let step of selectedMissingPart?.steps, last as isLast">
                            <span class="step-number">{{"STEP" | translate}} {{step.stepIndex}}</span>
                            <svg *ngIf="step.closed" class="icon-ok" width="32" height="32" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19" cy="19" r="19" fill="#00BA5E" fill-opacity="0.24"/><path d="M18.9914 7.47143C12.6314 7.47143 7.47144 12.6314 7.47144 18.9914C7.47144 25.3514 12.6314 30.5114 18.9914 30.5114C25.3514 30.5114 30.5114 25.3514 30.5114 18.9914C30.5114 12.6314 25.3514 7.47143 18.9914 7.47143ZM24.9194 16.2074L18.1514 22.9994C17.9355 23.2154 17.6234 23.3594 17.3113 23.3594C16.9993 23.3594 16.6874 23.2393 16.4713 22.9994L13.0634 19.5914C12.5835 19.1115 12.5835 18.3675 13.0634 17.8873C13.5434 17.4074 14.2874 17.4074 14.7675 17.8873L17.3115 20.4313L23.2395 14.5033C23.7194 14.0233 24.4634 14.0233 24.9436 14.5033C25.3994 14.9834 25.3994 15.7274 24.9195 16.2074L24.9194 16.2074Z" fill="#00BA5E"/></svg>
                            
                            <svg *ngIf="!step.closed && isPreviousStepClosed(step.stepIndex-1)" class="icon-progress" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 158 158"><circle cx="79" cy="79" r="79" fill-opacity="0.24"/><circle cx="78.641" cy="78.641" r="48.047"/><path class="cls-3" d="M58.246,75.906a4.72,4.72,0,1,1-4.72,4.72A4.72,4.72,0,0,1,58.246,75.906Z"/><path class="cls-3" d="M80.246,40.906a4.72,4.72,0,1,1-4.72,4.72A4.72,4.72,0,0,1,80.246,40.906Z"/><path class="cls-3" d="M80.246,75.906a4.72,4.72,0,1,1-4.72,4.72A4.72,4.72,0,0,1,80.246,75.906Z"/><rect class="cls-3" x="59" y="76" width="21" height="9"/><path class="cls-3" d="M76,80V46h9V80H76Z"/></svg>
                            
                            <div *ngIf="!step.closed && !isPreviousStepClosed(step.stepIndex-1)" class="icon-todo"></div>
                            <span class="step-name">{{ MissingPartStepTitle[step.stepIndex - 1] | translate }}</span>
                            <div class="between-step todo" *ngIf="!isLast"></div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- IMPACTED PRODUCT -->
            <div class="list-impacted-products" *ngIf="impactedDeviations != null && impactedDeviations.length != 0">
                <div class="legend impacted-deviation">
                    <span></span>
                    <span>{{"PRODUCTS" | translate}}</span>
                    <div class="deviation-desc-container">
                        <span *ngIf="roleAuth?.readDeviation">{{"RESPONSIBLE" | translate}}</span>
                        <span>{{"DETECTION DATE" | translate}}</span>
                        <span *ngIf="roleAuth?.readDeviation">{{"COMMENT" | translate}}</span>
                    </div>
                    <span></span>
                </div>
                
                <div class="impacted-product" > 
                    <div *ngFor="let deviation of impactedDeviations" class="impacted-deviation" tabindex="0" (click)="openProductPopUp(deviation)" (keyup.enter)="openProductPopUp(deviation)">
                        <svg *ngIf="deviation.resolutionDate != null" width="24" height="24" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="19" cy="19" r="19" fill="#00BA5E" fill-opacity="0.24"/>
                            <path d="M18.9914 7.47143C12.6314 7.47143 7.47144 12.6314 7.47144 18.9914C7.47144 25.3514 12.6314 30.5114 18.9914 30.5114C25.3514 30.5114 30.5114 25.3514 30.5114 18.9914C30.5114 12.6314 25.3514 7.47143 18.9914 7.47143ZM24.9194 16.2074L18.1514 22.9994C17.9355 23.2154 17.6234 23.3594 17.3113 23.3594C16.9993 23.3594 16.6874 23.2393 16.4713 22.9994L13.0634 19.5914C12.5835 19.1115 12.5835 18.3675 13.0634 17.8873C13.5434 17.4074 14.2874 17.4074 14.7675 17.8873L17.3115 20.4313L23.2395 14.5033C23.7194 14.0233 24.4634 14.0233 24.9436 14.5033C25.3994 14.9834 25.3994 15.7274 24.9195 16.2074L24.9194 16.2074Z" fill="#00BA5E"/>
                        </svg>
                        <svg *ngIf="deviation.resolutionDate == null" width="24" height="24" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="19" cy="19" r="19" fill="#FCDDDA"/>
                            <path d="M18.9918 7.08571C12.4154 7.08571 7.08569 12.4154 7.08569 18.9919C7.08569 25.5683 12.4154 30.898 18.9918 30.898C25.5683 30.898 30.898 25.5683 30.898 18.9919C30.898 12.4154 25.5683 7.08571 18.9918 7.08571V7.08571ZM24.598 22.9107C25.062 23.3748 25.062 24.1295 24.598 24.5935C24.1245 25.067 23.5574 25.2357 22.9152 24.5935L18.9917 20.6748L15.0729 24.5935C14.5292 25.1373 13.7417 24.9451 13.3901 24.5935C12.9261 24.1295 12.9261 23.3748 13.3901 22.9107L17.3089 18.9919L13.3901 15.0732C12.9261 14.6091 12.9261 13.8544 13.3901 13.3903C13.8542 12.9263 14.6089 12.9263 15.0729 13.3903L18.9917 17.3091L22.9105 13.3903C23.3745 12.9263 24.1293 12.9263 24.5933 13.3903C25.0574 13.8544 25.0574 14.6091 24.5933 15.0732L20.6745 18.9919L24.598 22.9107Z" fill="#EA2C1A"/>
                        </svg>

                        <span class="title">{{getImpactedDeviationProduct(deviation.productId)?.fabNumber}}{{getImpactedDeviationProduct(deviation.productId)?.controlLetter}}</span>
                        <div class="deviation-desc-container">
                            <span class="desc" *ngIf="roleAuth?.readDeviation"><mat-icon class="mat-icon-rtl-mirror small">{{'settings'}}</mat-icon>{{getResponsibleFullPath(deviation)}}</span>
                            <span class="desc"><mat-icon class="mat-icon-rtl-mirror small">{{'location_on'}}</mat-icon>{{deviation.detectionDate | demoDate }}</span>
                            <span class="desc" title="{{deviation.description}}" *ngIf="roleAuth?.readDeviation"><mat-icon class="mat-icon-rtl-mirror small">{{'chat_bubble'}}</mat-icon>{{deviation.description}}</span>
                        </div>

                        <app-demo-button class="bar-button" text="{{ 'CONFIRM' | translate }}" color="reverse-grey" type="btn-small" (clickEvent)="openValidatePopUp(deviation, $event)" *ngIf="deviation.missingPartStatus == 0 && roleAuth?.validateMissingPart"></app-demo-button>
                        <app-demo-button class="bar-button solve-button" text="{{ 'SOLVE' | translate }}" color="reverse-grey" type="btn-small" (clickEvent)="openSolvePopUp($event, deviation)" *ngIf="deviation.resolutionDate == null && roleAuth?.solveDeviation"></app-demo-button>
                        <span class="desc solved-date" *ngIf="deviation.resolutionDate != null && roleAuth?.readDeviation">{{deviation.resolutionDate | demoDate}}</span>
                    </div>                  
                </div>
            </div>

            <div class="stakeholder-amount-container">
                <!--STAKEHOLDERS-->
                <app-stakeholders-list
                    [roleAuth]="roleAuth"
                    [errorMessageSelectedCase]="''"
                    [currentFactoryId]="currentFactoryId"
                    [selectedMissingPart]="selectedMissingPart"
                    (assignEvent)="assignedMissingPart($event)">
                </app-stakeholders-list>

                <!-- AMOUNT PARTS -->
                <div class="amount-parts" *ngIf="selectedMissingPart != null">
                    <div class="amount-parts-head">
                        <span class="title">{{ "AMOUNT OF PARTS" | translate}} </span>
                        <div class="tooltip-container relative">
                            <mat-icon class="mat-icon-rtl-mirror" class="">{{'info'}}</mat-icon>
                            <div class="tooltip-content">
                                <span><strong>{{selectedMissingPart.totalOpenMissingPartsAmount}}</strong> {{"OPEN MISSING DESC" | translate}}</span>
                                <span><strong>{{selectedMissingPart.totalMissingPartsAmount}}</strong> {{"IN ALL DEVIATIONS DESC" | translate}}</span>
                                <span>{{"AMOUNT OF PART DESC" | translate}}</span>
                                <span><strong>{{selectedMissingPart.totalMissingPartsToBeDeliveredAmount}}</strong> {{"TO BE DELIVERED DESC" | translate}}</span>
                            </div>
                        </div>
                    </div>
                    <span><strong>{{selectedMissingPart.totalOpenMissingPartsAmount}}</strong> {{"OPEN MISSING PART OF" | translate}} <strong>{{selectedMissingPart.totalMissingPartsAmount}}</strong> {{"IN ALL DEVIATIONS" | translate}}</span>
                    <span><strong>{{selectedMissingPart.totalMissingPartsToBeDeliveredAmount}}</strong> {{"MISSING PART TO BE DELIVERED" | translate}}</span>
                </div>
            </div>

        </div>

    </div>
    
    <!--POPUP-->
    <div class="popup-container" [ngClass]="{'active' :hasMissingPartToAdd || hasMissingPartToEdit || hasMissingPartToValidate || hasMissingPartToClose || hasMissingPartToAssign || showProblemStatus || hasNotificationToSend}" (pointerdown)="closePopUp()">
        <div class="popup">
            <div class="missing-part-popup" (pointerdown)="cancelClosePopUp()" [ngClass]="{'problem-solving-popup' :showProblemStatus}">
                <svg (click)="closePopUp()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062">
                    <path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/>
                    <path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/>
                </svg>
                <mat-icon class="mat-icon-rtl-mirror history-btn" *ngIf="hasMissingPartToEdit" (click)="showHistory()">{{'history'}}</mat-icon>
        
                
                <span class="title" *ngIf="!showProblemStatus">{{ getPopUpTitle() | translate }}</span>
                
                <span class="selected-product" *ngIf="!hasMissingPartToAdd && !hasMissingPartToAssign && stakeholderToRemove == null && !showProblemStatus && !hasNotificationToSend">{{ "MISSING PART" | translate }}: {{selectedMissingPart?.partId}}</span>
                <span class="selected-product" *ngIf="hasMissingPartToAssign && stakeholderToAssign == null">{{ "ASSIGN TO ME CONFIRMATION MESSAGE" | translate }}</span>
                     
                <form [formGroup]="missingPartForm" (ngSubmit)="onMissingPartSubmit()" (keydown.enter)="$event.preventDefault()" *ngIf="(hasMissingPartToAdd || hasMissingPartToEdit) && !showMissingPartHistory">
                    <!-- TITLE -->
                    <div class="field-container">
                        <label for="title">{{ 'TITLE' | translate }} <app-notification-message *ngIf="!missingPartForm.disabled"></app-notification-message></label>
                        <input #firstMPInput type="text" id="title" class="form-control" formControlName="title" autocomplete="off">
                    </div>

                    <!-- PARTS -->
                    <div class="field-container free-text-suggestions field-container-missing-part" [ngClass]="{'error' :isMissingPartDuplicate}">
                        <label for="partId" class="full-width">{{ 'PARTID' | translate }} * <app-notification-message *ngIf="!missingPartForm.disabled"></app-notification-message></label>
                        <svg class="scan-icon" width="17" height="17" viewBox="0 0 107 95" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.39551 33.1314C4.39551 28.6855 6.16162 24.4217 9.30532 21.278C12.449 18.1343 16.7128 16.3682 21.1587 16.3682H61.8692V49.8945H21.1587C16.7128 49.8945 12.449 48.1284 9.30532 44.9847C6.16162 41.841 4.39551 37.5772 4.39551 33.1314V33.1314Z" stroke="black" stroke-width="4" stroke-linejoin="round"/><path d="M16.3684 49.8945L2 76.2366L13.9258 84.7619C14.537 85.1943 15.2319 85.4939 15.9658 85.6415C16.6998 85.7892 17.4565 85.7815 18.1873 85.6191C18.9182 85.4567 19.6069 85.1431 20.2092 84.6985C20.8116 84.254 21.3142 83.6882 21.6847 83.0377L40.3158 49.8945" stroke="black" stroke-width="4" stroke-linejoin="round"/><path d="M52.2899 49.8945V59.7129C52.2292 62.1708 51.2258 64.5112 49.4872 66.2497C47.7487 67.9882 45.4083 68.9917 42.9505 69.0524H30.7373" stroke="black" stroke-width="4" stroke-linejoin="round"/><path d="M81.0264 69.0522H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 83.4209H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 93H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 59.4736H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 45.1055H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 35.5264H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 25.9478H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 11.5791H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M81.0264 2H104.974" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        <mat-icon *ngIf="isMissingPartDuplicate" class="mat-icon-rtl-mirror">{{'error'}}</mat-icon>
                        <input type="text" id="partid" class="form-control" formControlName="partId" autocomplete="off" [ngClass]="{'full-width': !isSelectedMissingPartOptionFullyDisplayed()}"
                               (focus)="inputMpFocused = true"
                               (blur)="blurMissingPartField()"
                               (keyup.enter)="chooseOption($event)"
                               (keyup.arrowdown)="selectNextOption()"
                               (keyup.arrowup)="selectPreviousOption()"
                               (keyup.backspace)="inputMpFocused = true" required>
                        <ul class="suggestions" [ngClass]="{'active': inputMpFocused}">
                            <li *ngFor="let mp of filteredMissingPartOptions().slice(0, optionsLimit)" (mousedown)="selectPartIdOption(mp)" [ngClass]="{'to-be-selected': toBeSelectedPartOption?.partId == mp.partId}"><strong>{{ mp.partId }}</strong> {{ currentFactory != null && currentFactory.displayRTAlias ? mp.rtAlias : "" }} {{mp.description}} {{mp.demarcation != null && mp.demarcation != "" ? "- " + mp.demarcation : ""}}</li>
                            <li *ngIf="filteredMissingPartOptions().length > optionsLimit" class="see-more" (mousedown)="removeOptionsLimit()" class="see-more">{{"SEE ALL OPTIONS" | translate}}</li>
                        </ul>
                        <span *ngIf="isSelectedMissingPartOptionFullyDisplayed()" class="part-description" title="{{selectedMissingPartOption?.demarcation}}">
                            {{currentFactory != null && currentFactory.displayRTAlias ? selectedMissingPartOption?.rtAlias : ""}} {{selectedMissingPartOption?.description}} {{selectedMissingPartOption?.demarcation != null && selectedMissingPartOption?.demarcation != "" ? "- " + selectedMissingPartOption?.demarcation : ""}}
                        </span>
                        <span *ngIf="isMissingPartDuplicate" class="error-message">{{"MISSING PART ALREADY EXISTS IN FACTORY" | translate}}</span>
                    </div>

                    <!-- CAUSE -->
                    <div class="field-container">
                        <label for="cause">{{ 'CAUSE' | translate }}</label>
                        <app-tree-data id="cause"
                            [missingCauseList]="missingCauses"
                            [currentSelectedMissingCause]="getMissingCauseFromList(selectedMissingCauseId!)"
                            (selectEvent)="setSelectedMissingCause($event)"
                            (fieldNotVoidEvent)="warningMissingCauseField = $event"
                            [temporaryPlaceholder]="hasMissingPartToEdit ? getMissingCauseFullPath() : ''"
                            [isDisabled]="(hasMissingPartToEdit && currentFactory.missingPartProblemSolvingEnabled && selectedMissingPart != null && selectedMissingPart.steps != null && selectedMissingPart.steps[1].closed) || missingPartForm.disabled">
                        </app-tree-data>
                    </div>

                    <!-- SUPPLIERS -->
                    <div class="field-container">
                        <label>{{ 'SUPPLIER' | translate }}</label>
                        <mat-select tabindex="0" id="supplierParmaCode" formControlName="supplierParmaCode" *skeleton="!suppliersLoaded; height: '30px'; width: '100%';">
                            <mat-option *ngFor="let supplier of selectedMissingPartPossibleSuppliers" [value]="supplier.parmaCode">{{supplier.parmaCode}} - {{supplier.companyName}} ({{supplier.countryCode}})</mat-option>
                        </mat-select>
                    </div>

                    <!--DEFAULT DUE TO / RESPONSIBLE -->
                    <div class="field-container">
                        <label for="defaultResponsibleField">{{ 'DEFAULT RESPONSIBLE' | translate }}</label>
                        <app-tree-data id="defaultResponsibleField"
                        (click)="scrollToFunction('responsibleField')" 
                        [responsiblesList]="responsibles"
                        [currentSelectedResponsible]="getResponsibleElement(selectedDefaultResponsibleId!)"
                        [isDisabled]="missingPartForm.disabled"
                        [temporaryPlaceholder]="hasMissingPartToEdit ? getDefaultResponsibleFullPath() : ''"
                        (selectEvent)="setSelectedDefaultResponsible($event)"
                        (fieldNotVoidEvent)="warningDefaultResponsibleField = $event"></app-tree-data>
                    </div>

                    <!-- COMMENT -->
                    <div class="field-container">
                        <label for="comment">{{ 'COMMENT' | translate }} <app-notification-message *ngIf="!missingPartForm.disabled"></app-notification-message></label>
                        <input type="text" id="comment" class="form-control" formControlName="comment" autocomplete="off">
                    </div>

                    <!-- DATES -->
                    <div class="field-container">
                        <label for="plannedMissingDate">{{ 'PLANNED MISSING DATE' | translate }}</label>
                        <input matInput [matDatepicker]="pickerPlannedMissingDate" placeholder="{{getDateFormatString()}}" formControlName="plannedMissingDate"
                            (dateChange)="selectPlannedMissingDate($event)">
                        <mat-datepicker-toggle matIconSuffix [for]="pickerPlannedMissingDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickerPlannedMissingDate></mat-datepicker>
                    </div>
                    <div class="field-container">
                        <label for="plannedArrival">{{ 'PLANNED ARRIVAL' | translate }}</label>
                        <input matInput [matDatepicker]="pickerPlannedArrival" placeholder="{{getDateFormatString()}}" formControlName="plannedArrival"
                            (dateChange)="selectPlannedArrival($event)">
                        <mat-datepicker-toggle matIconSuffix [for]="pickerPlannedArrival"></mat-datepicker-toggle>
                        <mat-datepicker #pickerPlannedArrival></mat-datepicker>
                    </div>

                    <!-- IMPACTED LINES -->
                    <div class="field-container">
                        <div class="expandable" (click)="isFieldLinesExpanded = !isFieldLinesExpanded">
                            <label for="inmpacted-lines">{{ 'IMPACTED LINES' | translate }}</label>
                            <mat-icon class="mat-icon-rtl-mirror">{{isFieldLinesExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>
                        </div>
                        <div class="inside-accordion" [ngClass]="{'visible':isFieldLinesExpanded}">
                            <div class="checkbox-container" *ngFor="let line of currentFactory.productionLines">
                                <label>
                                    <input type="checkbox" id="impacted-lines" [checked]="isLineImpacted(line.id)" (change)="changeImpactedLine($event, line.id!)">
                                    {{line.name}}
                                </label>
                            </div>
                        </div>
                    </div>

                    <!-- IMPACTED PRODUCT -->
                    <div class="field-container">
                        <label for="impactedProductsNumber">{{ 'IMPACTED PRODUCTS' | translate }}</label>
                        <input type="number"
                               id="impactedProductsNumber"
                               class="form-control read-only"
                               formControlName="impactedProductsNumber"
                               onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                               [readOnly]="true">
                        <div class="start-of-line">
                            <app-demo-button 
                                text="{{'REFRESH' | translate}}"
                                type="btn-small"
                                color="{{refreshImpactedProductBlockedReasons().length === 0 && !isRefreshingImpactedProduct ? 'reverse-tertiary' : 'reverse-grey'}}"
                                svg="refresh"
                                [disable]="refreshImpactedProductBlockedReasons().length !== 0 || isRefreshingImpactedProduct"
                                [tooltipText]="refreshImpactedProductBlockedReasons().length === 0 ? 'CALCULATION IMPACTED PRODUCT EXPLAINATION' : ''"
                                [tooltipTexts]="refreshImpactedProductBlockedReasons()"
                                (clickEvent)="refreshImpactedProduct()">
                            </app-demo-button>
                            <mat-spinner [diameter]="20" [strokeWidth]="4" class="field-spinner" *ngIf="isRefreshingImpactedProduct"></mat-spinner>
                        </div>
                    </div>

                    <div class="button-container">
                        <app-demo-button text="{{ 'CANCEL' | translate }}" color="reverse-grey" (clickEvent)="closePopUp()"></app-demo-button>
                        <app-demo-button 
                            text="{{getPopUpButtonText() | translate}}"
                            (clickEvent)="onMissingPartSubmit()"
                            [disable]="!missingPartForm.valid || isCreatingMissingPart || isSavingData || isMissingPartDuplicate || warningMissingCauseField"
                            color="tertiary"
                            svg="save"
                            [tooltipText]="warningMissingCauseField ? 'WRONG MISSING CAUSE FIELD MESSAGE': '' | translate"
                            *ngIf="roleAuth.missingPartManagement || roleAuth.createMissingPart" tabindex="0">
                        </app-demo-button>
                    </div>
                </form>

                <app-demo-button text="{{ 'CONFIRM RELATED PRODUCT' | translate }}" color="tertiary" (clickEvent)="validateMissingPart()" [disable]=isSavingData *ngIf="hasMissingPartToValidate"></app-demo-button>
                <app-demo-button text="{{ 'CLOSE MISSING PART' | translate }}" color="tertiary" (clickEvent)="closeMissingPart()" [disable]=isSavingData *ngIf="hasMissingPartToClose"></app-demo-button>
                <app-demo-button text="{{ 'ASSIGN TO ME' | translate }}" color="tertiary" (clickEvent)="assignMissingPart()" [disable]=isSavingData *ngIf="hasMissingPartToAssign && stakeholderToAssign == null"></app-demo-button>
                
                <!--PROBLEM SOLVING-->
                <div *ngIf="showProblemStatus" class="problem-solving-popup-content">
                    <app-problem-solving-step *ngFor="let step of selectedMissingPart?.steps; let index = index"
                        title="{{'STEP' | translate}} {{step.stepIndex}}: {{ MissingPartStepTitle[step.stepIndex - 1]| translate}}"
                        employeeId="{{step.ownerEmployeeId == '' || !step.closed ? selectedMissingPart?.ownerEmployeeId : step.ownerEmployeeId}}"
                        name = "{{step.ownerName == '' || !step.closed ? selectedMissingPart?.ownerName : step.ownerName}}"
                        comment="{{step.comment}}"
                        [stepIndex]=step.stepIndex
                        [files]=step.files
                        [closed]=step.closed
                        [previousStepClosed]=isPreviousStepClosed(step.stepIndex-1)
                        [nextStepClosed]=isNextStepClosed(step.stepIndex+1)
                        [isSavingData]="isSavingStep == step.stepIndex"
                        [missingCauses]="step.stepIndex == 1 || step.stepIndex == 2  ? missingCauses : undefined" 
                        [missingCauseLastChildRequired]="step.stepIndex == 1 ? false : true" 
                        [selectedMissingCauseId]="selectedMissingPart?.missingCauseId"
                        [suppliers]="step.stepIndex == 1  ? selectedMissingPartPossibleSuppliers : undefined"
                        [partsDelivered]="step.stepIndex == 3 ? this.selectedMissingPart?.partsDelivered : undefined" 
                        [partsOrdered]="step.stepIndex == 2 ? this.selectedMissingPart?.partsOrdered : undefined" 
                        [currentMissingPart]=this.selectedMissingPart
                        partLabel="{{getDescriptionTranslation() | titlecase}}"
                        [missingPartHistories]=getMissingPartHistoryof(step.stepIndex)
                        [disabled]=!roleAuth.missingPartManagement
                        (saveEvent)="saveStep($event, step)"
                        (closeEvent)="closeStep($event, step)"
                        (reOpenEvent)="reOpenStep($event, step)"
                        (addEvent)="addFileToStep($event, step)">
                    </app-problem-solving-step>
                </div>

                <!--MISSING PART HISTORY-->
                <div *ngIf="showMissingPartHistory" style="width: 100%;">
                    <div class="history-container" *skeleton="loadingMissingPartHistory; repeat: 2; height: '100px'; width: 'calc(100% - 20px)'; margin:'10px';">
                        <div *ngFor="let mpHist of missingPartHistories" class="history-element">
                            <div class="main-info">
                                <span class="property-name" *ngIf="isCreation(mpHist?.actionType!)">{{"CREATED" | translate}} {{"BY" | translate}} {{mpHist?.user}} {{mpHist?.userEmployeeId}}</span>
                                <span class="property-name" *ngIf="isUpdate(mpHist?.actionType!)">{{getHistoryPropertyName(mpHist?.propertyName!) | translate}}</span>
                                <span class="date">{{mpHist?.date | demoDate}}</span>
                                <span class="user" *ngIf="isUpdate(mpHist?.actionType!)">{{"MODIFIED" | translate}} {{"BY" | translate}} {{mpHist?.user}} {{mpHist?.userEmployeeId}}</span>
                            </div>

                            <span class="old-value" *ngIf="isUpdate(mpHist?.actionType!)"><strong>{{"FROM" | translate}}</strong> {{isHistoryValueDate(mpHist?.propertyName!, mpHist?.oldValue!) ? (getHistoryValue(mpHist?.oldValue!, mpHist?.propertyName!) | demoDate) : getHistoryValue(mpHist?.oldValue!, mpHist?.propertyName!)}} </span>
                            <span class="new-value" *ngIf="isUpdate(mpHist?.actionType!)"><strong>{{"TO" | translate}}</strong> {{isHistoryValueDate(mpHist?.propertyName!, mpHist?.newValue!) ? (getHistoryValue(mpHist?.newValue!, mpHist?.propertyName!) | demoDate) : getHistoryValue(mpHist?.newValue!, mpHist?.propertyName!)}}</span>
                        </div>
                        <span *ngIf="errorMessage != ''">{{errorMessage | translate}}</span>
                    </div>
                </div>
            </div>            
        </div>
    
    </div>

    <app-deviation-popup [isPopupOpen]="(hasDeviationToEdit && selectedDeviation != null) || isLoadingData"
                         [selectedProduct]="selectedProduct"
                         [selectedDeviation]="selectedDeviation"
                         [currentFactoryId]="currentFactoryId"
                         [allowUnknownOperator]="currentFactory != null ? currentFactory.allowUnknownOperator : false"
                         [currentLineId]="currentLineId"
                         [operatorScanningEnabled]="currentFactory != null ? currentFactory.operatorScanningEnabled : false"
                         [isGenericUser]="isGenericUser"
                         [roleAuth]="roleAuth"
                         [activeAccount]="getActiveAccount()"
                         [canActivateSaveAndSolve]="true"
                         [canActivateSaveAndConfirm]="true"
                         [quantityVisible]="true"
                         [problemDetailEnabled]="currentFactory != null ? currentFactory.deviationProblemDetailEnabled : false"
                         [locationEnabled]="currentFactory != null ? currentFactory.deviationLocationEnabled: false"
                         [displayRTAlias]="currentFactory != null ? currentFactory.displayRTAlias: false"
                         [showLoadingUntilDataUpdated]="true"
                         [isParentDataUpdated]="isLoadingData"
                         (closePopupEvent)="closeProductPopUp($event)"
                         (confirmPopupEvent)="openValidatePopUpAfterDeviation($event)"
                         (solvePopupEvent)="openSolvePopUpAfterDeviation($event)"
                         (messagePopupEvent)="displayMessagePopup($event)">
    </app-deviation-popup>

    <app-solve-popup [isPopupOpen]="hasDeviationToSolve"
                     [selectedProduct]="selectedProduct"
                     [selectedDeviation]="selectedDeviation"
                     [currentFactoryId]="currentFactoryId"
                     [operatorScanningEnabled]="currentFactory != null ? currentFactory.operatorScanningEnabled : false"
                     [isGenericUser]="isGenericUser"
                     [solver]=getActiveAccount()?.username
                     [solverId]=getUserId()
                     (closePopupEvent)="closeSolvePopUp()"
                     (solvePopupEvent)="solveDeviation($event)">
    </app-solve-popup>

                

    <div class="spinner-container" *ngIf="isCreatingMissingPart || isSavingData">
        <mat-spinner></mat-spinner>
    </div>
</div>

<app-message-popup [type]=messagePopupType
                   title="{{messagePopupTitle | translate}}"
                   content="{{messagePopupText | translate}}"
                   [ngClass]="{'active' :messagePopupTitle != ''}"
                   (closeEvent)="messagePopupTitle = ''">
</app-message-popup>
    