<div class="field-container parts-delivered-container" *ngIf="(partsDelivered != null || partsOrdered != null) && currentMissingPart != null">
    <label><strong>{{"MISSING PART" | translate}}: {{currentMissingPart.partId}} {{partLabel}}</strong></label>

    <label *ngIf="partsDelivered != null">{{sumMissingPartDelivered()}} {{"MISSING PART DELIVERED" | translate}}</label>
    <label *ngIf="partsDelivered != null">{{currentMissingPart.totalMissingPartsToBeDeliveredAmount}} {{"MISSING PART TO BE DELIVERED" | translate}}</label>


    <div *ngIf="partsDelivered != null" class="table-container">
        <div class="parts-delivered-table-header" id="partsDelivered">
            <span>{{'AMOUNT DELIVERED' | translate}}</span>
            <span>{{'DELIVERY ADDRESS' | translate}}</span>
            <div></div>
            <mat-icon class="mat-icon-rtl-mirror" class="small" (click)="openPartDeliveredPopup()" *ngIf="isOwner">{{'add_circle'}}</mat-icon>
        </div>
        <div class="parts-delivered-table" *ngFor="let partDelivered of partsDelivered; let i = index">
            <span>{{partDelivered.deliveredAmount}}</span>
            <span>{{partDelivered.deliveryAddress}}</span>
            <mat-icon class="mat-icon-rtl-mirror" class="small" (click)="openPartDeliveredPopup(partDelivered)" *ngIf="isOwner">{{'edit'}}</mat-icon>
            <mat-icon class="mat-icon-rtl-mirror" class="small" (click)="openDeletePartDeliveredPopup(partDelivered)" *ngIf="isOwner">{{'delete'}}</mat-icon>
        </div>
    </div>

    <div *ngIf="partsOrdered != null" class="table-container">
        <div class="parts-delivered-table-header" id="partsOrdered">
            <span>{{'AMOUNT ORDERED' | translate}}</span>
            <span>{{'PROMISED DELIVERY DATE' | translate}}</span>
            <span>{{'WHO ORDERED' | translate}}</span>
            <div></div>
            <mat-icon class="mat-icon-rtl-mirror" class="small" (click)="openPartOrderedPopup()" *ngIf="isOwner">{{'add_circle'}}</mat-icon>
        </div>
        <div class="parts-delivered-table" *ngFor="let partOrdered of partsOrdered; let i = index" id="partsOrderedLine">
            <span>{{partOrdered.orderedAmount}}</span>
            <span>{{partOrdered.promisedDeliveryDate | demoDate}}</span>
            <span>{{partOrdered.orderedBy}}</span>
            <mat-icon class="mat-icon-rtl-mirror" (click)="openPartOrderedPopup(partOrdered)" class="small" *ngIf="isOwner">{{'edit'}}</mat-icon>
            <mat-icon class="mat-icon-rtl-mirror" (click)="openDeletePartOrderedPopup(partOrdered)" class="small" *ngIf="isOwner">{{'delete'}}</mat-icon>
        </div>
    </div>
</div>