import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MissingPart } from 'src/app/models/missingPart.model';
import { MissingPartDelivered } from 'src/app/models/missingPartDelivered.model';
import { MissingPartOrdered } from 'src/app/models/missingPartOrdered.model';

@Component({
  selector: 'app-step-table',
  templateUrl: './step-table.component.html',
  styleUrls: ['./step-table.component.less']
})
export class StepTableComponent {
  @Input() isOwner?:boolean;

  @Input() partsDelivered?:MissingPartDelivered[];
  @Input() partsOrdered?:MissingPartOrdered[];
  @Input() currentMissingPart?:MissingPart | null | undefined;
  @Input() partLabel?:string;

  @Output() openPartDeliveredPopupEvent = new EventEmitter<any>();
  @Output() openDeletePartDeliveredPopupEvent = new EventEmitter<any>();
  @Output() openPartOrderedPopupEvent = new EventEmitter<any>();
  @Output() openDeletePartOrderedPopupEvent = new EventEmitter<any>();


  sumMissingPartDelivered(){}

  openPartDeliveredPopup(partDelivered:MissingPartDelivered | null = null){
    this.openPartDeliveredPopupEvent.emit(partDelivered);
  }

  openDeletePartDeliveredPopup(partDelivered:MissingPartDelivered | null = null){
    this.openDeletePartDeliveredPopupEvent.emit(partDelivered);
  }

  openPartOrderedPopup(partOrdered:MissingPartOrdered | null = null){
    this.openPartOrderedPopupEvent.emit(partOrdered);
  }

  openDeletePartOrderedPopup(partOrdered:MissingPartOrdered | null = null){
    this.openDeletePartOrderedPopupEvent.emit(partOrdered);
  }
}
