import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as Diacritics from 'diacritics';
import { DeviationTag } from 'src/app/models/deviationTag.model';
import { DeviationTagLink } from 'src/app/models/deviationTagLink.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrl: './tags-list.component.less'
})
export class TagsListComponent {
  @Input() deviationTagList:DeviationTagLink[] = [];
  @Input() hasRightToModifyTag = false; // right to add, remove tag from deviation
  @Input() hasRightToManageTag = false; // right to change visibility of tags and create new tag
  @Input() factoryId = -1;
  @Input() deviationId = -1;

  @ViewChild('newTagInput') newTagInput!: ElementRef;

  apiUrl = environment.API_URL;

  tagSuggections:DeviationTag[] = [];
  public tagList:DeviationTagLink[] = [];

  addElementVisible = false;
  newTagElement = "";

  errorMessage = "";

  //Keyboard selection
  tagToBeSelected:DeviationTag | null = null;

  constructor(private httpClient:HttpClient){}

  ngOnInit(){
    this.getFactoryTagList();
    this.tagList = [...this.deviationTagList];
  }

  //to move in a service when factory selected
  getFactoryTagList(){
    if(this.factoryId === -1) { return; }

    this.httpClient.get<DeviationTag[]>(this.apiUrl + "Deviation/Tag/ByFactoryId/" + this.factoryId).subscribe(
      (res: DeviationTag[]) => {
        this.tagSuggections = this.sortTags(res);
      }
    );
  }

  sortTags(tags:DeviationTag[]): DeviationTag[]{
    return tags.sort((a, b) => {
      // Sort by enabled/disabled first
      if (a.disabled !== b.disabled) {
        return a.disabled ? 1 : -1;
      }
      // Then sort alphabetically by name
      return a.name.localeCompare(b.name);
    });
  }

  changeVisibility(tag:DeviationTag, event?:any, callback?:(tag:DeviationTag) => void){
    event?.preventDefault();
    event?.stopPropagation();
    if(tag == null) {return;}

    tag.disabled = !tag.disabled;

    this.httpClient.put<DeviationTag>(this.apiUrl + "Deviation/Tag", tag).subscribe(
      (res: DeviationTag) => {
        this.tagSuggections.splice(this.tagSuggections.findIndex(x=> x.id === res.id), 1, res);
        this.tagSuggections = this.sortTags(this.tagSuggections);
        if(callback) { callback(res);}
      }
    );
  }

  createNewTag(){    
    const newTag = new DeviationTag(this.factoryId, this.newTagElement, 0, false);

    this.httpClient.post<DeviationTag>(this.apiUrl + "Deviation/Tag", newTag).subscribe(
      (res: DeviationTag) => {
        this.tagSuggections.push(res);
        this.tagSuggections = this.sortTags(this.tagSuggections);
        this.addElement(res);
      }
    );
  }

  setAddElementVisible(){
    this.addElementVisible = true;
    this.newTagInput.nativeElement.focus();
  }

  blurTagField(){
    if(this.tagToBeSelected != null){
      this.addElement(this.tagToBeSelected);
      return;
    }

    const currentSuggestions = this.tagSuggectionsFiltered();
    if(this.newTagElement !== "" && currentSuggestions.length === 1 && currentSuggestions[0].name === this.newTagElement){
      this.addElement(currentSuggestions[0]);
    }
    else if(this.newTagElement !== "" && this.hasRightToManageTag) { 
      this.createNewTag();
    }
    else{
      this.resetTagField();
    }
  }

  addElement(element:DeviationTag){
    if(element.disabled){
      this.changeVisibility(element, null, () => {
        this.tagList.push(new DeviationTagLink(this.deviationId, element, element.id));
        this.resetTagField();
      });
    }
    else{
      this.tagList.push(new DeviationTagLink(this.deviationId, element, element.id));
      this.resetTagField();
    }
  }

  resetTagField(){
    this.newTagElement = "";
    this.addElementVisible = false;
    this.tagToBeSelected = null;
  }

  removeTag(event:any){
    this.tagList.splice(this.tagList.findIndex(x => event.id === x.tagId), 1);
  }

  tagSuggectionsFiltered():DeviationTag[]{
    return this.tagSuggections.filter(x=>this.notSensitive(x.name).includes(this.notSensitive(this.newTagElement)))
  }

  notSensitive(s:string | null | undefined){
    if(s==null) { return "";}
    return Diacritics.remove(s).toLowerCase();
  }

  //Keyboard navigation
  selectNextTag(){
    const list = this.tagSuggectionsFiltered();
    const nextIndex:number = this.tagToBeSelected == null ? 0 : (list.findIndex(x => x.id === this.tagToBeSelected?.id) + 1) % list.length;
    this.tagToBeSelected = list[nextIndex];
  }
  selectPreviousTag(){
    const list = this.tagSuggectionsFiltered();
    const nextIndex:number = this.tagToBeSelected == null ? list.length - 1 : (list.findIndex(x => x.id === this.tagToBeSelected?.id) - 1 + list.length) % list.length;
    this.tagToBeSelected = list[nextIndex];
  }
}
