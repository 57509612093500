<app-tag-element *ngFor="let tagLink of tagList" text="{{tagLink.tag?.name}}" [id]="tagLink.tagId" [hasRightToModifyTag]="hasRightToModifyTag" (removeEvent)="removeTag($event)"></app-tag-element>

<mat-icon class="add" (click)="setAddElementVisible()" [ngClass]="{'active':!addElementVisible}" *ngIf="tagList.length < 3 && (tagSuggections.length > 0 || hasRightToManageTag) && hasRightToModifyTag">{{'add'}}</mat-icon>

<div class="add-element-container" [ngClass]="{'active':addElementVisible}">
    <input #newTagInput type="text" (blur)="blurTagField()" (keydown.enter)="blurTagField()" (keydown.arrowdown)="selectNextTag()" (keydown.arrowup)="selectPreviousTag()" [(ngModel)]="newTagElement">
    <div class="suggestions-container">
        <span *ngFor="let suggestion of tagSuggectionsFiltered()" 
            (mousedown)="addElement(suggestion)"
            [ngClass]="{'to-be-selected':tagToBeSelected?.id === suggestion.id, 'disabled':suggestion.disabled}">
            {{suggestion.name}}
            <mat-icon 
                class="visibility-icon small" 
                *ngIf="!suggestion.disabled && hasRightToManageTag" title="{{'CLICK TO DISABLE' | translate}}"
                (mousedown)="changeVisibility(suggestion, $event)">
                    {{'visibility'}}
            </mat-icon>
            <mat-icon 
                class="visibility-icon small" 
                *ngIf="suggestion.disabled && hasRightToManageTag" 
                title="{{'CLICK TO ENABLE' | translate}}" 
                (mousedown)="changeVisibility(suggestion, $event)">
                    {{'visibility_off'}}
            </mat-icon>
        </span>
    </div>
</div>
