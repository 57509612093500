export class Agent {

    agentNumber:string;
    agentName:string;
    employeeId:string;
    email:string;
    factoryId:number;

    constructor(
        agentNumber:string,
        agentName:string,
        employeeId:string,
        email:string,
        factoryId:number) {

        this.agentNumber = agentNumber;
        this.agentName = agentName;
        this.employeeId = employeeId;
        this.email = email;
        this.factoryId = factoryId;
    }
}