import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { findIndex, of } from 'rxjs';
import { ProductionZone } from 'src/app/models/productionZone.model';
import { Workcell } from 'src/app/models/workcell.model';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Check } from 'src/app/models/check.model';
import { NotExpr } from '@angular/compiler';
import { Product } from 'src/app/models/product.model';
import { environment } from 'src/environments/environment';
import { ProductLocation } from 'src/app/models/productLocation.model';
import { FaultType } from 'src/app/models/faultType.model';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/models/language.enum';

@Component({
  selector: 'app-production-tracking',
  templateUrl: './production-tracking.component.html',
  styleUrls: ['./production-tracking.component.less']
})
export class ProductionTrackingComponent implements OnInit, OnChanges {

  @Input() product?: Product;
  @Input() productLocation?: ProductLocation;
  @Input() checkRight = false;
  @Input() onlyLine = true;
  @Output() checkEvent = new EventEmitter<any>();

  zones?: ProductionZone[] = [];
  currentZone?: ProductionZone;
  currentWorkcell?: Workcell;

  firstCheckpointZoneIndex = -1;
  firstCheckpointWorkcellIndex = -1;

  isDisplayed = true;

  apiUrl:string = environment.API_URL;

  todo = false;

  constructor(private httpClient:HttpClient, private translate: TranslateService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initializeData(changes['productLocation'].currentValue);
  }

  initializeData(pl:ProductLocation){
    if(pl == null){ return;}
    
    this.zones = pl.currentProductionLine?.productionZones;
    this.currentZone = pl.currentProductionZone;
    this.currentWorkcell = pl.currentWorkcell;
    this.setChecksInWorkcell();
    this.sortData();
    this.getfirstCheckpointIndex();
    this.displayLine();
  }

  setChecksInWorkcell(){
    if(this.zones == null || this.product == null || this.product.checks == null) {return;}

    //put the check data in the correct checkpoints
    this.zones.forEach(zone => {
      zone.workcells.forEach(workcell => {
        this.product?.checks?.forEach(check => {
          if(check.checkpointId === workcell.id){
            workcell.check = check;
          }
        });
      });
    });
  }

  sortData(){
    this.zones?.sort((a, b) => (a.order < b.order) ? -1 : 1);
    this.zones?.forEach(zone => {
      zone.workcells.sort((a, b) => (a.order < b.order) ? -1 : 1);
    });
  }

  isCurrent(workcell:Workcell) : boolean{
      return workcell.id === this.currentWorkcell?.id;
  }

  isDone(indexZone:number, indexWorkcell?:number, isLinkBar?:boolean): boolean{

    const cz:ProductionZone = this.currentZone!;

    if(cz == null || this.currentWorkcell == null){
      return false;
    }

    
    if(this.zones != null && indexZone < this.zones.findIndex((x) => x.id === cz.id)){
      return true;
    }
    else if(this.zones != null && indexZone === this.zones.findIndex((x) => x.id === cz.id)){
      if(indexWorkcell == null){
        return true;
      }

      if(indexWorkcell <= this.zones[indexZone].workcells.findIndex((w)=> w.id === this.currentWorkcell?.id)){
        return true;
      }
      else{
        return false;
      }
    }
    else{
      return false; 
    }   
  }

  check(workcell:Workcell){
    if(this.product == null || !this.checkRight){ return;}

    this.checkEvent.emit();
  }

  getWorkcellStateClass(workcell:Workcell): string{
    if(workcell?.check?.state != null){
      return workcell.check.state ? "cok" : "cnok";
    }
    return "";
  }

  getfirstCheckpointIndex(){
    if(this.zones==null){ return;}

    for (let i=0; i < this.zones.length; i++) {
      for (let j=0; j < this.zones[i].workcells.length; j++) {
        if(this.zones[i].workcells[j].isCheckpoint && (!this.zones[i].workcells[j].disabled || this.zones[i].workcells[j].check != null)){
          this.firstCheckpointZoneIndex = i;
          this.firstCheckpointWorkcellIndex = j;
          return;
        }
      }
    }
  }
  
  getListCheckpoints(workcells:Workcell[]):Workcell[]{
    return workcells.filter(x=>x.isCheckpoint && (!x.disabled || x.check != null));
  }

  allLineChecked(){
    return this.currentWorkcell == null;
  }

  displayLine(){   
    let tmpIsDisplayed = false;
    if(this.zones != null && this.zones?.length > 0){
      this.zones.forEach(zone => {
          if(this.getListCheckpoints(zone.workcells).length > 0){
            tmpIsDisplayed = true;
          }
      });
    }
    this.isDisplayed = this.onlyLine || tmpIsDisplayed;
  }

  //LANGUAGE SETTING
  getFaultTypeRank2Name(faultType:FaultType | null){
    if(faultType == null){ return "";}
    if(this.translate.currentLang === Language.French){
      return faultType.rank2NameFR;}
    else if(this.translate.currentLang === Language.Dutch){
      return faultType.rank2NameNL;}
    else{
      return faultType.rank2NameEN;}
  }
}
