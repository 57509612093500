import { CheckView } from "./checkView.model";

export class ProductView {
    
    id:number;
    fabNumber:string;
    controlLetter:string;
    hasOpenDeviations:boolean;
    assemblyDate:Date;
    rank:number;
    checkState?:boolean;
    checkDate?:Date;
    chassisSeries:string;
    chassisNumber:string;

    constructor(
            fabNumber:string,
            controlLetter:string,
            id:number,
            hasOpenDeviations:boolean,
            assemblyDate:Date,
            rank:number,
            chassisSeries:string,
            chassisNumber:string,
            checkState?:boolean,
            checkDate?:Date) {
        this.id = id;
        this.fabNumber = fabNumber;
        this.controlLetter = controlLetter;
        this.hasOpenDeviations = hasOpenDeviations;
        this.assemblyDate = assemblyDate;
        this.rank = rank;
        this.checkState = checkState;
        this.checkDate = checkDate;
        this.chassisSeries = chassisSeries;
        this.chassisNumber = chassisNumber;
    }
}