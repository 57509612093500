import { DeviationTag } from "./deviationTag.model";

export class DeviationTagLink {

    deviationId:number;
    tag:DeviationTag | null;
    tagId:number;

    constructor(
        deviationId:number,
        tag:DeviationTag | null,
        tagId:number) {

        this.deviationId = deviationId;
        this.tag = tag;
        this.tagId = tagId;
    }
}