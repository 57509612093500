import { Part } from "./part.model";
import { Product } from "./product.model";
import { Supplier } from "./supplier.model";
import { MissingCause } from "./missingCause.model";
import { Agent } from "./agent.model";
import { Responsible } from './responsible.model';
import { MissingPartStakeholder } from "./missingPartStakeholder.model";
import { MissingPartStep } from "./missingPartStep.model";
import { MissingPartImpactedProductionLine } from "./missingPartImpactedProductionLine.model";
import { MissingPartDelivered } from "./missingPartDelivered.model";
import { MissingPartOrdered } from "./missingPartOrdered.model";

export enum MissingPartStatus {
    Potential,
    ToBeConfirmed,
    InProgress,
    Closed
}

export class MissingPart {

    comment:string;
    part:Part | null;
    partId:string;
    title:string;
    missingDate?: Date;
    impactedProductsNumber:number;
    plannedMissingDate:Date | null;
    plannedArrival:Date | null;
    actualArrival?:Date;
    carrier:string;
    factoryId?:number;
    id?:number;
    creationDate:Date;
    creator:string;
    creatorOperatorEmployeeId:string | null;
    creatorOperatorName:string | null;
    cause:string;
    missingPartStatus:MissingPartStatus;
    impactedProducts: Product[] | null;
    supplier?:Supplier | null;
    supplierParmaCode?:string | null;
    missingCauseId: number | null;
    missingCause?: MissingCause | null; 
    agent?:Agent;
    stakeholders:MissingPartStakeholder[] | null;
    ownerEmployeeId:string;
    ownerName:string;
    ownerEmail:string;
    steps: MissingPartStep[] | null;
    impactedProductionLines:MissingPartImpactedProductionLine[] | null;
    materialControllerEmployeeId:string;
    materialControllerName:string;
    materialControllerEmail:string;
    totalMissingPartsAmount?:number | null;
    totalOpenMissingPartsAmount?:number | null;
    totalMissingPartsToBeDeliveredAmount?:number | null;
    partsDelivered?: MissingPartDelivered[];
    partsOrdered?: MissingPartOrdered[];
    defaultResponsibleId:number | null;
    defaultResponsible:Responsible | null;
    defaultResponsibleLevel1Id:number | null;
    defaultResponsibleLevel1:Responsible | null;
    defaultResponsibleLevel2Id:number | null;
    defaultResponsibleLevel2:Responsible | null;
    defaultResponsibleLevel3Id:number | null;
    defaultResponsibleLevel3:Responsible | null;


    constructor(
        comment:string,
        part:Part,
        partId:string,
        title:string,
        impactedProductsNumber:number,
        carrier:string,
        creationDate:Date,
        creator:string,
        creatorOperatorEmployeeId:string | null,
        creatorOperatorName:string | null,
        cause:string,
        missingPartStatus:MissingPartStatus,
        materialControllerEmployeeId:string,
        materialControllerName:string,
        materialControllerEmail:string,
        totalMissingPartsToBeDeliveredAmount:number | null,
        totalMissingPartsAmount:number | null,
        totalOpenMissingPartsAmount:number | null,
        impactedProducts: Product[] | null,
        plannedMissingDate:Date | null,
        plannedArrival:Date | null,
        missingCauseId: number | null,
        stakeholders:MissingPartStakeholder[] | null,
        ownerEmployeeId:string,
        ownerName:string,
        ownerEmail:string,
        steps:MissingPartStep[] | null,
        impactedProductionLines:MissingPartImpactedProductionLine[] | null,
        defaultResponsibleId:number | null,
        defaultResponsible:Responsible,
        defaultResponsibleLevel1Id:number | null,
        defaultResponsibleLevel1:Responsible | null,
        defaultResponsibleLevel2Id:number | null,
        defaultResponsibleLevel2:Responsible | null,
        defaultResponsibleLevel3Id:number | null,
        defaultResponsibleLevel3:Responsible | null,
        partsDelivered?:MissingPartDelivered[],
        partsOrdered?:MissingPartOrdered[], 
        missingCause?: MissingCause | null,
        supplier?:Supplier,
        supplierParmaCode?:string | null,
        agent?:Agent,
        actualArrival?:Date,
        factoryId?:number,
        missingDate?: Date,
        id?:number) {

        this.comment = comment;
        this.part = part;
        this.partId = partId;
        this.title = title;
        this.missingDate = missingDate;
        this.impactedProductsNumber = impactedProductsNumber;
        this.plannedMissingDate = plannedMissingDate;
        this.plannedArrival = plannedArrival;
        this.actualArrival = actualArrival;
        this.carrier = carrier;
        this.factoryId = factoryId;
        this.id = id;
        this.creationDate = creationDate;
        this.creator = creator;
        this.creatorOperatorEmployeeId = creatorOperatorEmployeeId;
        this.creatorOperatorName = creatorOperatorName;
        this.cause = cause;
        this.missingPartStatus = missingPartStatus;
        this.impactedProducts = impactedProducts;
        this.supplier = supplier;
        this.supplierParmaCode = supplierParmaCode;
        this.missingCauseId = missingCauseId;
        this.missingCause = missingCause;
        this.agent = agent;
        this.stakeholders = stakeholders;
        this.ownerEmployeeId = ownerEmployeeId;
        this.ownerName = ownerName;
        this.ownerEmail = ownerEmail;
        this.steps = steps;
        this.impactedProductionLines = impactedProductionLines;
        this.materialControllerEmployeeId = materialControllerEmployeeId;
        this.materialControllerName = materialControllerName;
        this.materialControllerEmail = materialControllerEmail;
        this.totalMissingPartsAmount = totalMissingPartsAmount;
        this.totalOpenMissingPartsAmount = totalOpenMissingPartsAmount;
        this.totalMissingPartsToBeDeliveredAmount = totalMissingPartsToBeDeliveredAmount;
        this.partsDelivered = partsDelivered;
        this.partsOrdered = partsOrdered;
        this.defaultResponsibleId = defaultResponsibleId;
        this.defaultResponsible = defaultResponsible;
        this.defaultResponsibleLevel1Id = defaultResponsibleLevel1Id;
        this.defaultResponsibleLevel1 = defaultResponsibleLevel1;
        this.defaultResponsibleLevel2Id = defaultResponsibleLevel2Id;
        this.defaultResponsibleLevel2 = defaultResponsibleLevel2;
        this.defaultResponsibleLevel3Id = defaultResponsibleLevel3Id;
        this.defaultResponsibleLevel3 = defaultResponsibleLevel3;
    }
}